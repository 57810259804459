import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { GoogleIcon, ShevronRightIcon } from '@tg-web/icons';
import { useBackNavigation, useBoolean } from '@tg-web/utils';

import { GoogleAccountCard } from '../../features/google';
import { useUserProfileConfigured } from '../../shared/api';
import { GlobalLoading } from '../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../shared/ui/PageWrapper';
import { ViewField } from '../../shared/ui/ViewField';

export function SettingsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isConnectingGoogle, setIsConnectingGoogle] = useBoolean(false);

  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const clearCurrentTimeout = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  }, []);

  useEffect(() => {
    return clearCurrentTimeout;
  }, [clearCurrentTimeout]);

  const userProfile = useUserProfileConfigured({
    refetchInterval: isConnectingGoogle ? 3000 : 0,
    refetchOnWindowFocus: 'always',
  });

  useBackNavigation(() => {
    // TODO: handle some query param to navigate back to proper page
    navigate({ to: '/feed' });
  });

  useEffect(() => {
    if (
      userProfile.isSuccess &&
      userProfile.data.user.data.attributes.has_google_calendar
    ) {
      setIsConnectingGoogle.off();
    }
  }, [
    setIsConnectingGoogle,
    userProfile.data?.user.data.attributes.has_google_calendar,
    userProfile.isSuccess,
  ]);

  if (userProfile.isPending) {
    return <GlobalLoading />;
  }

  if (userProfile.isError) {
    throw new Error('Unexpected API error');
  }

  const userData = userProfile.data.user.data;

  const handleStartConnecting = () => {
    clearCurrentTimeout();

    setIsConnectingGoogle.on();

    timeoutIdRef.current = setTimeout(
      () => {
        setIsConnectingGoogle.off();
      },
      5 * 60 * 1000,
    );
  };

  return (
    <PageWrapper className="flex flex-col gap-6 p-4">
      <h1 className="typo-header-big">{t('all:settings.title')}</h1>
      <GoogleAccountCard
        onConnectStart={handleStartConnecting}
        userData={userData}
      />
      {userData.attributes.has_google_calendar && (
        <div>
          <ViewField
            leftIcon={
              <div className="flex h-8 w-8 flex-col items-center justify-center">
                <GoogleIcon />
              </div>
            }
            onClick={() => navigate({ to: '/settings/google-calendars' })}
            rightIcon={<ShevronRightIcon className="text-tg-hint" />}
          >
            {t('all:settings.sections.google_sync')}
          </ViewField>
        </div>
      )}
    </PageWrapper>
  );
}
