import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { GoogleAuthPage } from '../pages/google/ui/GoogleAuthPage';
import { LocalStoreKeys } from '../shared/model/localStoreKeys';

const googleAuthSearchSchema = z.object({
  token: z.string().default(''),
});

export const Route = createFileRoute('/google-auth')({
  component: Page,
  validateSearch: zodSearchValidator(googleAuthSearchSchema),
});

function Page() {
  const { token } = Route.useSearch();

  localStorage.setItem(LocalStoreKeys.GOOGLE_AUTH_TOKEN, token);

  return <GoogleAuthPage token={token} instantLogin />;
}
