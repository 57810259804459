import { PropsWithChildren } from 'react';

import Linkify from 'linkify-react';
import { Opts } from 'linkifyjs';

const render: Opts['render'] = ({ attributes, content }) => {
  return (
    <a
      className="text-tg-link"
      rel="noreferrer"
      target="_blank"
      {...attributes}
    >
      {content}
    </a>
  );
};

export function LinkifyWrapper({ children }: PropsWithChildren) {
  return <Linkify options={{ render }}>{children}</Linkify>;
}
