import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { BellIcon, EasyIcon, PinIcon } from '@tg-web/icons';

export function EmptyStacks() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center gap-2">
      <div className="flex flex-col items-center gap-4 pb-4 pt-2">
        <span className="text-[56px] leading-none">🎉</span>
        <span className="typo-header-big text-center">
          {t('all:stacks.empty.title')}
        </span>
        <span className="typo-text text-center">
          {t('all:stacks.empty.description')}
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex py-2">
          <EasyIcon className="text-tg-hint shrink-0" />
          <span className="typo-text ml-3">
            {t('all:stacks.empty.pointOne')}
          </span>
        </div>
        <div className="flex py-2">
          <BellIcon className="text-tg-hint shrink-0" />
          <span className="typo-text ml-3">
            {t('all:stacks.empty.pointTwo')}
          </span>
        </div>
        <div className="flex py-2">
          <PinIcon className="text-tg-hint shrink-0" />
          <span className="typo-text ml-3">
            {t('all:stacks.empty.pointThree')}
          </span>
        </div>
      </div>
      <Button className="mt-4" variant="primary" asChild>
        <Link to="/stacks/new">{t('all:stacks.empty.button')}</Link>
      </Button>
    </div>
  );
}
