import React from 'react';

import { IconProps } from './types';

export const DateDurationArrowIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="8"
        viewBox="0 0 41 8"
        width="41"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M40.3536 4.35355C40.5488 4.15829 40.5488 3.84171 40.3536 3.64644L37.1716 0.464463C36.9763 0.269201 36.6597 0.269201 36.4645 0.464463C36.2692 0.659725 36.2692 0.976308 36.4645 1.17157L39.2929 4L36.4645 6.82842C36.2692 7.02369 36.2692 7.34027 36.4645 7.53553C36.6597 7.73079 36.9763 7.73079 37.1716 7.53553L40.3536 4.35355ZM4.37114e-08 4.5L40 4.5L40 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
          fill={color}
        />
      </svg>
    );
  },
);
