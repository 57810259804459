import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';

import { Button, Skeleton } from '@tg-web/components';

import {
  User,
  useDeleteGoogleAccount,
  useGoogleCalendarAccountConfigured,
} from '../../../shared/api';
import { removeCloudStorageItem } from '../../../shared/lib/removeCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { useGoogleAuthHandler } from '../lib';

type GoogleAccountCardProps = {
  onConnectStart: () => void;
  userData: User;
};

export function GoogleAccountCard({
  onConnectStart,
  userData,
}: GoogleAccountCardProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { googleAccountsList } = useGoogleCalendarAccountConfigured({
    enabled: userData.attributes.has_google_calendar,
  });
  const deleteGoogleAccount = useDeleteGoogleAccount({
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['events'],
      });
      removeCloudStorageItem(
        CloudStorageKeys.GOOGLE_CALENDAR_ONBOARDING_PASSED,
      );
    },
  });

  const { createUserToken, googleAuthHandler } =
    useGoogleAuthHandler(onConnectStart);

  // invalidate profile after google account delete
  useEffect(() => {
    if (deleteGoogleAccount.isSuccess) {
      queryClient.invalidateQueries({ queryKey: ['auth', 'profile'] });
    }
  }, [deleteGoogleAccount.isSuccess, queryClient]);

  return (
    <div className="bg-tg-section-bg flex flex-col items-center rounded-2xl px-4 py-5">
      <img
        alt="Google calendar icon"
        className="h-12 w-12"
        src="/images/gcal_icon.webp"
      />
      {userData.attributes.has_google_calendar ? (
        <>
          <div className="mt-3 w-full">
            {googleAccountsList.isSuccess &&
            googleAccountsList.data.google_accounts.data.length > 0 ? (
              <h2 className="typo-text-bold text-center">
                {
                  googleAccountsList.data.google_accounts.data[0].attributes
                    .email
                }
              </h2>
            ) : (
              <Skeleton className="bg-tg-secondary-bg h-5 w-full" />
            )}
          </div>
          <p className="typo-text mt-3 text-center">
            {t('all:google.account_card.connected.description')}
          </p>
          {googleAccountsList.isSuccess &&
            googleAccountsList.data.google_accounts.data.length > 0 && (
              <Button
                onClick={() => {
                  deleteGoogleAccount.mutate({
                    pathParams: {
                      id: googleAccountsList.data.google_accounts.data[0].id,
                    },
                  });
                }}
                className="mt-4"
                isLoading={deleteGoogleAccount.isPending}
                size="small"
                variant="secondary"
              >
                {t('all:google.account_card.buttons.disconnect')}
              </Button>
            )}
        </>
      ) : (
        <>
          <h2 className="typo-header-small mt-3 text-center">
            {t('all:google.account_card.not_connected.title')}
          </h2>
          <p className="typo-text mt-3 text-center">
            {t('all:google.account_card.not_connected.description')}
          </p>
          <Button
            className="mt-4"
            isLoading={createUserToken.isPending}
            onClick={googleAuthHandler}
            size="small"
            variant="primary"
          >
            {t('all:google.account_card.buttons.connect')}
          </Button>
        </>
      )}
    </div>
  );
}
