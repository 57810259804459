import { useTranslation } from 'react-i18next';

import { RemoveIcon } from '@tg-web/icons';

import { Event } from '../../../shared/api';
import { useDeleteEventLogic } from '../lib';
import { EventActionButton } from './EventActionButton';

export interface DeleteEventButtonProps {
  event: Event;
}

export function DeleteEventButton({ event }: DeleteEventButtonProps) {
  const { t } = useTranslation();

  const { handleDelete, isPending } = useDeleteEventLogic({ event });

  return (
    <EventActionButton
      disabled={isPending}
      icon={<RemoveIcon />}
      onClick={handleDelete}
      text={t('all:common.buttons.remove')}
    />
  );
}
