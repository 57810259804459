import React from 'react';

import { IconProps } from './types';

export const ShareIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M25.4531 6.54689C22.0625 3.13022 5.16147 9.22397 5.00001 12.0052C4.88543 14.4219 10.1302 17.6927 13.9792 18.0261C14.2969 21.9427 17.5417 27.0573 20 27C22.7917 26.7813 28.7709 9.86459 25.4531 6.54689Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M19.4375 12.5729L14.1042 17.9063"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
