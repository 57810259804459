import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';
import { format, interval, isWithinInterval } from 'date-fns';

import { OverlapEvents } from '../../../features/events';
import { useFeedOptimisticUpdater } from '../../../features/feed/lib/useFeedOptimisticUpdater';
import { CreateEventRequestBody, useCreateEvent } from '../../../shared/api';
import { DATE_SEARCH_PARAM_FORMAT } from '../../../shared/model/search';
import {
  EditEventForm,
  EditEventFormProps,
} from '../../../widgets/events/ui/EditEventForm';

export type NewEventPageProps = {
  initialDate?: Date;
  stackId: string | undefined;
};
export function NewEventPage({ initialDate, stackId }: NewEventPageProps) {
  const { t } = useTranslation();
  const updateFeedData = useFeedOptimisticUpdater();

  const [formToSave, setFormToSave] = useState<
    CreateEventRequestBody | undefined
  >(undefined);
  const navigate = useNavigate();

  const creteEvent = useCreateEvent({
    onSuccess: (data) => {
      updateFeedData((feedData) => {
        const updatedPages = feedData.pages.map((page, index) => {
          const queryParams = feedData.pageParams[index].queryParams;
          if (!queryParams?.from || !queryParams?.to) {
            return page;
          }

          if (
            isWithinInterval(
              data.event.data.attributes.start_at ?? new Date(),
              interval(queryParams.from, queryParams.to),
            )
          ) {
            return { events: { data: [...page.events.data, data.event.data] } };
          }

          return page;
        });

        return {
          pageParams: feedData.pageParams,
          pages: updatedPages,
        };
      });
    },
  });

  useEffect(() => {
    if (creteEvent.isSuccess) {
      navigate({
        params: { eventId: creteEvent.data.event.data.id },
        search: {
          stackId: creteEvent.data.event.data.attributes.stack_id ?? undefined,
        },
        to: '/events/$eventId/created',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creteEvent.isSuccess]);

  const handleOverlapCancel = () => setFormToSave(undefined);

  const handleOverlapContinue = () => {
    setFormToSave(undefined);
    creteEvent.mutate({ body: formToSave! });
  };

  const handleSubmit: EditEventFormProps['onSubmit'] = (form) => {
    if (form.stack_id) {
      creteEvent.mutate({ body: form });
    } else {
      setFormToSave(form);
    }
  };

  const handleGoBack = () => {
    if (stackId) {
      navigate({ params: { stackId }, to: '/stacks/$stackId/view' });
    } else {
      navigate({
        search: initialDate
          ? { date: format(initialDate, DATE_SEARCH_PARAM_FORMAT) }
          : {},
        to: '/feed',
      });
    }
  };

  const hasFormToSave = Boolean(formToSave);

  return (
    <>
      <EditEventForm
        hideSubmitButton={hasFormToSave}
        initialDate={initialDate}
        isPending={creteEvent.isPending}
        onBack={handleGoBack}
        onSubmit={handleSubmit}
        stackId={stackId}
        submitButtonText={t('all:event.buttons.create')}
      />
      <OverlapEvents
        duration={formToSave ? formToSave.duration.toString() : ''}
        enabled={hasFormToSave}
        onCancel={handleOverlapCancel}
        onContinue={handleOverlapContinue}
        start_at={formToSave ? formToSave.start_at : ''}
      />
    </>
  );
}
