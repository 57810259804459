import { useEffect } from 'react';

import { getWebApp } from './getWebApp';
import { useCallbackRef } from './index';

export function useBackNavigation(onBack?: () => void, active = true) {
  const onBackCallbackRef = useCallbackRef(onBack);

  useEffect(() => {
    if (active) {
      getWebApp().BackButton.show();

      return () => {
        getWebApp().BackButton.hide();
      };
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      const handleBackClick = () => {
        onBackCallbackRef?.();
      };

      getWebApp().BackButton.onClick(handleBackClick);

      return () => {
        getWebApp().BackButton.offClick(handleBackClick);
      };
    }
  }, [onBackCallbackRef, active]);
}
