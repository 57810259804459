import { format } from 'date-fns';
import { enGB, ru } from 'date-fns/locale';

import { useAppLang } from './useAppLang';

const LangToLocaleNap = {
  en: enGB,
  ru,
} as const;
const FormatStringMapping: Record<string, string> = {
  E: 'EEEEEE',
};

export function useLocalisedDateFormat() {
  const { appLang } = useAppLang();
  const userLocale = LangToLocaleNap[appLang];
  const localisedFormat: typeof format = (date, formatStr, options) =>
    format(
      date,
      userLocale === ru
        ? (FormatStringMapping[formatStr] ?? formatStr)
        : formatStr,
      {
        ...options,
        locale: userLocale,
      },
    );

  return {
    format: localisedFormat,
    userLocale,
  };
}
