import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { tzOffset } from '@date-fns/tz';
import { zodResolver } from '@hookform/resolvers/zod';
import { Time } from '@internationalized/date';
import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  differenceInMilliseconds,
  differenceInMinutes,
  getHours,
  isSameDay,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Separator,
  Sheet,
  SheetTrigger,
  Textarea,
  TimeInput,
} from '@tg-web/components';
import {
  DateArrowIcon,
  DateIcon,
  DescriptionIcon,
  GlobeIcon,
  LocationIcon,
  NotificationIcon,
  RepeatIcon,
  ShevronRightIcon,
  TitleIcon,
  UsersIcon,
  VideoIcon,
} from '@tg-web/icons';
import {
  cn,
  useBackNavigation,
  useCallbackRef,
  useDisclosure,
} from '@tg-web/utils';

import { InviteInstructionModal } from '../../../features/events/ui/InviteInstructionModal';
import { TimezonesModal } from '../../../features/events/ui/TimezonesModal';
import {
  NotificationDuration,
  NotificationDurationLabels,
  NotificationModal,
} from '../../../features/notifications';
import {
  RecurrentModal,
  RecurrentType,
  RecurrentTypesLabels,
} from '../../../features/recurrency';
import { CreateEventRequestBody } from '../../../shared/api';
import { dateTimeJoin } from '../../../shared/lib/dateTimeJoin';
import { dateTimeSplit } from '../../../shared/lib/dateTimeSplit';
import { formatTimeZone } from '../../../shared/lib/formatTimeZone';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { useMainButton } from '../../../shared/lib/useMainButton';
import { useScrollFixerDisabler } from '../../../shared/ui/AdditionalScrollFixer';
import { DatePickerSheetContent } from '../../../shared/ui/DatePickerSheetContent';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { TextAreaModal } from '../../../shared/ui/TextAreaModal';
import { TruncateContainer } from '../../../shared/ui/TruncateContainer';
import { ViewField } from '../../../shared/ui/ViewField';
import { MY_CALENDAR_FAKE_STACK_ID, StackSelect } from './StackSelect';

const formSchema = z
  .object({
    description: z.string().min(0),
    endsAtDate: z.date(),
    endsAtTime: z.instanceof(Time),
    location: z.string().min(0),
    notifyBefore: z.number(),
    recurrent: z.number(),
    stackId: z.string().optional(),
    startsAtDate: z.date(),
    startsAtTime: z.instanceof(Time),
    timezone: z.string(),
    title: z.string().min(0),
    videoLink: z.string().min(0),
  })
  .superRefine((form, ctx) => {
    const milliseconds = differenceInMilliseconds(
      dateTimeJoin(form.endsAtDate, form.endsAtTime),
      dateTimeJoin(form.startsAtDate, form.startsAtTime),
    );

    if (milliseconds < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The start time must be before the end time',
        path: ['endsAtDate'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The start time must be before the end time',
        path: ['endsAtTime'],
      });
    }
  });
type EventForm = z.infer<typeof formSchema>;

function getInitialForm(
  initialDate: Date,
  stackId: string | undefined,
): EventForm {
  const initialDateDayStart = startOfDay(initialDate);
  const startTimeDate = addHours(initialDate, 1);
  const endTimeDate = addHours(startTimeDate, 1);

  return {
    description: '',
    endsAtDate: isSameDay(startTimeDate, endTimeDate)
      ? initialDateDayStart
      : addDays(initialDateDayStart, 1),
    endsAtTime: new Time(getHours(endTimeDate), 0),
    location: '',
    notifyBefore: NotificationDuration.halfHour,
    recurrent: RecurrentType.noRecurrency,
    stackId: stackId ?? MY_CALENDAR_FAKE_STACK_ID,
    startsAtDate: initialDateDayStart,
    startsAtTime: new Time(getHours(startTimeDate), 0),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    title: '',
    videoLink: '',
  };
}

type FieldToEdit = 'description' | 'location' | 'videoLink';

const validationSchemas = {
  description: undefined,
  location: undefined,
  videoLink: z.object({ value: z.string().url().or(z.literal('')) }),
} as const;

export type EditEventFormProps = {
  hideSubmitButton?: boolean;
  initialDate?: Date;
  initialForm?: EventForm;
  isPending?: boolean;
  onBack?: () => void;
  onSubmit: (form: CreateEventRequestBody) => void;
  stackId?: string;
  submitButtonText: string;
};

export function EditEventForm({
  hideSubmitButton = false,
  initialDate = new Date(),
  initialForm,
  isPending,
  onBack,
  onSubmit,
  stackId: initialStackId,
  submitButtonText,
}: EditEventFormProps) {
  const { format } = useLocalisedDateFormat();
  const { t } = useTranslation();
  const onSubmitCallbackRef = useCallbackRef(onSubmit);

  const timezonesModal = useDisclosure();
  const recurrentModal = useDisclosure();
  const notificationModal = useDisclosure();
  const calendarStartModal = useDisclosure();
  const calendarEndModal = useDisclosure();
  const inviteModal = useDisclosure();
  const [fieldToEdit, setFieldToEdit] = useState<FieldToEdit | undefined>(
    undefined,
  );
  const isSomeModalShown =
    timezonesModal.open ||
    recurrentModal.open ||
    notificationModal.open ||
    calendarStartModal.open ||
    calendarEndModal.open ||
    inviteModal.open ||
    fieldToEdit !== undefined;

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const form = useForm<EventForm>({
    defaultValues: initialForm ?? getInitialForm(initialDate, initialStackId),
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  });

  const [duration, setDuration] = useState(() =>
    form.getValues('endsAtTime').compare(form.getValues('startsAtTime')),
  );

  const [startsAtDate, startsAtTime, endsAtDate, endsAtTime, stackId] =
    form.watch([
      'startsAtDate',
      'startsAtTime',
      'endsAtDate',
      'endsAtTime',
      'stackId',
    ]);
  // Change endsAtTime after changing of startsAtTime
  useEffect(() => {
    if (startsAtDate && startsAtTime && duration > 0) {
      const [date, time] = dateTimeSplit(
        addMilliseconds(dateTimeJoin(startsAtDate, startsAtTime), duration),
      );
      form.setValue('endsAtDate', date);
      form.setValue('endsAtTime', time);
      form.trigger('endsAtDate');
      form.trigger('endsAtTime');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startsAtDate, startsAtTime]);

  // Change duration after changing of endsAtTime
  useEffect(() => {
    if (endsAtDate && endsAtTime) {
      setDuration(
        differenceInMilliseconds(
          dateTimeJoin(endsAtDate, endsAtTime),
          dateTimeJoin(startsAtDate, startsAtTime),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endsAtDate, endsAtTime]);

  useScrollFixerDisabler();

  useBackNavigation(() => {
    if (fieldToEdit) {
      setFieldToEdit(undefined);
    } else {
      onBack?.();
    }
  }, !isSomeModalShown);

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit(
        (values) => {
          const startDate = setMinutes(
            setHours(startOfDay(values.startsAtDate), values.startsAtTime.hour),
            values.startsAtTime.minute,
          );
          const endDate = setMinutes(
            setHours(startOfDay(values.endsAtDate), values.endsAtTime.hour),
            values.endsAtTime.minute,
          );
          const isStackEvent = values.stackId !== MY_CALENDAR_FAKE_STACK_ID;

          onSubmitCallbackRef({
            description: values.description,
            duration: differenceInMinutes(endDate, startDate),
            location: values.location,
            notify_before: isStackEvent ? 0 : values.notifyBefore,
            recurrent_type: isStackEvent ? 0 : values.recurrent,
            stack_id: isStackEvent ? values.stackId : undefined,
            start_at: addMinutes(
              startDate,
              -tzOffset(values.timezone, startDate) -
                startDate.getTimezoneOffset(),
            ).toISOString(),
            timezone: values.timezone,
            title: values.title || t('all:event.edit_from.default_event_title'),
            video_link: values.videoLink,
          });
        },
        (errors: FieldErrors<EventForm>) => {
          if (errors.endsAtTime?.message) {
            setErrorMessage(errors.endsAtTime.message);
            setShowErrorMessage(true);
          }
        },
      ),
    [form, onSubmitCallbackRef],
  );

  useMainButton({
    isHidden: hideSubmitButton || isSomeModalShown,
    isLoading: isPending,
    onClick: handleSubmit,
    text: submitButtonText,
  });

  const fieldTitles = useMemo<Record<FieldToEdit, string>>(
    () => ({
      description: 'Description',
      location: 'Location or call link',
      videoLink: 'Video Call Link',
    }),
    [],
  );

  const fieldPlaceholders = useMemo<Record<FieldToEdit, string>>(
    () => ({
      description: 'Add event description',
      location: 'Add event location',
      videoLink: 'Add video call link',
    }),
    [],
  );

  return (
    <PageWrapper className="h-auto">
      <Form {...form}>
        <form
          className="flex flex-col justify-between gap-3 p-5 pb-6"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-3">
            <FormField
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <StackSelect
                      onValueChange={field.onChange}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              control={form.control}
              name="stackId"
            />
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      leftIcon={<TitleIcon className="text-tg-hint" />}
                      placeholder={t('all:event.edit_from.event_title')}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              control={form.control}
              name="title"
            />
            <div className="flex flex-col">
              <ViewField className="w-full p-0" innerClassName="w-full">
                <div className="flex flex-row items-center justify-between p-1">
                  <DateIcon className="text-tg-hint ml-2" />
                  <span className="typo-text text-tg-hint">
                    {t('all:event.edit_from.start_date.label')}
                  </span>
                  <div className="flex flex-row gap-1">
                    <FormField
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Sheet
                              onOpenChange={calendarStartModal.onToggle}
                              open={calendarStartModal.open}
                            >
                              <SheetTrigger asChild>
                                <Input
                                  value={
                                    field.value
                                      ? format(field.value, 'dd MMM yyyy')
                                      : ''
                                  }
                                  className="bg-tg-secondary-bg h-12"
                                />
                              </SheetTrigger>
                              <DatePickerSheetContent
                                onSelect={(value) => {
                                  field.onChange(value);
                                  form.trigger('endsAtDate');
                                  form.trigger('endsAtTime');
                                }}
                                title={t(
                                  'all:event.edit_from.start_date.modal_title',
                                )}
                                onClose={calendarStartModal.onClose}
                                open={calendarStartModal.open}
                                value={field.value}
                              />
                            </Sheet>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                      control={form.control}
                      name="startsAtDate"
                    />
                    <FormField
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <TimeInput
                              {...field}
                              onChange={(value) => {
                                field.onChange(value);
                                form.trigger('endsAtDate');
                                form.trigger('endsAtTime');
                              }}
                              className="bg-tg-secondary-bg h-12"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                      control={form.control}
                      name="startsAtTime"
                    />
                  </div>
                </div>
                <DateArrowIcon className="text-tg-hint -mb-3 -mt-3 ml-5 h-6" />
                <div className="flex flex-row items-center justify-between p-1">
                  <DateIcon className="text-tg-hint ml-2" />
                  <span className="typo-text text-tg-hint">
                    {t('all:event.edit_from.end_date.label')}
                  </span>
                  <div className="flex flex-row gap-1">
                    <FormField
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <FormControl>
                            <Sheet
                              onOpenChange={calendarEndModal.onToggle}
                              open={calendarEndModal.open}
                            >
                              <SheetTrigger asChild>
                                <Input
                                  className={cn('bg-tg-secondary-bg h-12', {
                                    'text-tg-destructive-text':
                                      fieldState.error,
                                  })}
                                  value={
                                    field.value
                                      ? format(field.value, 'dd MMM yyyy')
                                      : ''
                                  }
                                />
                              </SheetTrigger>
                              <DatePickerSheetContent
                                onSelect={(value) => {
                                  field.onChange(value);
                                  form.trigger('endsAtTime');
                                }}
                                title={t(
                                  'all:event.edit_from.end_date.modal_title',
                                )}
                                onClose={calendarEndModal.onClose}
                                open={calendarEndModal.open}
                                value={field.value}
                              />
                            </Sheet>
                          </FormControl>
                        </FormItem>
                      )}
                      control={form.control}
                      name="endsAtDate"
                    />
                    <FormField
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <FormControl>
                            <TimeInput
                              {...field}
                              className={cn('bg-tg-secondary-bg h-12', {
                                'text-tg-destructive-text': fieldState.error,
                              })}
                              onChange={(value) => {
                                field.onChange(value);
                                form.trigger('endsAtDate');
                                form.trigger('endsAtDate');
                              }}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                      control={form.control}
                      name="endsAtTime"
                    />
                  </div>
                </div>
              </ViewField>
              {initialForm?.videoLink && (
                <FormField
                  render={({ field }) => (
                    <FormControl>
                      <ViewField
                        rightIcon={
                          <ShevronRightIcon className="text-tg-hint" />
                        }
                        innerClassName="min-w-0 -mr-5 w-full"
                        leftIcon={<VideoIcon className="text-tg-hint" />}
                        onClick={() => setFieldToEdit('videoLink')}
                      >
                        {field.value ? (
                          <TruncateContainer
                            bgColor="section-bg"
                            className="text-tg-link"
                          >
                            {field.value}
                          </TruncateContainer>
                        ) : (
                          <span className="typo-text text-tg-hint">
                            {t('all:event.edit_from.video_link')}
                          </span>
                        )}
                      </ViewField>
                    </FormControl>
                  )}
                  control={form.control}
                  name="videoLink"
                />
              )}
              <FormField
                render={({ field }) => {
                  const { city, offset } = formatTimeZone(field.value);
                  return (
                    <FormControl>
                      <ViewField
                        rightIcon={
                          <ShevronRightIcon className="text-tg-hint" />
                        }
                        leftIcon={<GlobeIcon className="text-tg-hint" />}
                        onClick={timezonesModal.onOpen}
                      >
                        {city}{' '}
                        {field.value ===
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                          ? `(${t('all:event.timezone.your_timezone')})`
                          : offset}
                      </ViewField>
                    </FormControl>
                  );
                }}
                control={form.control}
                name="timezone"
              />
              {stackId === MY_CALENDAR_FAKE_STACK_ID && (
                <>
                  <FormField
                    render={({ field }) => (
                      <FormControl>
                        <ViewField
                          rightIcon={
                            <ShevronRightIcon className="text-tg-hint" />
                          }
                          leftIcon={<RepeatIcon className="text-tg-hint" />}
                          onClick={recurrentModal.onOpen}
                        >
                          {t(
                            RecurrentTypesLabels[field.value as RecurrentType],
                          )}
                        </ViewField>
                      </FormControl>
                    )}
                    control={form.control}
                    name="recurrent"
                  />
                  <FormField
                    render={({ field }) => (
                      <FormControl>
                        <ViewField
                          leftIcon={
                            <NotificationIcon className="text-tg-hint" />
                          }
                          rightIcon={
                            <ShevronRightIcon className="text-tg-hint" />
                          }
                          onClick={notificationModal.onOpen}
                        >
                          {t(
                            NotificationDurationLabels[
                              field.value as NotificationDuration
                            ],
                          )}
                        </ViewField>
                      </FormControl>
                    )}
                    control={form.control}
                    name="notifyBefore"
                  />
                </>
              )}
            </div>
            <div>
              <ViewField
                leftIcon={<UsersIcon className="text-tg-hint" />}
                onClick={inviteModal.onOpen}
                rightIcon={<ShevronRightIcon className="text-tg-hint" />}
              >
                <span className="typo-text text-tg-hint">
                  {t('all:event.edit_from.invite')}
                </span>
              </ViewField>
            </div>
            <div>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        leftIcon={<LocationIcon className="text-tg-hint" />}
                        placeholder={t('all:event.edit_from.location')}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
                control={form.control}
                name="location"
              />
            </div>
            <div>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        {...field}
                        leftIcon={<DescriptionIcon className="text-tg-hint" />}
                        placeholder={t('all:event.edit_from.description')}
                        rows={6}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
                control={form.control}
                name="description"
              />
            </div>
          </div>
        </form>
      </Form>
      <TextAreaModal
        onSave={(value: string) => {
          if (fieldToEdit) {
            form.setValue(fieldToEdit, value);
          }
        }}
        validationSchema={
          fieldToEdit ? validationSchemas[fieldToEdit] : undefined
        }
        initialValue={fieldToEdit && form.getValues(fieldToEdit)}
        onClose={() => setFieldToEdit(undefined)}
        open={Boolean(fieldToEdit)}
        placeholder={fieldToEdit ? fieldPlaceholders[fieldToEdit] : ''}
        title={fieldToEdit ? fieldTitles[fieldToEdit] : ''}
      />
      <TimezonesModal
        onSave={(value) => {
          form.setValue('timezone', value);
        }}
        initial={form.getValues('timezone')}
        onClose={timezonesModal.onClose}
        open={timezonesModal.open}
      />
      <RecurrentModal
        onSave={(value: number) => {
          form.setValue('recurrent', value);
        }}
        initial={form.getValues('recurrent').toString()}
        onClose={recurrentModal.onClose}
        open={recurrentModal.open}
      />
      <NotificationModal
        onSave={(value: number) => {
          form.setValue('notifyBefore', value);
        }}
        initial={form.getValues('notifyBefore') as NotificationDuration}
        onClose={notificationModal.onClose}
        open={notificationModal.open}
      />
      <InviteInstructionModal
        isOpen={inviteModal.open}
        onClose={inviteModal.onClose}
      />
      <AlertDialog open={showErrorMessage}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('all:event.edit_from.error')}
            </AlertDialogTitle>
            <AlertDialogDescription>{errorMessage}</AlertDialogDescription>
          </AlertDialogHeader>
          <Separator />
          <AlertDialogFooter>
            <Button
              className="text-tg-link typo-text"
              onClick={() => setShowErrorMessage(false)}
              size="small"
              variant="ghost"
            >
              {t('all:common.buttons.ok')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </PageWrapper>
  );
}
