const SDK_URL = 'https://accounts.google.com/gsi/client';

export function loadGoogleSdkScript() {
  return new Promise<void>((resolve, reject) => {
    const existingScript = document.querySelector(
      `script[src="${SDK_URL}"]`,
    ) as HTMLScriptElement;
    if (existingScript) {
      existingScript.onload = () => resolve();
      existingScript.onerror = (err) => reject(err);
      return;
    }

    const script = document.createElement('script');
    script.src = SDK_URL;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });
}
