import React from 'react';

import { IconProps } from './types';

export const LocationIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M25.9844 13.9844C25.9844 8.45313 21.4844 3.96876 15.9479 4.00001C10.6979 4.02605 6.25003 8.31772 6.02086 13.5573C5.78649 19.0677 9.21357 24.1823 13.6407 27.1927C13.8646 27.3438 14.0938 27.4948 14.3229 27.6354C15.4063 28.3021 16.7865 28.2448 17.8542 27.5521C22.1563 24.7448 25.9844 19.2865 25.9844 13.9844Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M19 13C19 14.6562 17.6562 16 16 16C14.3438 16 13 14.6562 13 13C13 11.3438 14.3438 10 16 10C17.6562 10 19 11.3438 19 13Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
