import React from 'react';

import { IconProps } from './types';

export const CheckmarkIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M10 15C11.5625 15.9115 13.8594 18.3125 15 20C18 15 23 9 28 7"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M28 16C28 22.625 22.625 28 16 28C9.375 28 4 22.625 4 16C4 9.375 9.375 4 16 4C22.625 4 28 9.375 28 16Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
