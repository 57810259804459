import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';

import { EmptyStacks, StacksList } from '../../../features/stacks';
import { useListStack } from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function StacksPage() {
  const { t } = useTranslation();

  const myStacksReq = useListStack({
    queryParams: {
      page_number: 1,
      page_size: 100,
      roles: ['owner'],
    },
  });

  const subscribedStacksReq = useListStack({
    queryParams: {
      page_number: 1,
      page_size: 100,
      roles: ['reader', 'creator'],
    },
  });

  if (myStacksReq.isPending || subscribedStacksReq.isPending) {
    return <GlobalLoading />;
  }

  if (myStacksReq.isError || subscribedStacksReq.isError) {
    throw new Error('Unexpected API error');
  }
  const areMyStacksEmpty = myStacksReq.data.stacks.length === 0;
  const areSubscribedStacksEmpty = subscribedStacksReq.data.stacks.length === 0;

  return (
    <PageWrapper className="flex flex-col gap-10 px-5 pb-20 pt-6">
      {areMyStacksEmpty && areSubscribedStacksEmpty ? (
        <EmptyStacks />
      ) : (
        <>
          <div className="flex flex-col gap-4">
            <span className="typo-header-big">
              {t('all:stacks.list.my_stacks')}
            </span>
            {areMyStacksEmpty ? (
              <div className="bg-tg-section-bg typo-text text-tg-hint flex flex-col rounded-2xl px-4 py-6 text-center">
                <span>{t('all:stacks.list.empty.text')}</span>
                <span>{t('all:stacks.list.empty.subtext')}</span>
              </div>
            ) : (
              <StacksList stacks={myStacksReq.data.stacks} />
            )}
            <Button variant="tertiary">
              <Link to="/stacks/new">{t('all:stacks.list.new')}</Link>
            </Button>
          </div>
          {!areSubscribedStacksEmpty && (
            <div className="flex flex-col gap-4">
              <span className="typo-header-small">
                {t('all:stacks.list.subscribed')}
              </span>
              <div className="flex flex-col gap-3">
                <StacksList stacks={subscribedStacksReq.data.stacks} />
              </div>
            </div>
          )}
        </>
      )}
    </PageWrapper>
  );
}
