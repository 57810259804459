import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';

import { useAppLang } from '../shared/lib/useAppLang';
import { ErrorPage } from '../shared/ui/ErrorPage';
import { RouteChangeAnalyticsTracker } from '../shared/ui/RouteChangeAnalyticsTracker';
import { TanStackRouterDevtoolsOnlyDev } from '../shared/ui/TanStackRouterDevtoolsOnlyDev';

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
  {
    component: Root,
    errorComponent: ErrorPage,
    notFoundComponent: () => {
      return <h2>404 Not Found</h2>;
    },
  },
);

function Root() {
  const [isLangSet, setIsLangSet] = useState(false);
  const { i18n } = useTranslation();
  const { appLang } = useAppLang();

  useEffect(() => {
    i18n.changeLanguage(appLang, () => setIsLangSet(true));
  }, [appLang]);

  // avoid language flashing
  if (!isLangSet) {
    return null;
  }

  return (
    <>
      <Outlet />
      <RouteChangeAnalyticsTracker />
      <ReactQueryDevtools buttonPosition="bottom-right" />
      <TanStackRouterDevtoolsOnlyDev position="bottom-left" />
    </>
  );
}
