import { ComponentProps } from 'react';

import { JitsuProvider } from '@jitsu/jitsu-react';
import * as Sentry from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';

import { getWebApp } from '@tg-web/utils';

import { routeTree } from './routeTree.gen';

const queryClient = new QueryClient();

// Create a new router instance
const router = createRouter({
  context: {
    queryClient,
  },
  defaultPendingMs: 300,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  routeTree,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

Sentry.init({
  beforeSend: (event) => {
    // Pass telegram data to events
    if (getWebApp()) {
      const modifiedEvent = { ...event };
      modifiedEvent.extra = {
        ...(event.extra ?? {}),
        tgWebAppInfo: {
          auth_date: getWebApp().initDataUnsafe.auth_date,
          colorScheme: getWebApp().colorScheme,
          language_code: getWebApp().initDataUnsafe.user?.language_code,
          platform: getWebApp().platform,
          start_param: getWebApp().initDataUnsafe.start_param,
          themeParams: getWebApp().themeParams,
          user_id: getWebApp().initDataUnsafe.user?.id,
          username: getWebApp().initDataUnsafe.user?.username,
          version: getWebApp().version,
          viewportHeight: getWebApp().viewportHeight,
        },
      };

      return modifiedEvent;
    }

    return event;
  },
  dsn: 'https://90e16d5ad8fe965c23b08824f8dfd8cb@o4508335988473856.ingest.de.sentry.io/4508360380383312',
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],

  normalizeDepth: 10,

  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,

  // Setting a sample rate is required for sending performance data.
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control.
  tracesSampleRate: 1.0,
});

const jitsuOptions: ComponentProps<typeof JitsuProvider>['options'] = {
  echoEvents: import.meta.env.VITE_APP_ENV === 'development',
  host: import.meta.env.VITE_JITSU_HOST,
  writeKey: import.meta.env.VITE_JITSU_WRITE_KEY,
};

export { jitsuOptions, queryClient, router };
