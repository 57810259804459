import { useEffect } from 'react';

import { useJitsu } from '@jitsu/jitsu-react';
import { useRouter } from '@tanstack/react-router';

export function RouteChangeAnalyticsTracker() {
  const router = useRouter();
  const { analytics } = useJitsu();

  useEffect(() => {
    const unsubscribe = router.subscribe('onLoad', (newState) => {
      analytics.page({
        fromLocation: newState.fromLocation.href,
        toLocation: newState.toLocation.href,
      });
    });

    return () => {
      unsubscribe();
    };
  }, [analytics, router]);

  return null;
}
