import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { noop } from '@tg-web/utils';

const AdditionalScrollFixerContext =
  createContext<(isDisabled: boolean) => void>(noop);

/**
 * Fixes additional page scroll on mobile devices inside of TG webview
 */
export function AdditionalScrollFixer({ children }: PropsWithChildren) {
  const [isDisabled, setIsDisabled] = useState(false);

  useLayoutEffect(() => {
    if (isDisabled) {
      return;
    }

    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.height = '';
      document.body.style.overflow = '';
    };
  }, [isDisabled]);

  return (
    <AdditionalScrollFixerContext.Provider value={setIsDisabled}>
      {children}
    </AdditionalScrollFixerContext.Provider>
  );
}

/**
 * Disables scroll fix. Can be useful for cases when page has inputs on the bottom.
 */
export function useScrollFixerDisabler() {
  const disableScrollFixer = useContext(AdditionalScrollFixerContext);

  useEffect(() => {
    disableScrollFixer(true);

    return () => {
      disableScrollFixer(false);
    };
  }, [disableScrollFixer]);
}
