import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getWebApp, useCallbackRef } from '@tg-web/utils';

import { Event, StackEvent, useAttendEvent } from '../../../shared/api';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { getFeedSearch } from '../../feed';
import { usePrompt } from '../../prompt';
import { RecurrentType } from '../../recurrency';
import { AttendStatus } from '../model';
import { isStackEvent } from './isStackEvent';

export function useAttendEventHandler(
  event: Event | StackEvent,
  attendStatus: AttendStatus,
  onSuccess?: () => void,
) {
  const { t } = useTranslation();
  const prompt = usePrompt();
  const onSuccessCallbackRef = useCallbackRef(onSuccess);

  const attendEvent = useAttendEvent();

  useEffect(() => {
    if (attendEvent.isSuccess) {
      getCloudStorageItem(CloudStorageKeys.SHOW_WELCOME).then((value) => {
        if (value) {
          getWebApp().CloudStorage.setItem(
            CloudStorageKeys.FEED_SEARCH,
            JSON.stringify(getFeedSearch(attendEvent.data.event.data)),
          );
        }
      });
      onSuccessCallbackRef();
    }
  }, [attendEvent.isSuccess, onSuccessCallbackRef]);

  // TODO: handle error

  return [
    async () => {
      let dependentAttend = 0;
      const recurrentType = isStackEvent(event)
        ? event.recurrent_type
        : event.attributes.recurrent_type;
      if (recurrentType !== RecurrentType.noRecurrency) {
        const promptStatus = await prompt({
          discardButton: { label: t('all:common.buttons.cancel') },
          secondaryButton: { label: t('all:common.buttons.all_events') },
          submitButton: { label: t('all:common.buttons.only_this') },
          subtitle: t('all:event.join_dialog.title'),
          title: t('all:event.join_dialog.subtitle'),
        });
        if (promptStatus === 'close' || promptStatus === 'discard') {
          return;
        }
        if (promptStatus === 'secondary') {
          dependentAttend = 1;
        }
      }

      attendEvent.mutate({
        body: { dependent_attend: dependentAttend, status: attendStatus },
        pathParams: { id: event.id },
      });
    },
    attendEvent,
  ] as const;
}
