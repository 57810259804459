import { UseQueryOptions } from '@tanstack/react-query';

import {
  UserProfileError,
  UserProfileResponse,
  useUserProfile,
} from '../generated';

const FIVE_MINUTES = 5 * 60 * 1000;

export function useUserProfileConfigured(
  options: Omit<
    UseQueryOptions<UserProfileResponse, UserProfileError, UserProfileResponse>,
    'initialData' | 'queryFn' | 'queryKey'
  > | void,
) {
  return useUserProfile({}, { staleTime: FIVE_MINUTES, ...(options ?? {}) });
}
