import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { BoringIcon, CircleIconWrapper } from '@tg-web/icons';

import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function EventNotFound() {
  const { t } = useTranslation();

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="grow" />
      <div className="flex shrink-0 flex-col items-center">
        <CircleIconWrapper className="mb-5">
          <BoringIcon />
        </CircleIconWrapper>
        <h1 className="typo-header-biggest mb-3 text-center">
          {t('all:event.not_found.title')}
        </h1>
        <p className="typo-text text-tg-subtitle-text mb-8 text-center">
          {t('all:event.not_found.subtitle')}
        </p>
        <Button size="small" variant="primary" asChild>
          <Link to="/events/new" replace>
            {t('all:common.buttons.new_event')}
          </Link>
        </Button>
      </div>
      <div className="grow" />
      <div className="grow" />
    </PageWrapper>
  );
}
