import { createFileRoute } from '@tanstack/react-router';

import { ViewEventPage } from '../../../../pages/events/ui/ViewEventPage';

export const Route = createFileRoute('/_authorized/events/$eventId/view')({
  component: Page,
});

function Page() {
  const { eventId } = Route.useParams();

  return <ViewEventPage eventId={eventId} />;
}
