import { useTranslation } from 'react-i18next';

import { Skeleton } from '@tg-web/components';

import { StackEvents } from '../index';
import { StackEventsList } from './StackEventsList';
import { StackEventsWrapper } from './StackEventsWrapper';

export interface PassedStackEventsProps {
  events: StackEvents;
  isLoading: boolean;
}

export function PassedStackEvents({
  events,
  isLoading,
}: PassedStackEventsProps) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <StackEventsWrapper className="gap-3 px-5 py-2">
        <Skeleton className="bg-secondary h-[38px] w-full" />
        <Skeleton className="bg-secondary h-[110px] w-full" />
        <Skeleton className="bg-secondary h-[110px] w-full" />
        <Skeleton className="bg-secondary h-[110px] w-full" />
      </StackEventsWrapper>
    );
  }

  if (events.length === 0) {
    return (
      <StackEventsWrapper className="items-center gap-4 px-5 py-10">
        <span className="typo-text text-center">
          {t('all:stacks.view.past.description')}
        </span>
      </StackEventsWrapper>
    );
  }

  return (
    <StackEventsWrapper className="gap-6 px-5 py-2">
      <StackEventsList events={events} />
    </StackEventsWrapper>
  );
}
