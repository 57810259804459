import { useTranslation } from 'react-i18next';

import { PropsWithCN, cn } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import {
  NotificationDuration,
  NotificationDurationLabels,
} from '../../notifications';
import { RecurrentType, RecurrentTypeInfo } from '../../recurrency';
import { useFormatEventDateAndTime } from '../lib';

export type EventInfoCardProps = {
  event: Event;
  /** Disable card border */
  isBorderless?: boolean;
  /** Show a static stack of cards below the main card */
  isMulti?: boolean;
} & PropsWithCN;

export function EventInfoCard({
  className,
  event,
  isBorderless,
  isMulti,
}: EventInfoCardProps) {
  const { t } = useTranslation();
  const { formatEventDateAndTime } = useFormatEventDateAndTime();

  const notificationText =
    NotificationDuration.noNotify === event.attributes.notify_before
      ? undefined
      : //TODO: change text source
        t(
          NotificationDurationLabels[
            event.attributes.notify_before as NotificationDuration
          ],
        );

  return (
    <div className={cn('relative w-full', className)}>
      <div
        className={cn(
          'bg-tg-section-bg relative z-10 rounded-2xl',
          !isBorderless && 'border-03 border-tg-section-separator',
        )}
      >
        <div className="border-tg-section-separator border-b-03 min-h-[50px] px-4 pb-3 pt-4">
          <h2 className="typo-header-small">{event.attributes.title}</h2>
        </div>
        <div className="flex flex-col justify-center gap-0.5 px-4 pb-4 pt-3">
          <p className="typo-text-bold">
            {formatEventDateAndTime(event)}
            {event.attributes.recurrent_type !== RecurrentType.noRecurrency && (
              <>
                <br />
                {t(
                  RecurrentTypeInfo[
                    event.attributes.recurrent_type as RecurrentType
                  ],
                )}
              </>
            )}
          </p>
          <p className="typo-text text-tg-hint">{notificationText}</p>
        </div>
      </div>
      {isMulti && (
        <>
          <div className="border-tg-section-separator bg-tg-section-bg border-03 absolute bottom-0 h-14 w-full translate-y-4 scale-[0.85] rounded-2xl" />
          <div className="border-tg-section-separator bg-tg-section-bg border-03 absolute bottom-0 h-14 w-full translate-y-2 scale-[0.93] rounded-2xl" />
        </>
      )}
    </div>
  );
}
