import { useMemo, useState } from 'react';

type InitialState = (() => boolean) | boolean;

/**
 * React hook to manage boolean (on - off) states
 *
 * @param initialState the initial boolean state value
 *
 * @see Docs https://chakra-ui.com/docs/hooks/use-boolean
 */
export function useBoolean(initialState: InitialState = false) {
  const [value, setValue] = useState(initialState);
  const callbacks = useMemo(
    () => ({
      off: () => setValue(false),
      on: () => setValue(true),
      toggle: () => setValue((prev) => !prev),
    }),
    [],
  );
  return [value, callbacks] as const;
}
