'use client';

import React from 'react';

import { cn } from '@tg-web/utils';

interface AvatarCirclesProps {
  avatarUrls: string[];
  className?: string;
  numOfAvatars?: number;
  numPeople?: number;
}

export const AvatarCircles = ({
  avatarUrls,
  className,
  numOfAvatars,
  numPeople,
}: AvatarCirclesProps) => {
  const avatarsForShow = avatarUrls.slice(
    0,
    numOfAvatars ? numOfAvatars : avatarUrls.length,
  );

  let countOfPlusPeople = numPeople ?? 0;
  if (numOfAvatars !== undefined && numOfAvatars > 0) {
    countOfPlusPeople = Math.max(0, countOfPlusPeople - avatarsForShow.length);
  }

  return (
    <div className={cn('z-10 flex -space-x-2 rtl:space-x-reverse', className)}>
      {avatarsForShow.map((url, index) => (
        <img
          alt={`Avatar ${index + 1}`}
          className="border-tg-section-bg h-[26px] w-[26px] rounded-full border-2"
          key={index}
          src={url}
        />
      ))}
      {countOfPlusPeople > 0 && (
        <div className="bg-tg-button text-tg-button-text typo-small border-tg-button flex h-[26px] w-[26px] items-center justify-center rounded-full border-2">
          +{countOfPlusPeople}
        </div>
      )}
    </div>
  );
};
