import { StartParams } from '../model/startParamsStore';

export function parseStartappParam(startappParam: string) {
  const parsedBase64 = atob(startappParam);
  const params = JSON.parse(parsedBase64);

  // TODO: validate

  return params as StartParams;
}
