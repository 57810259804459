import { addMinutes, interval } from 'date-fns';

import { Event, StackEvent } from '../../../shared/api';
import { isStackEvent } from './isStackEvent';

export function getEventInterval(event: Event | StackEvent) {
  const startAt = isStackEvent(event)
    ? event.start_at
    : event.attributes.start_at;
  const duration = isStackEvent(event)
    ? event.duration
    : event.attributes.duration;
  return interval(startAt, addMinutes(startAt, duration));
}
