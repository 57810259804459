import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import {
  GoogleErrorResponseSchema,
  GoogleSuccessResponseSchema,
  getTypedGoogleResponse,
} from '../features/google';
import { GoogleAuthPage } from '../pages/google/ui/GoogleAuthPage';
import { LocalStoreKeys } from '../shared/model/localStoreKeys';

const googleAuthSearchSchema = z.union([
  GoogleSuccessResponseSchema,
  GoogleErrorResponseSchema,
]);

export const Route = createFileRoute('/google-auth-callback')({
  component: Page,
  validateSearch: zodSearchValidator(googleAuthSearchSchema),
});

function Page() {
  const search = Route.useSearch();

  const token = localStorage.getItem(LocalStoreKeys.GOOGLE_AUTH_TOKEN) ?? '';

  return (
    <GoogleAuthPage
      initialGoogleResponse={getTypedGoogleResponse(search)}
      token={token}
    />
  );
}
