import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';
import { useAnimate } from 'framer-motion';

import { Button } from '@tg-web/components';
import { getWebApp } from '@tg-web/utils';

import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { Dots } from '../../../shared/ui/Dots';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { useTheme } from '../../../shared/ui/ThemeProvider';
import welcome1dark from './images/welcome1dark.webp';
import welcome1dark2x from './images/welcome1dark@2x.webp';
import welcome1light from './images/welcome1light.webp';
import welcome1light2x from './images/welcome1light@2x.webp';
import welcome2dark from './images/welcome2dark.webp';
import welcome2dark2x from './images/welcome2dark@2x.webp';
import welcome2light from './images/welcome2light.webp';
import welcome2light2x from './images/welcome2light@2x.webp';
import welcome3dark from './images/welcome3dark.webp';
import welcome3dark2x from './images/welcome3dark@2x.webp';
import welcome3light from './images/welcome3light.webp';
import welcome3light2x from './images/welcome3light@2x.webp';

const images = {
  dark: [
    { src: welcome1dark, srcSet: welcome1dark2x },
    { src: welcome2dark, srcSet: welcome2dark2x },
    { src: welcome3dark, srcSet: welcome3dark2x },
  ],
  light: [
    { src: welcome1light, srcSet: welcome1light2x },
    { src: welcome2light, srcSet: welcome2light2x },
    { src: welcome3light, srcSet: welcome3light2x },
  ],
};

const texts = [
  {
    button: 'welcome.firstStep.button',
    description: 'all:welcome.firstStep.description',
    title: 'all:welcome.firstStep.title',
  },
  {
    button: 'welcome.secondStep.button',
    description: 'all:welcome.secondStep.description',
    title: 'all:welcome.secondStep.title',
  },
  {
    button: 'welcome.thirdStep.button',
    description: 'all:welcome.thirdStep.description',
    title: 'all:welcome.thirdStep.title',
  },
];

export function WelcomePage() {
  const [scope, animate] = useAnimate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { appTheme } = useTheme();
  const [page, setPage] = useState(0);
  const [feedSearch, setFeedSearch] = useState();
  const image = images[appTheme][page];
  const text = texts[page];

  const fadeIn = (onComplete?: VoidFunction) => {
    animate(scope.current, { opacity: 1 }, { duration: 0.3, onComplete });
  };

  const fadeOut = (onComplete?: VoidFunction) => {
    animate(scope.current, { opacity: 0 }, { duration: 0.3, onComplete });
  };

  const handleClick = () => {
    if (page < 2) {
      fadeOut(() => {
        setPage(page + 1);
        fadeIn();
      });
    } else {
      fadeOut(() => {
        // TODO: change redirect route after google onboarding is ready
        // navigate({ to: '/google-onboarding' });

        navigate(
          feedSearch
            ? { search: feedSearch, to: '/feed' }
            : { to: '/feed-welcome' },
        );
      });
    }
  };

  useEffect(() => {
    fadeIn();
    // preload images for next steps to avoid blinking
    images[appTheme].forEach((image) => {
      const img = new Image();
      img.src = image.src;
      img.srcset = image.srcSet;
    });
  }, []);

  useEffect(() => {
    getWebApp().CloudStorage.removeItem(CloudStorageKeys.SHOW_WELCOME);

    getCloudStorageItem(CloudStorageKeys.FEED_SEARCH).then((value) => {
      setFeedSearch(value ? JSON.parse(value) : {});
    });
  }, []);

  return (
    <PageWrapper className="flex w-full flex-col justify-between px-5 pb-6 pt-12">
      <div className="flex flex-col items-center opacity-0" ref={scope}>
        <img
          alt={t(text.title)}
          src={image.src}
          srcSet={`${image.srcSet} 2x`}
        />
        <p className="typo-header-small text-tg-accent-text mt-14">
          {t(text.title)}
        </p>
        <p className="typo-header-small max-w-72 text-center">
          {t(text.description)}
        </p>
      </div>
      <div className="flex w-full flex-col gap-4">
        <Dots activeIndex={page} count={3} />
        <Button onClick={handleClick}>{t(text.button)}</Button>
      </div>
    </PageWrapper>
  );
}
