import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { useBackNavigation } from '@tg-web/utils';

import { StackForm, StackFormProps } from '../../../features/stacks';
import { useCreateStack } from '../../../shared/api';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function NewStackPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createStackReq = useCreateStack();
  const handleSubmit: StackFormProps['onSubmit'] = (values) => {
    createStackReq.mutate({
      body: { stack: { default_creator: values.allow, title: values.title } },
    });
  };

  useEffect(() => {
    if (createStackReq.isSuccess) {
      navigate({
        params: { stackId: createStackReq.data.id },
        to: '/stacks/$stackId/view',
      });
    }
  }, [createStackReq.isSuccess]);

  useBackNavigation(() => {
    navigate({ to: '/stacks' });
  });

  return (
    <PageWrapper className="flex flex-col px-5 py-6">
      <span className="typo-header-big">{t('all:stacks.new.title')}</span>
      <StackForm
        buttonTitle={t('all:stacks.new.button')}
        isPending={createStackReq.isPending}
        onSubmit={handleSubmit}
      />
    </PageWrapper>
  );
}
