import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@tg-web/components';
import { useCallbackRef } from '@tg-web/utils';

import { User, useRemoveAttendeeFromEvent } from '../../../shared/api';
import { AttendStatus } from '../../events';
import { ParticipantCard } from './ParticipantCard';

export type DeleteParticipantModalProps = {
  eventId: string;
  onClose?: () => void;
  onSuccess?: () => void;
  open?: boolean;
  status?: AttendStatus;
  user?: User;
};

export function DeleteParticipantModal({
  eventId,
  onClose,
  onSuccess,
  open = false,
  status,
  user,
}: DeleteParticipantModalProps) {
  const { t } = useTranslation();
  const removeAttendeeFromEvent = useRemoveAttendeeFromEvent();
  const onSuccessCallbackRef = useCallbackRef(onSuccess);

  useEffect(() => {
    if (removeAttendeeFromEvent.isSuccess) {
      onSuccessCallbackRef?.();
    }
  }, [onSuccessCallbackRef, removeAttendeeFromEvent.isSuccess]);

  return (
    <Sheet onOpenChange={onClose} open={open}>
      <SheetContent
        className="w-full border-none"
        onPointerDownOutside={onClose}
        side="bottom"
      >
        <SheetHeader>
          <SheetTitle>{t('all:event.participants.remove.title')}</SheetTitle>
        </SheetHeader>
        {status !== undefined && user !== undefined && (
          <div className="mt-6">
            <ParticipantCard
              className="border-none"
              status={status}
              user={user}
            />
            <Button
              onClick={() => {
                removeAttendeeFromEvent.mutate({
                  body: { user_id: user.id },
                  pathParams: { id: eventId },
                });
              }}
              className="mt-8"
              type="button"
              variant="primary"
            >
              {t('all:common.buttons.remove')}
            </Button>
            <Button
              className="mt-3"
              onClick={onClose}
              type="button"
              variant="secondary"
            >
              {t('all:common.buttons.cancel')}
            </Button>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}
