import React from 'react';

import { IconProps } from './types';

export const RepeatIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17 2.75C16.5833 4.59896 15.7083 6.29688 14.2396 7.35938C13.8594 7.63542 13.9479 8.20313 14.3958 8.32813C16.0156 8.76563 17.401 10.0156 18.4583 11.7083"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M14.0052 7.85939C19.5989 6.34897 23.7916 7.48439 26 9.00001C27.2239 11.4844 27.5 19 26 23C24.5 24 23 24 23 24"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M15 29.25C15.4166 27.401 16.2916 25.7031 17.7604 24.6406C18.1406 24.3646 18.052 23.7969 17.6041 23.6719C15.9843 23.2344 14.5989 21.9844 13.5416 20.2917"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M17.9948 24.1406C12.401 25.651 8.20832 24.5156 5.99998 23C4.77603 20.5156 4.49998 13 5.99998 9C7.3229 8 8.99998 8 8.99998 8"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
