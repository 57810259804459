import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import ICU from 'i18next-icu';

i18n
  .use(ICU)
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      cache: 'no-cache',
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: { v: '1' },
    },
    debug: true,
    defaultNS: 'all',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
