import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';

import { SpinnerIcon } from '@tg-web/icons';
import { cn } from '@tg-web/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap typo-text-bold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    defaultVariants: {
      size: 'big',
      variant: 'primary',
    },
    variants: {
      size: {
        big: 'rounded-2xl h-14 w-full p-3.5',
        icon: 'h-9 w-9',
        small: 'rounded-xl h-10 px-5 py-3.5',
        tiny: 'typo-text gap-2 pl-2.5 pr-4 rounded-xl h-10',
      },
      variant: {
        ghost: '',
        hover:
          'bg-tg-button text-tg-button-text shadow-button-hover active:bg-c-button-active',
        primary: 'bg-tg-button text-tg-button-text active:bg-c-button-active',
        secondary: 'bg-c-secondary text-tg-text active:bg-c-secondary-active',
        tertiary: 'text-tg-text border border-tg-section-separator',
        white: 'text-tg-text bg-tg-section-bg',
      },
    },
  },
);

const buttonSpinnerVariants = cva('animate-spin', {
  defaultVariants: {
    size: 'big',
  },
  variants: {
    size: {
      big: 'h-8 w-8',
      icon: 'h-6 w-6',
      small: 'h-6 w-6',
    },
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      disabled,
      isLoading,
      size,
      variant,
      ...props
    },
    ref,
  ) => {
    const Comp = !isLoading && asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(buttonVariants({ className, size, variant }))}
        disabled={isLoading || disabled}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <SpinnerIcon className={buttonSpinnerVariants({ size })} />
        ) : (
          children
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
