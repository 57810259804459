import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from '@tanstack/react-router';
import { EmojiClickData } from 'emoji-picker-react';

import {
  Button,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@tg-web/components';
import { EditIcon, ShareSecondaryIcon, SpinnerIcon } from '@tg-web/icons';
import { cn, useBackNavigation, useBoolean } from '@tg-web/utils';

import {
  PassedStackEvents,
  ShareStackModal,
  StackDescription,
  StackNotFound,
  StackSubscriptionButton,
  UpcomingStackEvents,
} from '../../../features/stacks';
import {
  isUserSubscribedToStack,
  useStackSubscription,
} from '../../../features/stacks/lib';
import {
  ListStackEventsQueryParams,
  Stack,
  useGetStack,
  useListStackEvents,
  useUpdateStack,
} from '../../../shared/api';
import { useMainButton } from '../../../shared/lib/useMainButton';
import { ConfiguredEmojiPicker } from '../../../shared/ui/ConfiguredEmojiPicker';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export interface ViewStackPageProps {
  stackId: string;
}

type EventsStatus = NonNullable<ListStackEventsQueryParams['status']>[number];

export function ViewStackPage({ stackId }: ViewStackPageProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showMainButton, setShowMainButton] = useBoolean(true);
  const [showEmojiPicker, setShowEmojiPicker] = useBoolean(false);
  const [eventsStatus, setEventsStatus] = useState<EventsStatus>('upcoming');

  const stackReq = useGetStack({
    pathParams: { id: stackId },
  });

  const updateStackReq = useUpdateStack({
    onSuccess: (data) => {
      queryClient.setQueryData<Stack>(['stacks', stackId], data);
    },
  });
  const handleEmojiChange = (emojiData: EmojiClickData) => {
    updateStackReq.mutate({
      body: {
        stack: {
          emoji: emojiData.emoji,
        },
      },
      pathParams: { id: stackId },
    });
    setShowEmojiPicker.off();
  };

  const stackEventsReq = useListStackEvents({
    pathParams: { stackId },
    queryParams: { status: [eventsStatus] },
  });
  const { subscribe, subscriptionReq } = useStackSubscription({
    onSuccess: () => stackReq.refetch(),
  });

  const isSubscribed = stackReq.data
    ? isUserSubscribedToStack(stackReq.data.role)
    : false;
  const isOwner = stackReq.data ? stackReq.data.role === 'owner' : false;
  const canCreate =
    isOwner ||
    (isSubscribed && stackReq.isSuccess && stackReq.data.default_creator);
  const handleMainButtonClick = () => {
    if (!isOwner && !isSubscribed) {
      subscribe(stackId);
    } else {
      navigate({ search: { stackId }, to: '/events/new' });
    }
  };

  useMainButton({
    isHidden:
      !showMainButton ||
      !stackReq.isSuccess ||
      (isSubscribed && !stackReq.data.default_creator),
    isLoading: subscriptionReq.isPending,
    onClick: handleMainButtonClick,
    text:
      !isOwner && !isSubscribed
        ? t('all:stacks.view.buttons.main_subscribe')
        : t('all:stacks.view.buttons.add_event'),
  });
  useBackNavigation(() => {
    navigate({ to: '/stacks' });
  }, showMainButton);

  if (stackReq.isPending) {
    return <GlobalLoading />;
  }

  if (stackReq.isError) {
    if (stackReq.error?.status === 404) {
      return <StackNotFound />;
    }
    throw new Error('Unexpected API error');
  }

  const stack = stackReq.data;

  return (
    <PageWrapper className="flex flex-col">
      <div className="flex flex-col items-center gap-6 px-5 py-4">
        <Popover
          onOpenChange={setShowEmojiPicker.toggle}
          open={showEmojiPicker}
        >
          {updateStackReq.isPending ? (
            <div className="flex h-16 w-16 items-center justify-center">
              <SpinnerIcon className="text-tg-accent-text h-12 w-12 animate-spin" />
            </div>
          ) : (
            <PopoverAnchor>
              <span
                className={cn(
                  'text-[64px] leading-none',
                  isOwner ? 'cursor-pointer' : 'cursor-default',
                )}
                onClick={isOwner ? setShowEmojiPicker.toggle : undefined}
              >
                {stack.emoji}
              </span>
            </PopoverAnchor>
          )}
          <PopoverContent className="w-full border-none bg-transparent p-0 shadow-none">
            <ConfiguredEmojiPicker onEmojiClick={handleEmojiChange} open />
          </PopoverContent>
        </Popover>
        <div className="flex flex-col items-center gap-2">
          <span className="typo-header-big text-center">{stack.title}</span>
          <span className="typo-text text-tg-hint">
            {t('all:stacks.view.subscribers', {
              count: stack.subscriptions_count,
            })}
          </span>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="flex gap-2">
            {isOwner ? (
              <Button size="tiny" variant="white" asChild>
                <Link params={{ stackId }} to="/stacks/$stackId/edit">
                  <EditIcon height={24} width={24} />
                  {t('all:stacks.view.buttons.edit')}
                </Link>
              </Button>
            ) : (
              <StackSubscriptionButton
                onSuccess={() => stackReq.refetch()}
                stack={stack}
              />
            )}
            <ShareStackModal
              trigger={
                <Button
                  size="tiny"
                  variant={isOwner || isSubscribed ? 'primary' : 'white'}
                >
                  <ShareSecondaryIcon />
                  {t('all:common.buttons.share')}
                </Button>
              }
              onOpenChange={() => setShowMainButton.toggle()}
              stackId={stackId}
            />
          </div>
          <StackDescription stack={stack} />
        </div>
      </div>
      <Tabs
        className="flex grow flex-col"
        defaultValue={eventsStatus}
        onValueChange={(value) => setEventsStatus(value as EventsStatus)}
      >
        <TabsList>
          <TabsTrigger value="upcoming">
            {t('all:stacks.view.upcoming.title')}
          </TabsTrigger>
          <TabsTrigger value="passed">
            {t('all:stacks.view.past.title')}
          </TabsTrigger>
        </TabsList>
        <TabsContent className="grow" value="upcoming">
          <UpcomingStackEvents
            canCreate={canCreate}
            events={stackEventsReq.data?.stack_events ?? []}
            isLoading={stackEventsReq.isFetching}
            stackId={stackId}
          />
        </TabsContent>
        <TabsContent className="grow" value="passed">
          <PassedStackEvents
            events={stackEventsReq.data?.stack_events ?? []}
            isLoading={stackEventsReq.isFetching}
          />
        </TabsContent>
      </Tabs>
    </PageWrapper>
  );
}
