import React from 'react';

import { IconProps } from './types';

export const ShareSecondaryIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.0542 0.730526C11.6479 0.726619 11.2417 0.921932 11.0386 1.30865C10.562 2.211 9.81982 2.82818 8.72998 3.24225C8.34326 3.39068 8.15186 3.82428 8.30029 4.211C8.44482 4.59771 8.88232 4.79303 9.26904 4.64459C10.0425 4.34771 10.6948 3.8985 11.2651 3.36725V13.0235C11.2612 13.293 11.4019 13.543 11.6362 13.6797C11.8706 13.8165 12.1597 13.8165 12.394 13.6797C12.6245 13.543 12.769 13.293 12.7651 13.0235V3.37506C13.3003 3.88678 13.937 4.32818 14.7222 4.64068C14.9722 4.75006 15.2651 4.7149 15.4839 4.55084C15.7026 4.38287 15.8081 4.10943 15.7651 3.836C15.7222 3.56646 15.5347 3.3399 15.2769 3.24615C14.1323 2.79303 13.4487 2.16412 13.0542 1.336C12.8628 0.933651 12.4604 0.734432 12.0542 0.730526ZM9.02295 6.711C8.96826 6.70709 8.91748 6.7149 8.86279 6.72662C8.07764 6.86334 7.29639 7.05084 6.51514 7.29303C5.80811 7.50787 5.25732 8.05865 5.04248 8.76568C3.92139 12.4102 3.92139 16.0899 5.04248 19.7344C5.25732 20.4415 5.80811 20.9922 6.51514 21.2071C10.1597 22.3282 13.8394 22.3282 17.4839 21.2071C18.1909 20.9922 18.7417 20.4415 18.9565 19.7344C20.0776 16.0899 20.0776 12.4102 18.9565 8.76568C18.7417 8.05865 18.1909 7.50787 17.4839 7.29303C16.7026 7.05084 15.9136 6.85943 15.1284 6.72271C15.0776 6.7149 15.0308 6.711 14.98 6.711C14.5894 6.7149 14.2651 7.01568 14.2378 7.40631C14.2105 7.79693 14.4839 8.14068 14.8706 8.20318C15.5933 8.32818 16.3198 8.50396 17.0464 8.72662C17.2651 8.79303 17.4565 8.98443 17.5229 9.20709C18.562 12.5821 18.562 15.918 17.5229 19.293C17.4565 19.5157 17.2651 19.7071 17.0425 19.7735C13.6675 20.8126 10.3315 20.8126 6.95264 19.7735C6.73389 19.7071 6.54248 19.5157 6.47607 19.293C5.43701 15.918 5.43701 12.5821 6.47607 9.20709C6.47607 9.20318 6.47607 9.20318 6.47607 9.20318C6.54248 8.98443 6.73389 8.79303 6.95264 8.72662C7.6792 8.50396 8.40185 8.32818 9.1206 8.20318C9.51123 8.14459 9.79248 7.80084 9.76514 7.41021C9.73779 7.01568 9.41748 6.711 9.02295 6.711Z"
          fill={color}
        />
      </svg>
    );
  },
);
