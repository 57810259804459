import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { CheckmarkIcon, QuestionMarkIcon } from '@tg-web/icons';
import { cn, useBackNavigation, useDisclosure } from '@tg-web/utils';

import { AttendStatus, EventInfoCard } from '../../../features/events';
import { getFeedSearch } from '../../../features/feed';
import { AddToGoogleButton } from '../../../features/google';
import {
  NotificationDuration,
  NotificationModal,
} from '../../../features/notifications';
import { RecurrentType } from '../../../features/recurrency';
import { useNotifyEvent, useShowEvent } from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export type AttendEventPageProps = {
  eventId: string;
};
export function AttendEventPage({ eventId }: AttendEventPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notificationModal = useDisclosure();

  const event = useShowEvent({
    pathParams: { id: eventId },
  });

  const notifyEvent = useNotifyEvent();

  useBackNavigation(() => {
    navigate({
      params: { eventId },
      to: '/events/$eventId/view',
    });
  });

  const handleNotificationClose = () => {
    navigate({ search: getFeedSearch(eventData), to: '/feed' });
  };

  const handleNotificationSave = (value: NotificationDuration) => {
    notifyEvent.mutate({
      body: {
        dependent_notify: Number(
          eventData.attributes.recurrent_type !== RecurrentType.noRecurrency,
        ),
        notify_before: value,
      },
      pathParams: { id: eventId },
    });
  };

  useEffect(() => {
    if (notifyEvent.isSuccess) {
      handleNotificationClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyEvent.isSuccess]);

  if (event.isFetching || event.isPending) {
    return <GlobalLoading />;
  }

  if (event.isError) {
    throw new Error('Unexpected API error');
  }

  const eventData = event.data.event.data;
  const isAttend = eventData.attributes.attend_status === AttendStatus.attend;

  return (
    <PageWrapper className="flex flex-col justify-between gap-4 px-5 py-6">
      <div className="flex flex-col items-center">
        <div
          className={cn(
            'mt-4 flex h-[64px] w-[64px] items-center justify-center rounded-full',
            isAttend && 'bg-tg-button text-tg-button-text',
            !isAttend && 'border-tg-section-separator border',
          )}
        >
          {isAttend ? <CheckmarkIcon /> : <QuestionMarkIcon />}
        </div>
        <h1 className="typo-header-biggest mt-5 text-center">
          {t(
            isAttend
              ? 'all:event_attention_changed.title.attend'
              : 'all:event_attention_changed.title.maybe',
          )}
        </h1>
        <p className="typo-text text-tg-subtitle-text mt-3">
          {t('all:event_attention_changed.subtitle')}
        </p>
        <EventInfoCard className="mt-10" event={eventData} isBorderless />
        <AddToGoogleButton className="mt-3" event={eventData} />
      </div>
      <div className="flex flex-col items-center">
        <Button
          className="mt-12"
          onClick={notificationModal.onOpen}
          variant="primary"
        >
          {t('all:event_attention_changed.buttons.continue')}
        </Button>
      </div>
      <NotificationModal
        cancelButtonText={t('all:common.buttons.skip')}
        initial={eventData.attributes.notify_before}
        onClose={handleNotificationClose}
        onSave={handleNotificationSave}
        open={notificationModal.open}
        title={t('all:event_attention_changed.reminder')}
      />
    </PageWrapper>
  );
}
