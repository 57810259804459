import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabsProps } from '@radix-ui/react-tabs';
import { useNavigate } from '@tanstack/react-router';
import { motion } from 'framer-motion';

import { Tabs, TabsList, TabsTrigger } from '@tg-web/components';
import { getWebApp, useBoolean } from '@tg-web/utils';

import { NewLabel } from '../../../features/stacks/ui/NewLabel';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { useSafeAreaInset } from '../../../shared/lib/useSafeAreaInset';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';

const tabs = [
  { label: 'all:stacks.main_tabs.feed', value: '/feed' },
  { label: 'all:stacks.main_tabs.stacks', value: '/stacks' },
] as const;

export interface MainTabsProps {
  initialRoute: string;
}

export function MainTabs({ initialRoute }: MainTabsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(initialRoute);
  const [showNewLabel, setShowNewLabel] = useBoolean(false);
  const safeAreaInset = useSafeAreaInset();

  const handleTabChange: TabsProps['onValueChange'] = (tab) => {
    setCurrentTab(tab);
    navigate({ to: tab });
    if (showNewLabel) {
      setShowNewLabel.off();
      getWebApp().CloudStorage.setItem(
        CloudStorageKeys.HIDE_STACK_NEW_LABEL,
        'true',
      );
    }
  };

  useEffect(() => {
    getCloudStorageItem(CloudStorageKeys.HIDE_STACK_NEW_LABEL).then((value) => {
      if (!value) {
        setShowNewLabel.on();
      }
    });
  }, []);

  return (
    <div
      style={{
        paddingBottom: safeAreaInset.bottom > 0 ? safeAreaInset.bottom : 8,
      }}
      className="bg-tg-bg shadow-main-tabs fixed bottom-0 z-10 min-h-[50px] w-full px-5 pt-2"
    >
      <Tabs onValueChange={handleTabChange} value={currentTab}>
        <TabsList className="bg-tg-secondary-bg text-tg-text relative flex h-10 w-full rounded-xl p-0.5">
          {tabs.map((tab) => (
            <TabsTrigger
              className="typo-text-bold data-[state=active]:text-tg-text relative h-full basis-1/2 justify-center border-none py-2 data-[state=active]:border-none data-[state=active]:bg-transparent data-[state=active]:shadow-none"
              key={tab.value}
              value={tab.value}
            >
              {currentTab === tab.value && (
                <motion.span
                  className="bg-tg-section-bg absolute inset-0 rounded-[10px]"
                  layoutId="bubble"
                  transition={{ bounce: 0.2, duration: 0.6, type: 'spring' }}
                />
              )}
              <span className="absolute">
                {t(tab.label)}{' '}
                {tab.value === '/stacks' && showNewLabel && <NewLabel />}
              </span>
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
    </div>
  );
}
