import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from '@radix-ui/react-dialog';
import { useNavigate } from '@tanstack/react-router';

import { Button, Sheet, SheetContent, SheetTrigger } from '@tg-web/components';
import { AttentionIcon } from '@tg-web/icons';

import { useDeleteStack } from '../../../shared/api';

export interface DeleteStackModalProps extends DialogProps {
  stackId: string;
}

export function DeleteStackModal({
  children,
  stackId,
  ...sheetProps
}: DeleteStackModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteStackReq = useDeleteStack();

  const handleDeleteClick = () => {
    deleteStackReq.mutate({ pathParams: { id: stackId } });
  };

  useEffect(() => {
    if (deleteStackReq.isSuccess) {
      navigate({
        to: '/stacks',
      });
    }
  }, [deleteStackReq.isSuccess]);

  return (
    <Sheet {...sheetProps}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent
        className="bg-tg-section-bg flex flex-col gap-3 px-5 py-10"
        side="bottom"
      >
        <span className="typo-header-big text-center">
          {t('all:stacks.edit.delete_dialog.title')}
        </span>
        <div className="flex gap-3 py-2">
          <AttentionIcon className="text-tg-text shrink-0" />
          <span>{t('all:stacks.edit.delete_dialog.description')}</span>
        </div>
        <div className="flex flex-col gap-3 pt-4">
          <Button
            className="bg-tg-destructive-text"
            disabled={deleteStackReq.isPending}
            onClick={handleDeleteClick}
            variant="primary"
          >
            {t('all:stacks.edit.delete_dialog.delete')}
          </Button>
          <SheetTrigger asChild>
            <Button variant="secondary">
              {t('all:stacks.edit.delete_dialog.cancel')}
            </Button>
          </SheetTrigger>
        </div>
      </SheetContent>
    </Sheet>
  );
}
