import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { isValid, parse } from 'date-fns';
import { z } from 'zod';

import { Feed } from '../../../pages/feed/ui/Feed';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { DATE_SEARCH_PARAM_FORMAT } from '../../../shared/model/search';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';

const feedSearchSchema = z.object({
  date: z.string().default(''),
});

export const Route = createFileRoute('/_authorized/_withTabs/feed')({
  beforeLoad: async () => {
    const value = await getCloudStorageItem(CloudStorageKeys.SHOW_WELCOME);
    if (value) {
      throw redirect({
        replace: true,
        to: '/welcome',
      });
    }
  },
  component: Page,
  pendingComponent: GlobalLoading,
  validateSearch: zodSearchValidator(feedSearchSchema),
});

function Page() {
  const { date } = Route.useSearch();
  const parsedDate = parse(date, DATE_SEARCH_PARAM_FORMAT, new Date());

  return <Feed initialDate={isValid(parsedDate) ? parsedDate : undefined} />;
}
