import { useRef } from 'react';

import { useDateSegment } from '@react-aria/datepicker';
import {
  DateSegment as IDateSegment,
  TimeFieldState,
} from '@react-stately/datepicker';

import { cn } from '@tg-web/utils';

export function DateSegment({
  segment,
  state,
}: {
  segment: IDateSegment;
  state: TimeFieldState;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      className={cn(
        'typo-text group box-content flex flex-col items-center justify-center rounded-sm px-0.5 text-right tabular-nums outline-none',
        'focus:text-tg-accent-text',
      )}
      style={{
        ...segmentProps.style,
        minWidth:
          segment.maxValue == null
            ? undefined
            : `${String(segment.maxValue).length}ch`,
      }}
      ref={ref}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        style={{
          height: segment.isPlaceholder ? '' : 0,
          pointerEvents: 'none',
          visibility: segment.isPlaceholder ? undefined : 'hidden',
        }}
        aria-hidden="true"
        className="block w-full text-center italic"
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
}
