import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { captureException } from '@sentry/react';
import { ErrorComponentProps } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { CircleIconWrapper, CowSkullIcon } from '@tg-web/icons';

import { PageWrapper } from './PageWrapper';

export function ErrorPage({ error, reset }: ErrorComponentProps) {
  const { t } = useTranslation();

  useEffect(() => {
    captureException(error, {});
  }, [error]);

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="grow" />
      <div className="flex shrink-0 flex-col items-center">
        <CircleIconWrapper className="mb-5">
          <CowSkullIcon />
        </CircleIconWrapper>
        <h1 className="typo-header-biggest mb-3">
          {t('all:error_page.title')}
        </h1>
        <p className="typo-text text-tg-subtitle-text mb-8 max-w-[280px] text-center">
          {t('all:error_page.text')}
        </p>
        <Button onClick={reset} size="small" variant="primary">
          {t('all:common.buttons.try_again')}
        </Button>
      </div>
      <div className="grow" />
      <div className="grow" />
    </PageWrapper>
  );
}
