import { PropsWithChildren, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { useUserProfileConfigured } from '../../../shared/api';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';

export function GoogleConnectionHandler({ children }: PropsWithChildren) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userProfile = useUserProfileConfigured();

  useEffect(() => {
    if (
      userProfile.isSuccess &&
      userProfile.data.user.data.attributes.has_google_calendar
    ) {
      getCloudStorageItem(
        CloudStorageKeys.GOOGLE_CALENDAR_ONBOARDING_PASSED,
      ).then((value) => {
        if (!value) {
          queryClient
            .invalidateQueries({
              queryKey: ['google_accounts'],
            })
            .then(() => {
              navigate({ to: '/google-calendar-onboarding' });
            });
        }
      });
    }
  }, [
    navigate,
    queryClient,
    userProfile.data?.user.data.attributes.has_google_calendar,
    userProfile.isSuccess,
  ]);

  return children;
}
