import { ReactNode } from 'react';

import { Button, ButtonProps } from '@tg-web/components';
import { cn } from '@tg-web/utils';

export type EventActionButtonProps = {
  icon: ReactNode;
  iconClassName?: string;
  text: string;
} & Omit<ButtonProps, 'children'>;

export function EventActionButton({
  className,
  icon,
  iconClassName,
  text,
  ...restProps
}: EventActionButtonProps) {
  return (
    <Button
      className="flex h-auto w-auto flex-col gap-2 p-0"
      variant="ghost"
      {...restProps}
    >
      <div
        className={cn(
          'bg-tg-section-bg h-[64px] w-[64px] rounded-full p-4',
          iconClassName,
        )}
      >
        {icon}
      </div>
      <span className="typo-text">{text}</span>
    </Button>
  );
}
