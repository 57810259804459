import React from 'react';

import { IconProps } from './types';

export const DateArrowIcon = React.forwardRef<
  SVGSVGElement,
  { secondaryColor?: string } & IconProps
>(({ color = 'currentColor', secondaryColor, ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="23"
      viewBox="0 0 15 23"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8.5 1.5C8.5 0.947715 8.05228 0.5 7.5 0.5C6.94772 0.5 6.5 0.947715 6.5 1.5H8.5ZM6.79289 22.2071C7.18342 22.5976 7.81658 22.5976 8.20711 22.2071L14.5711 15.8431C14.9616 15.4526 14.9616 14.8195 14.5711 14.4289C14.1805 14.0384 13.5474 14.0384 13.1569 14.4289L7.5 20.0858L1.84315 14.4289C1.45262 14.0384 0.819457 14.0384 0.428932 14.4289C0.0384078 14.8195 0.0384078 15.4526 0.428932 15.8431L6.79289 22.2071ZM6.5 1.5V21.5H8.5V1.5H6.5Z"
        fill={color}
      />
    </svg>
  );
});
