import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  addDays,
  differenceInMinutes,
  eachDayOfInterval,
  isSameMinute,
  startOfDay,
} from 'date-fns';
import { groupBy } from 'ramda';

import { Event } from '../../../shared/api';
import { fixFullDayEventStart, getEventInterval } from '../../events';
import { ExtendedFeedEvent } from '../model';

export function useEventsGroupedByDay(events: ExtendedFeedEvent[] | undefined) {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!events) {
      return {};
    }

    const oneDayEvents = events
      .map((it) => {
        const eventData = fixFullDayEventStart(it);

        const eventInterval = getEventInterval(eventData);
        let days = eachDayOfInterval(eventInterval);

        // handle case of end date at 00:00 of next day
        if (
          days.length > 1 &&
          isSameMinute(eventInterval.end, startOfDay(eventInterval.end))
        ) {
          days = days.slice(0, -1);
        }

        if (days.length > 1) {
          const intervals = days.map((day, index) => {
            // first day
            if (index === 0) {
              return {
                end: startOfDay(addDays(eventInterval.start, 1)),
                start: eventInterval.start,
              };
            }
            // last day
            if (index === days.length - 1) {
              return {
                end: eventInterval.end,
                start: startOfDay(day),
              };
            }

            return { end: startOfDay(addDays(day, 1)), start: startOfDay(day) };
          });

          return intervals.map<ExtendedFeedEvent>(({ end, start }, index) => {
            return {
              ...it,
              attributes: {
                ...it.attributes,
                duration: differenceInMinutes(end, start),
                start_at: start.toISOString(),
              },
              titlePrefix: t('all:event.multiday.prefix', { count: index + 1 }),
            };
          });
        }

        return eventData;
      })
      .flat();

    return groupBy<Event, string>((it) =>
      startOfDay(it.attributes.start_at).toISOString(),
    )(oneDayEvents);
  }, [events, t]);
}
