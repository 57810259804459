import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { CreatedEventPage } from '../../../../pages/events/ui/CreatedEventPage';

const createdPageSearchSchema = z.object({
  stackId: z.string().optional(),
});

export const Route = createFileRoute('/_authorized/events/$eventId/created')({
  component: Page,
  validateSearch: zodSearchValidator(createdPageSearchSchema),
});

function Page() {
  const { eventId } = Route.useParams();
  const { stackId } = Route.useSearch();

  return <CreatedEventPage eventId={eventId} stackId={stackId} />;
}
