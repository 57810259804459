import React from 'react';

import { IconProps } from './types';

export const HeartIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.46548 3.57617C5.6196 3.57617 3.21306 5.49461 2.47427 8.11279C2.07364 9.53157 2.19273 11.055 2.80678 12.396C3.68208 14.5797 5.2479 16.5474 7.00356 17.999C8.76507 19.4555 10.7047 20.4248 12.4923 20.4248C14.2879 20.4248 16.2419 19.4478 18.0119 17.9814C19.7818 16.5151 21.3576 14.5286 22.2145 12.3242C22.5111 11.5624 22.7424 10.6898 22.758 9.78418C22.7656 9.35455 22.72 8.91856 22.6071 8.48779C22.0005 5.68043 19.5017 3.57617 16.5221 3.57617C14.9573 3.57617 13.5877 4.23749 12.4938 5.19336C11.4002 4.23777 10.0305 3.57617 8.46548 3.57617ZM8.46548 5.07617C9.84563 5.07617 11.0751 5.66734 11.9416 6.6084C12.0118 6.68484 12.0972 6.74586 12.1922 6.7876C12.2873 6.82934 12.39 6.85089 12.4938 6.85089C12.5976 6.85089 12.7003 6.82934 12.7954 6.7876C12.8904 6.74586 12.9758 6.68484 13.046 6.6084C13.9133 5.66646 15.1417 5.07617 16.5221 5.07617C18.8019 5.07617 20.6898 6.67972 21.1452 8.81445C21.1482 8.82724 21.1517 8.83994 21.1554 8.85254C21.2314 9.13571 21.2636 9.43922 21.258 9.75781V9.75928C21.2466 10.4212 21.0664 11.1401 20.817 11.7808C20.0772 13.6842 18.6461 15.5078 17.0553 16.8257C15.4646 18.1435 13.7035 18.9248 12.4923 18.9248C11.2867 18.9248 9.54304 18.1521 7.96011 16.8433C6.37717 15.5344 4.94652 13.7199 4.1896 11.8203C4.18455 11.808 4.17918 11.7958 4.17348 11.7837C3.70471 10.7674 3.61279 9.59782 3.91714 8.52002C4.47735 6.53471 6.29235 5.07617 8.46548 5.07617Z"
          fill={color}
        />
      </svg>
    );
  },
);
