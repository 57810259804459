import { useEffect } from 'react';

import { Event, useNotifyEvent } from '../../../shared/api';
import { NotificationModalProps } from '../../notifications';
import { RecurrentType } from '../../recurrency';

export interface UseEventNotificationParams {
  event: Event;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
}

export function useEventNotification({
  event,
  onClose,
  onSuccess,
}: UseEventNotificationParams) {
  const notifyEvent = useNotifyEvent();

  const isRecurringEvent =
    event.attributes.recurrent_type !== RecurrentType.noRecurrency;

  const handleNotificationSave: NotificationModalProps['onSave'] = (
    value,
    dependent_notify,
  ) => {
    notifyEvent.mutate({
      body: { dependent_notify, notify_before: value },
      pathParams: { id: event.id },
    });
  };

  useEffect(() => {
    if (notifyEvent.isSuccess) {
      onSuccess?.();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyEvent.isSuccess]);
  return { handleNotificationSave, isRecurringEvent };
}
