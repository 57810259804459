import { createFileRoute } from '@tanstack/react-router';

import { StacksPage } from '../../../pages/stacks/ui/StacksPage';

export const Route = createFileRoute('/_authorized/_withTabs/stacks')({
  component: Page,
});

function Page() {
  return <StacksPage />;
}
