/* eslint-disable perfectionist/sort-imports */
import { jitsuOptions, queryClient, router } from './instruments'; // this import should be first
import React, { StrictMode, Suspense } from 'react';

import { JitsuProvider } from '@jitsu/jitsu-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import ReactDOM from 'react-dom/client';

import { Toaster, TooltipProvider } from '@tg-web/components';
import { PromptProvider } from '../features/prompt';
import { ThemeProvider } from '../shared/ui/ThemeProvider';

import '../../i18n';

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ThemeProvider storageKey="vite-ui-theme">
        <Suspense fallback="...loading">
          <JitsuProvider options={jitsuOptions}>
            <QueryClientProvider client={queryClient}>
              <PromptProvider>
                <TooltipProvider>
                  <RouterProvider router={router} />
                  <Toaster />
                </TooltipProvider>
              </PromptProvider>
            </QueryClientProvider>
          </JitsuProvider>
        </Suspense>
      </ThemeProvider>
    </StrictMode>,
  );
}
