/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from "@tanstack/react-query";
import { useCalendarContext, CalendarContext } from "./calendarContext";
import type * as Fetcher from "./calendarFetcher";
import { calendarFetch } from "./calendarFetcher";
import type * as Schemas from "./calendarSchemas";

export type ListGoogleAccountsError = Fetcher.ErrorWrapper<undefined>;

export type ListGoogleAccountsResponse = {
  google_accounts: {
    data: Schemas.GoogleAccount[];
  };
};

export type ListGoogleAccountsVariables = CalendarContext["fetcherOptions"];

export const fetchListGoogleAccounts = (
  variables: ListGoogleAccountsVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ListGoogleAccountsResponse,
    ListGoogleAccountsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/google_accounts", method: "get", ...variables, signal });

export const useListGoogleAccounts = <TData = ListGoogleAccountsResponse,>(
  variables: ListGoogleAccountsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListGoogleAccountsResponse,
      ListGoogleAccountsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<
    ListGoogleAccountsResponse,
    ListGoogleAccountsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/google_accounts",
      operationId: "listGoogleAccounts",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListGoogleAccounts({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ShowGoogleAccountPathParams = {
  /**
   * Google Account ID
   */
  id: string;
};

export type ShowGoogleAccountError = Fetcher.ErrorWrapper<undefined>;

export type ShowGoogleAccountResponse = {
  google_calendars: {
    data: Schemas.GoogleCalendar[];
  };
};

export type ShowGoogleAccountVariables = {
  pathParams: ShowGoogleAccountPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchShowGoogleAccount = (
  variables: ShowGoogleAccountVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ShowGoogleAccountResponse,
    ShowGoogleAccountError,
    undefined,
    {},
    {},
    ShowGoogleAccountPathParams
  >({ url: "/google_accounts/{id}", method: "get", ...variables, signal });

export const useShowGoogleAccount = <TData = ShowGoogleAccountResponse,>(
  variables: ShowGoogleAccountVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ShowGoogleAccountResponse,
      ShowGoogleAccountError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<
    ShowGoogleAccountResponse,
    ShowGoogleAccountError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/google_accounts/{id}",
      operationId: "showGoogleAccount",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchShowGoogleAccount({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteGoogleAccountPathParams = {
  /**
   * Google Account ID
   */
  id: string;
};

export type DeleteGoogleAccountError = Fetcher.ErrorWrapper<undefined>;

export type DeleteGoogleAccountVariables = {
  pathParams: DeleteGoogleAccountPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchDeleteGoogleAccount = (
  variables: DeleteGoogleAccountVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    undefined,
    DeleteGoogleAccountError,
    undefined,
    {},
    {},
    DeleteGoogleAccountPathParams
  >({ url: "/google_accounts/{id}", method: "delete", ...variables, signal });

export const useDeleteGoogleAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteGoogleAccountError,
      DeleteGoogleAccountVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    undefined,
    DeleteGoogleAccountError,
    DeleteGoogleAccountVariables
  >({
    mutationFn: (variables: DeleteGoogleAccountVariables) =>
      fetchDeleteGoogleAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableGoogleAccountPathParams = {
  /**
   * Google Account ID
   */
  id: string;
};

export type EnableGoogleAccountError = Fetcher.ErrorWrapper<undefined>;

export type EnableGoogleAccountResponse = {
  google_account: {
    data: Schemas.GoogleAccount;
  };
};

export type EnableGoogleAccountVariables = {
  pathParams: EnableGoogleAccountPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchEnableGoogleAccount = (
  variables: EnableGoogleAccountVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    EnableGoogleAccountResponse,
    EnableGoogleAccountError,
    undefined,
    {},
    {},
    EnableGoogleAccountPathParams
  >({
    url: "/google_accounts/{id}/enable",
    method: "post",
    ...variables,
    signal,
  });

export const useEnableGoogleAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      EnableGoogleAccountResponse,
      EnableGoogleAccountError,
      EnableGoogleAccountVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    EnableGoogleAccountResponse,
    EnableGoogleAccountError,
    EnableGoogleAccountVariables
  >({
    mutationFn: (variables: EnableGoogleAccountVariables) =>
      fetchEnableGoogleAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableGoogleAccountPathParams = {
  /**
   * Google Account ID
   */
  id: string;
};

export type DisableGoogleAccountError = Fetcher.ErrorWrapper<undefined>;

export type DisableGoogleAccountResponse = {
  google_account: {
    data: Schemas.GoogleAccount;
  };
};

export type DisableGoogleAccountVariables = {
  pathParams: DisableGoogleAccountPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchDisableGoogleAccount = (
  variables: DisableGoogleAccountVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    DisableGoogleAccountResponse,
    DisableGoogleAccountError,
    undefined,
    {},
    {},
    DisableGoogleAccountPathParams
  >({
    url: "/google_accounts/{id}/disable",
    method: "post",
    ...variables,
    signal,
  });

export const useDisableGoogleAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DisableGoogleAccountResponse,
      DisableGoogleAccountError,
      DisableGoogleAccountVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    DisableGoogleAccountResponse,
    DisableGoogleAccountError,
    DisableGoogleAccountVariables
  >({
    mutationFn: (variables: DisableGoogleAccountVariables) =>
      fetchDisableGoogleAccount({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateGoogleAccountCalendarsPathParams = {
  /**
   * Google Account ID
   */
  id: string;
};

export type ActivateGoogleAccountCalendarsError =
  Fetcher.ErrorWrapper<undefined>;

export type ActivateGoogleAccountCalendarsResponse = {
  google_calendars: {
    data: Schemas.GoogleCalendar[];
  };
};

export type ActivateGoogleAccountCalendarsRequestBody = {
  google_account?: {
    /**
     * List of calendars to activate
     */
    calendar_ids: any[];
  };
  required?: void;
};

export type ActivateGoogleAccountCalendarsVariables = {
  body?: ActivateGoogleAccountCalendarsRequestBody;
  pathParams: ActivateGoogleAccountCalendarsPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchActivateGoogleAccountCalendars = (
  variables: ActivateGoogleAccountCalendarsVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ActivateGoogleAccountCalendarsResponse,
    ActivateGoogleAccountCalendarsError,
    ActivateGoogleAccountCalendarsRequestBody,
    {},
    {},
    ActivateGoogleAccountCalendarsPathParams
  >({
    url: "/google_accounts/{id}/activate",
    method: "post",
    ...variables,
    signal,
  });

export const useActivateGoogleAccountCalendars = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ActivateGoogleAccountCalendarsResponse,
      ActivateGoogleAccountCalendarsError,
      ActivateGoogleAccountCalendarsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    ActivateGoogleAccountCalendarsResponse,
    ActivateGoogleAccountCalendarsError,
    ActivateGoogleAccountCalendarsVariables
  >({
    mutationFn: (variables: ActivateGoogleAccountCalendarsVariables) =>
      fetchActivateGoogleAccountCalendars({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserProfileError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Schemas.ErrorResponse;
}>;

export type UserProfileResponse = {
  user: {
    data: Schemas.User;
  };
  experiments?: {
    name: string;
    code: string;
    state: "active" | "disabled";
  }[];
};

export type UserProfileVariables = CalendarContext["fetcherOptions"];

export const fetchUserProfile = (
  variables: UserProfileVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<UserProfileResponse, UserProfileError, undefined, {}, {}, {}>({
    url: "/auth/profile",
    method: "get",
    ...variables,
    signal,
  });

export const useUserProfile = <TData = UserProfileResponse,>(
  variables: UserProfileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<UserProfileResponse, UserProfileError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<UserProfileResponse, UserProfileError, TData>({
    queryKey: queryKeyFn({
      path: "/auth/profile",
      operationId: "userProfile",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchUserProfile({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UserLoginError = Fetcher.ErrorWrapper<undefined>;

export type UserLoginResponse = {
  user: {
    data: Schemas.User;
  };
  /**
   * JWT Token
   */
  token: string;
};

export type UserLoginRequestBody = {
  /**
   * user data hash from Telegram call
   */
  user_data: string;
  /**
   * user timezone, IANA format
   *
   * @example Europe/London
   */
  timezone?: string;
  /**
   * ID token to auth (only for staging)
   */
  token_id?: number;
};

export type UserLoginVariables = {
  body: UserLoginRequestBody;
} & CalendarContext["fetcherOptions"];

export const fetchUserLogin = (
  variables: UserLoginVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    UserLoginResponse,
    UserLoginError,
    UserLoginRequestBody,
    {},
    {},
    {}
  >({ url: "/auth/login", method: "post", ...variables, signal });

export const useUserLogin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UserLoginResponse,
      UserLoginError,
      UserLoginVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    UserLoginResponse,
    UserLoginError,
    UserLoginVariables
  >({
    mutationFn: (variables: UserLoginVariables) =>
      fetchUserLogin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListEventQueryParams = {
  /**
   * Filter events DateTime from
   */
  from?: string;
  /**
   * Filter events DateTime to
   */
  to?: string;
};

export type ListEventError = Fetcher.ErrorWrapper<undefined>;

export type ListEventResponse = {
  events: {
    data: Schemas.Event[];
  };
};

export type ListEventVariables = {
  queryParams?: ListEventQueryParams;
} & CalendarContext["fetcherOptions"];

export const fetchListEvent = (
  variables: ListEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ListEventResponse,
    ListEventError,
    undefined,
    {},
    ListEventQueryParams,
    {}
  >({ url: "/events", method: "get", ...variables, signal });

export const useListEvent = <TData = ListEventResponse,>(
  variables: ListEventVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListEventResponse, ListEventError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<ListEventResponse, ListEventError, TData>({
    queryKey: queryKeyFn({
      path: "/events",
      operationId: "listEvent",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListEvent({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateEventError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.MultipleErrorResponse;
}>;

export type CreateEventResponse = {
  event: {
    data: Schemas.Event;
  };
};

export type CreateEventRequestBody = {
  /**
   * Event title
   */
  title: string;
  /**
   * Event description
   */
  description?: string;
  /**
   * Event start date and time
   *
   * @example 2024-08-01T20:40:27+03:00
   * @format date-time
   */
  start_at: string;
  /**
   * Duration of event in minutes
   *
   * @minimum 1
   */
  duration: number;
  /**
   * Event timezone, IANA format
   *
   * @example Europe/London
   */
  timezone?: string;
  /**
   * Event location
   *
   * @example London/UK
   */
  location?: string;
  /**
   * Link to zoom/meet
   *
   * @example zoom.us/join/faw24fhy
   */
  video_link?: string;
  /**
   * Notify before minutes, possible values: 0, 1, 30, 60, 180, 1440: where 0 is no notification, 1 min is right before event
   *
   * @minimum 0
   */
  notify_before: number;
  /**
   * Recurrent events, 0 - no reccurency, 1 - daily, 2 - weekly, 3 - monthly
   *
   * @minimum 0
   */
  recurrent_type: number;
  /**
   * ID стэка
   *
   * @example 666
   */
  stack_id?: string;
};

export type CreateEventVariables = {
  body: CreateEventRequestBody;
} & CalendarContext["fetcherOptions"];

export const fetchCreateEvent = (
  variables: CreateEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    CreateEventResponse,
    CreateEventError,
    CreateEventRequestBody,
    {},
    {},
    {}
  >({ url: "/events", method: "post", ...variables, signal });

export const useCreateEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateEventResponse,
      CreateEventError,
      CreateEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    CreateEventResponse,
    CreateEventError,
    CreateEventVariables
  >({
    mutationFn: (variables: CreateEventVariables) =>
      fetchCreateEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type OverlapEventsQueryParams = {
  /**
   * Time event starts
   */
  start_at: string;
  /**
   * Duration of event
   */
  duration: string;
};

export type OverlapEventsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ErrorResponse;
}>;

export type OverlapEventsResponse = {
  events: {
    data: Schemas.Event[];
  };
};

export type OverlapEventsVariables = {
  queryParams: OverlapEventsQueryParams;
} & CalendarContext["fetcherOptions"];

export const fetchOverlapEvents = (
  variables: OverlapEventsVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    OverlapEventsResponse,
    OverlapEventsError,
    undefined,
    {},
    OverlapEventsQueryParams,
    {}
  >({ url: "/events/overlap", method: "get", ...variables, signal });

export const useOverlapEvents = <TData = OverlapEventsResponse,>(
  variables: OverlapEventsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      OverlapEventsResponse,
      OverlapEventsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<OverlapEventsResponse, OverlapEventsError, TData>({
    queryKey: queryKeyFn({
      path: "/events/overlap",
      operationId: "overlapEvents",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOverlapEvents({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ShowEventPathParams = {
  /**
   * Event ID or event token
   */
  id: string;
};

export type ShowEventError = Fetcher.ErrorWrapper<undefined>;

export type ShowEventResponse = {
  event: {
    data: Schemas.EventWithUserAndInvite;
    included: (Schemas.User | Schemas.Invite)[];
  };
};

export type ShowEventVariables = {
  pathParams: ShowEventPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchShowEvent = (
  variables: ShowEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ShowEventResponse,
    ShowEventError,
    undefined,
    {},
    {},
    ShowEventPathParams
  >({ url: "/events/{id}", method: "get", ...variables, signal });

export const useShowEvent = <TData = ShowEventResponse,>(
  variables: ShowEventVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ShowEventResponse, ShowEventError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<ShowEventResponse, ShowEventError, TData>({
    queryKey: queryKeyFn({
      path: "/events/{id}",
      operationId: "showEvent",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchShowEvent({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateEventPathParams = {
  id: string;
};

export type UpdateEventError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.MultipleErrorResponse;
}>;

export type UpdateEventResponse = {
  event: {
    data: Schemas.Event;
  };
};

export type UpdateEventRequestBody = {
  /**
   * Event title
   */
  title: string;
  /**
   * Event description
   */
  description?: string;
  /**
   * Event start date and time
   *
   * @example 2024-08-01T20:40:27+03:00
   * @format date-time
   */
  start_at: string;
  /**
   * Duration of event in minutes
   *
   * @minimum 1
   */
  duration: number;
  /**
   * Event timezone, IANA format
   *
   * @example Europe/London
   */
  timezone?: string;
  /**
   * Event location
   *
   * @example London/UK
   */
  location?: string;
  /**
   * Link to zoom/meet
   *
   * @example zoom.us/join/faw24fhy
   */
  video_link?: string;
  /**
   * Notify before minutes, possible values: 0, 1, 30, 60, 180, 1440: where 0 is no notification, 1 min is right before event
   *
   * @minimum 0
   */
  notify_before: number;
  /**
   * 1 if update dependent events
   */
  dependent_update?: number;
};

export type UpdateEventVariables = {
  body: UpdateEventRequestBody;
  pathParams: UpdateEventPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchUpdateEvent = (
  variables: UpdateEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    UpdateEventResponse,
    UpdateEventError,
    UpdateEventRequestBody,
    {},
    {},
    UpdateEventPathParams
  >({ url: "/events/{id}", method: "patch", ...variables, signal });

export const useUpdateEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateEventResponse,
      UpdateEventError,
      UpdateEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    UpdateEventResponse,
    UpdateEventError,
    UpdateEventVariables
  >({
    mutationFn: (variables: UpdateEventVariables) =>
      fetchUpdateEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteEventPathParams = {
  id: string;
};

export type DeleteEventError = Fetcher.ErrorWrapper<undefined>;

export type DeleteEventRequestBody = {
  /**
   * 1 if delete dependent events
   */
  dependent_destroy?: number;
};

export type DeleteEventVariables = {
  body?: DeleteEventRequestBody;
  pathParams: DeleteEventPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchDeleteEvent = (
  variables: DeleteEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    undefined,
    DeleteEventError,
    DeleteEventRequestBody,
    {},
    {},
    DeleteEventPathParams
  >({ url: "/events/{id}", method: "delete", ...variables, signal });

export const useDeleteEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteEventError,
      DeleteEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    undefined,
    DeleteEventError,
    DeleteEventVariables
  >({
    mutationFn: (variables: DeleteEventVariables) =>
      fetchDeleteEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MissEventPathParams = {
  id: string;
};

export type MissEventError = Fetcher.ErrorWrapper<undefined>;

export type MissEventResponse = {
  event: {
    data: Schemas.EventWithUserAndInvite;
    included: (Schemas.User | Schemas.Invite)[];
  };
};

export type MissEventRequestBody = {
  /**
   * 1 if miss dependend events
   */
  dependent_miss?: number;
};

export type MissEventVariables = {
  body?: MissEventRequestBody;
  pathParams: MissEventPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchMissEvent = (
  variables: MissEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    MissEventResponse,
    MissEventError,
    MissEventRequestBody,
    {},
    {},
    MissEventPathParams
  >({ url: "/events/{id}/miss", method: "delete", ...variables, signal });

export const useMissEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      MissEventResponse,
      MissEventError,
      MissEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    MissEventResponse,
    MissEventError,
    MissEventVariables
  >({
    mutationFn: (variables: MissEventVariables) =>
      fetchMissEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveAttendeeFromEventPathParams = {
  id: string;
};

export type RemoveAttendeeFromEventError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.ErrorResponse;
}>;

export type RemoveAttendeeFromEventResponse = {
  event: {
    data: Schemas.EventWithUserAndInvite;
    included: (Schemas.User | Schemas.Invite)[];
  };
};

export type RemoveAttendeeFromEventRequestBody = {
  /**
   * user id to remove
   */
  user_id: string;
  /**
   * 1 if remove from dependend events
   */
  dependent_destroy?: number;
};

export type RemoveAttendeeFromEventVariables = {
  body: RemoveAttendeeFromEventRequestBody;
  pathParams: RemoveAttendeeFromEventPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchRemoveAttendeeFromEvent = (
  variables: RemoveAttendeeFromEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    RemoveAttendeeFromEventResponse,
    RemoveAttendeeFromEventError,
    RemoveAttendeeFromEventRequestBody,
    {},
    {},
    RemoveAttendeeFromEventPathParams
  >({
    url: "/events/{id}/remove_attendee",
    method: "delete",
    ...variables,
    signal,
  });

export const useRemoveAttendeeFromEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RemoveAttendeeFromEventResponse,
      RemoveAttendeeFromEventError,
      RemoveAttendeeFromEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    RemoveAttendeeFromEventResponse,
    RemoveAttendeeFromEventError,
    RemoveAttendeeFromEventVariables
  >({
    mutationFn: (variables: RemoveAttendeeFromEventVariables) =>
      fetchRemoveAttendeeFromEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type NotifyEventPathParams = {
  id: string;
};

export type NotifyEventError = Fetcher.ErrorWrapper<undefined>;

export type NotifyEventResponse = {
  event: {
    data: Schemas.EventWithUserAndInvite;
    included: (Schemas.User | Schemas.Invite)[];
  };
};

export type NotifyEventRequestBody = {
  /**
   * Notify before minutes, possible values: 0, 1, 30, 60, 180, 1440: where 0 is no notification, 1 min is right before event
   */
  notify_before: number;
  /**
   * 1 if apply to dependend events
   */
  dependent_notify?: number;
};

export type NotifyEventVariables = {
  body: NotifyEventRequestBody;
  pathParams: NotifyEventPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchNotifyEvent = (
  variables: NotifyEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    NotifyEventResponse,
    NotifyEventError,
    NotifyEventRequestBody,
    {},
    {},
    NotifyEventPathParams
  >({ url: "/events/{id}/notify", method: "post", ...variables, signal });

export const useNotifyEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      NotifyEventResponse,
      NotifyEventError,
      NotifyEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    NotifyEventResponse,
    NotifyEventError,
    NotifyEventVariables
  >({
    mutationFn: (variables: NotifyEventVariables) =>
      fetchNotifyEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AttendEventPathParams = {
  id: string;
};

export type AttendEventError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.MultipleErrorResponse;
}>;

export type AttendEventResponse = {
  event: {
    data: Schemas.EventWithUserAndInvite;
    included: (Schemas.User | Schemas.Invite)[];
  };
};

export type AttendEventRequestBody = {
  /**
   * 1 - attend to event, 2 - maybe attend to event
   */
  status: number;
  /**
   * 1 if attend to dependend events
   */
  dependent_attend?: number;
};

export type AttendEventVariables = {
  body: AttendEventRequestBody;
  pathParams: AttendEventPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchAttendEvent = (
  variables: AttendEventVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    AttendEventResponse,
    AttendEventError,
    AttendEventRequestBody,
    {},
    {},
    AttendEventPathParams
  >({ url: "/events/{id}/attend", method: "post", ...variables, signal });

export const useAttendEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AttendEventResponse,
      AttendEventError,
      AttendEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    AttendEventResponse,
    AttendEventError,
    AttendEventVariables
  >({
    mutationFn: (variables: AttendEventVariables) =>
      fetchAttendEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListFeatureFlagsError = Fetcher.ErrorWrapper<undefined>;

export type ListFeatureFlagsResponse = {
  feature_flags: Schemas.FeatureFlag[];
};

export type ListFeatureFlagsVariables = CalendarContext["fetcherOptions"];

export const fetchListFeatureFlags = (
  variables: ListFeatureFlagsVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ListFeatureFlagsResponse,
    ListFeatureFlagsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/feature_flags", method: "get", ...variables, signal });

export const useListFeatureFlags = <TData = ListFeatureFlagsResponse,>(
  variables: ListFeatureFlagsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListFeatureFlagsResponse,
      ListFeatureFlagsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<
    ListFeatureFlagsResponse,
    ListFeatureFlagsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/feature_flags",
      operationId: "listFeatureFlags",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListFeatureFlags({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ListStackEventsPathParams = {
  stackId: string;
};

export type ListStackEventsQueryParams = {
  page_number?: string;
  page_size?: string;
  status?: ("passed" | "upcoming")[];
};

export type ListStackEventsError = Fetcher.ErrorWrapper<undefined>;

export type ListStackEventsResponse = {
  /**
   * @example {"date":"2021-01-01","events":[{"id":1,"token":"3de3a0aef7df60128f328c0e976d","recurrent_type":0,"event_type":"event","attend_status":-1,"type":"stack_event","user":{"id":38,"first_name":"Pavel","last_name":null,"photo_url":null,"type":"small_user"},"title":"Important event","description":"Event description","start_at":"2024-11-13T20:43:05.473Z","duration":10,"timezone":null,"location":null,"video_link":null,"notify_before":0}]}
   */
  stack_events?: {
    date: void;
    events: Schemas.StackEvent[];
  }[];
  required?: void;
};

export type ListStackEventsVariables = {
  pathParams: ListStackEventsPathParams;
  queryParams?: ListStackEventsQueryParams;
} & CalendarContext["fetcherOptions"];

export const fetchListStackEvents = (
  variables: ListStackEventsVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ListStackEventsResponse,
    ListStackEventsError,
    undefined,
    {},
    ListStackEventsQueryParams,
    ListStackEventsPathParams
  >({ url: "/stacks/{stackId}/events", method: "get", ...variables, signal });

export const useListStackEvents = <TData = ListStackEventsResponse,>(
  variables: ListStackEventsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListStackEventsResponse,
      ListStackEventsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<
    ListStackEventsResponse,
    ListStackEventsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/stacks/{stackId}/events",
      operationId: "listStackEvents",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListStackEvents({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ListStackSubscriptionsPathParams = {
  stackId: string;
};

export type ListStackSubscriptionsQueryParams = {
  page_number?: number;
  page_size?: number;
};

export type ListStackSubscriptionsError = Fetcher.ErrorWrapper<undefined>;

export type ListStackSubscriptionsResponse = {
  stack_subscriptions: Schemas.StackSubscription[];
  meta: {
    count: number;
    page_number: number;
    page_size: number;
  };
};

export type ListStackSubscriptionsVariables = {
  pathParams: ListStackSubscriptionsPathParams;
  queryParams?: ListStackSubscriptionsQueryParams;
} & CalendarContext["fetcherOptions"];

export const fetchListStackSubscriptions = (
  variables: ListStackSubscriptionsVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ListStackSubscriptionsResponse,
    ListStackSubscriptionsError,
    undefined,
    {},
    ListStackSubscriptionsQueryParams,
    ListStackSubscriptionsPathParams
  >({
    url: "/stacks/{stackId}/stack_subscriptions",
    method: "get",
    ...variables,
    signal,
  });

export const useListStackSubscriptions = <
  TData = ListStackSubscriptionsResponse,
>(
  variables: ListStackSubscriptionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListStackSubscriptionsResponse,
      ListStackSubscriptionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<
    ListStackSubscriptionsResponse,
    ListStackSubscriptionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/stacks/{stackId}/stack_subscriptions",
      operationId: "listStackSubscriptions",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListStackSubscriptions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateStackSubscriptionPathParams = {
  stackId: string;
};

export type CreateStackSubscriptionError = Fetcher.ErrorWrapper<undefined>;

export type CreateStackSubscriptionVariables = {
  pathParams: CreateStackSubscriptionPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchCreateStackSubscription = (
  variables: CreateStackSubscriptionVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    Schemas.StackSubscription,
    CreateStackSubscriptionError,
    undefined,
    {},
    {},
    CreateStackSubscriptionPathParams
  >({
    url: "/stacks/{stackId}/stack_subscriptions",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateStackSubscription = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.StackSubscription,
      CreateStackSubscriptionError,
      CreateStackSubscriptionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    Schemas.StackSubscription,
    CreateStackSubscriptionError,
    CreateStackSubscriptionVariables
  >({
    mutationFn: (variables: CreateStackSubscriptionVariables) =>
      fetchCreateStackSubscription({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteStackSubscriptionPathParams = {
  stackId: string;
};

export type DeleteStackSubscriptionError = Fetcher.ErrorWrapper<undefined>;

export type DeleteStackSubscriptionVariables = {
  pathParams: DeleteStackSubscriptionPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchDeleteStackSubscription = (
  variables: DeleteStackSubscriptionVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    undefined,
    DeleteStackSubscriptionError,
    undefined,
    {},
    {},
    DeleteStackSubscriptionPathParams
  >({
    url: "/stacks/{stackId}/stack_subscriptions",
    method: "delete",
    ...variables,
    signal,
  });

export const useDeleteStackSubscription = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteStackSubscriptionError,
      DeleteStackSubscriptionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    undefined,
    DeleteStackSubscriptionError,
    DeleteStackSubscriptionVariables
  >({
    mutationFn: (variables: DeleteStackSubscriptionVariables) =>
      fetchDeleteStackSubscription({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateStackSubscriptionPathParams = {
  id: string;
  stackId: string;
};

export type UpdateStackSubscriptionError = Fetcher.ErrorWrapper<undefined>;

export type UpdateStackSubscriptionRequestBody = {
  /**
   * Role
   */
  role?: "reader" | "creator" | "owner";
  /**
   * State of stack subscription
   */
  state?: "active" | "disabled";
};

export type UpdateStackSubscriptionVariables = {
  body?: UpdateStackSubscriptionRequestBody;
  pathParams: UpdateStackSubscriptionPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchUpdateStackSubscription = (
  variables: UpdateStackSubscriptionVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    Schemas.StackSubscription,
    UpdateStackSubscriptionError,
    UpdateStackSubscriptionRequestBody,
    {},
    {},
    UpdateStackSubscriptionPathParams
  >({
    url: "/stacks/{stackId}/stack_subscriptions/{id}",
    method: "patch",
    ...variables,
    signal,
  });

export const useUpdateStackSubscription = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.StackSubscription,
      UpdateStackSubscriptionError,
      UpdateStackSubscriptionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    Schemas.StackSubscription,
    UpdateStackSubscriptionError,
    UpdateStackSubscriptionVariables
  >({
    mutationFn: (variables: UpdateStackSubscriptionVariables) =>
      fetchUpdateStackSubscription({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListStackQueryParams = {
  page_number?: number;
  page_size?: number;
  roles?: ("creator" | "reader" | "owner")[];
};

export type ListStackError = Fetcher.ErrorWrapper<undefined>;

export type ListStackResponse = {
  stacks: Schemas.Stack[];
  meta: {
    count: number;
    page_number: number;
    page_size: number;
  };
};

export type ListStackVariables = {
  queryParams?: ListStackQueryParams;
} & CalendarContext["fetcherOptions"];

export const fetchListStack = (
  variables: ListStackVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ListStackResponse,
    ListStackError,
    undefined,
    {},
    ListStackQueryParams,
    {}
  >({ url: "/stacks", method: "get", ...variables, signal });

export const useListStack = <TData = ListStackResponse,>(
  variables: ListStackVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListStackResponse, ListStackError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<ListStackResponse, ListStackError, TData>({
    queryKey: queryKeyFn({
      path: "/stacks",
      operationId: "listStack",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListStack({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateStackError = Fetcher.ErrorWrapper<{
  status: 422;
  payload: Schemas.MultipleErrorResponse;
}>;

export type CreateStackRequestBody = {
  /**
   * Event title
   */
  title: string;
  /**
   * Emoji
   *
   * @example 🐹
   */
  emoji?: string;
  /**
   * @default false
   */
  default_creator?: boolean;
};

export type CreateStackVariables = {
  body: CreateStackRequestBody;
} & CalendarContext["fetcherOptions"];

export const fetchCreateStack = (
  variables: CreateStackVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    Schemas.Stack,
    CreateStackError,
    CreateStackRequestBody,
    {},
    {},
    {}
  >({ url: "/stacks", method: "post", ...variables, signal });

export const useCreateStack = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Stack,
      CreateStackError,
      CreateStackVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    Schemas.Stack,
    CreateStackError,
    CreateStackVariables
  >({
    mutationFn: (variables: CreateStackVariables) =>
      fetchCreateStack({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetStackPathParams = {
  id: string;
};

export type GetStackError = Fetcher.ErrorWrapper<undefined>;

export type GetStackVariables = {
  pathParams: GetStackPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchGetStack = (
  variables: GetStackVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    Schemas.Stack,
    GetStackError,
    undefined,
    {},
    {},
    GetStackPathParams
  >({ url: "/stacks/{id}", method: "get", ...variables, signal });

export const useGetStack = <TData = Schemas.Stack,>(
  variables: GetStackVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Stack, GetStackError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<Schemas.Stack, GetStackError, TData>({
    queryKey: queryKeyFn({
      path: "/stacks/{id}",
      operationId: "getStack",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetStack({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateStackPathParams = {
  id: string;
};

export type UpdateStackError = Fetcher.ErrorWrapper<undefined>;

export type UpdateStackRequestBody = {
  stack: {
    /**
     * Event title
     */
    title?: string;
    /**
     * Emoji
     *
     * @example 🐹
     */
    emoji?: string;
    /**
     * Visibility of stack
     */
    visibility?: "only_author" | "by_link" | "everyone";
    /**
     * State of stack
     */
    state?: "active" | "disabled";
    /**
     * @default false
     */
    default_creator?: boolean;
  };
};

export type UpdateStackVariables = {
  body: UpdateStackRequestBody;
  pathParams: UpdateStackPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchUpdateStack = (
  variables: UpdateStackVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    Schemas.Stack,
    UpdateStackError,
    UpdateStackRequestBody,
    {},
    {},
    UpdateStackPathParams
  >({ url: "/stacks/{id}", method: "patch", ...variables, signal });

export const useUpdateStack = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Stack,
      UpdateStackError,
      UpdateStackVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    Schemas.Stack,
    UpdateStackError,
    UpdateStackVariables
  >({
    mutationFn: (variables: UpdateStackVariables) =>
      fetchUpdateStack({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteStackPathParams = {
  id: string;
};

export type DeleteStackError = Fetcher.ErrorWrapper<undefined>;

export type DeleteStackVariables = {
  pathParams: DeleteStackPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchDeleteStack = (
  variables: DeleteStackVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    undefined,
    DeleteStackError,
    undefined,
    {},
    {},
    DeleteStackPathParams
  >({ url: "/stacks/{id}", method: "delete", ...variables, signal });

export const useDeleteStack = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteStackError,
      DeleteStackVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    undefined,
    DeleteStackError,
    DeleteStackVariables
  >({
    mutationFn: (variables: DeleteStackVariables) =>
      fetchDeleteStack({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ShareStackViaBotPathParams = {
  stackId: string;
};

export type ShareStackViaBotError = Fetcher.ErrorWrapper<undefined>;

export type ShareStackViaBotVariables = {
  pathParams: ShareStackViaBotPathParams;
} & CalendarContext["fetcherOptions"];

export const fetchShareStackViaBot = (
  variables: ShareStackViaBotVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    undefined,
    ShareStackViaBotError,
    undefined,
    {},
    {},
    ShareStackViaBotPathParams
  >({
    url: "/stacks/{stackId}/share_via_bot",
    method: "post",
    ...variables,
    signal,
  });

export const useShareStackViaBot = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ShareStackViaBotError,
      ShareStackViaBotVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    undefined,
    ShareStackViaBotError,
    ShareStackViaBotVariables
  >({
    mutationFn: (variables: ShareStackViaBotVariables) =>
      fetchShareStackViaBot({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListEmojisQueryParams = {
  page_number?: string;
  page_size?: string;
};

export type ListEmojisError = Fetcher.ErrorWrapper<undefined>;

export type ListEmojisResponse = {
  emojis: Schemas.Emoji[];
  meta: {
    count: number;
    page_number: number;
    page_size: number;
  };
};

export type ListEmojisVariables = {
  queryParams?: ListEmojisQueryParams;
} & CalendarContext["fetcherOptions"];

export const fetchListEmojis = (
  variables: ListEmojisVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ListEmojisResponse,
    ListEmojisError,
    undefined,
    {},
    ListEmojisQueryParams,
    {}
  >({ url: "/emojis", method: "get", ...variables, signal });

export const useListEmojis = <TData = ListEmojisResponse,>(
  variables: ListEmojisVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListEmojisResponse, ListEmojisError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useCalendarContext(options);
  return reactQuery.useQuery<ListEmojisResponse, ListEmojisError, TData>({
    queryKey: queryKeyFn({
      path: "/emojis",
      operationId: "listEmojis",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListEmojis({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateUserTokenError = Fetcher.ErrorWrapper<undefined>;

export type CreateUserTokenResponse = {
  token: string;
};

export type CreateUserTokenVariables = CalendarContext["fetcherOptions"];

export const fetchCreateUserToken = (
  variables: CreateUserTokenVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    CreateUserTokenResponse,
    CreateUserTokenError,
    undefined,
    {},
    {},
    {}
  >({ url: "/user_tokens", method: "post", ...variables, signal });

export const useCreateUserToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateUserTokenResponse,
      CreateUserTokenError,
      CreateUserTokenVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    CreateUserTokenResponse,
    CreateUserTokenError,
    CreateUserTokenVariables
  >({
    mutationFn: (variables: CreateUserTokenVariables) =>
      fetchCreateUserToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ValidateUserTokenError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Schemas.ErrorResponse;
}>;

export type ValidateUserTokenResponse = {
  /**
   * Status
   */
  status: string;
  /**
   * Email of connected google account
   */
  email: string;
  /**
   * Name of connected google account
   */
  name: string;
};

export type ValidateUserTokenRequestBody = {
  /**
   * User token
   */
  token: string;
  /**
   * Google credentials
   */
  google_credentials: string;
  /**
   * 1 - use local redirect uri, only on staging
   */
  local?: number;
};

export type ValidateUserTokenVariables = {
  body: ValidateUserTokenRequestBody;
} & CalendarContext["fetcherOptions"];

export const fetchValidateUserToken = (
  variables: ValidateUserTokenVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    ValidateUserTokenResponse,
    ValidateUserTokenError,
    ValidateUserTokenRequestBody,
    {},
    {},
    {}
  >({ url: "/user_tokens/validate", method: "post", ...variables, signal });

export const useValidateUserToken = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ValidateUserTokenResponse,
      ValidateUserTokenError,
      ValidateUserTokenVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    ValidateUserTokenResponse,
    ValidateUserTokenError,
    ValidateUserTokenVariables
  >({
    mutationFn: (variables: ValidateUserTokenVariables) =>
      fetchValidateUserToken({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: "/google_accounts";
      operationId: "listGoogleAccounts";
      variables: ListGoogleAccountsVariables;
    }
  | {
      path: "/google_accounts/{id}";
      operationId: "showGoogleAccount";
      variables: ShowGoogleAccountVariables;
    }
  | {
      path: "/auth/profile";
      operationId: "userProfile";
      variables: UserProfileVariables;
    }
  | {
      path: "/events";
      operationId: "listEvent";
      variables: ListEventVariables;
    }
  | {
      path: "/events/overlap";
      operationId: "overlapEvents";
      variables: OverlapEventsVariables;
    }
  | {
      path: "/events/{id}";
      operationId: "showEvent";
      variables: ShowEventVariables;
    }
  | {
      path: "/feature_flags";
      operationId: "listFeatureFlags";
      variables: ListFeatureFlagsVariables;
    }
  | {
      path: "/stacks/{stackId}/events";
      operationId: "listStackEvents";
      variables: ListStackEventsVariables;
    }
  | {
      path: "/stacks/{stackId}/stack_subscriptions";
      operationId: "listStackSubscriptions";
      variables: ListStackSubscriptionsVariables;
    }
  | {
      path: "/stacks";
      operationId: "listStack";
      variables: ListStackVariables;
    }
  | {
      path: "/stacks/{id}";
      operationId: "getStack";
      variables: GetStackVariables;
    }
  | {
      path: "/emojis";
      operationId: "listEmojis";
      variables: ListEmojisVariables;
    };
