import * as React from 'react';

import { cn } from '@tg-web/utils';

export type TextareaProps = {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, leftIcon, rightIcon, ...props }, ref) => {
    const gridColumnPositionClassName =
      'col-start-1 col-end-2 row-start-1 row-end-2';

    return (
      <div className="relative">
        {leftIcon && (
          <div className="absolute left-3 top-3 h-[32px] w-[32px]">
            {leftIcon}
          </div>
        )}
        <div className="grid">
          <div
            className={cn(
              gridColumnPositionClassName,
              'invisible mb-5 whitespace-pre-wrap',
            )}
            aria-hidden={true}
          >
            {props.value}
          </div>
          <textarea
            className={cn(
              gridColumnPositionClassName,
              'bg-tg-section-bg typo-text flex min-h-[60px] w-full resize-none overflow-hidden rounded-2xl pb-3 pt-4',
              'placeholder:text-tg-hint',
              'focus-visible:border-c-gray-light-icon focus-visible:outline-none',
              'disabled:cursor-not-allowed disabled:opacity-50',
              Boolean(leftIcon) && 'pl-[54px]',
              Boolean(rightIcon) && 'pr-[54px]',
              className,
            )}
            ref={ref}
            {...props}
          />
        </div>
        {rightIcon && (
          <div className="absolute right-3 top-3 h-[32px] w-[32px]">
            {rightIcon}
          </div>
        )}
      </div>
    );
  },
);
Textarea.displayName = 'Textarea';

export { Textarea };
