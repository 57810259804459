import { CSSProperties, PropsWithChildren } from 'react';
import * as React from 'react';

import { type VariantProps, cva } from 'class-variance-authority';

import { Skeleton } from '@tg-web/components';
import { GoogleIcon } from '@tg-web/icons';
import { PropsWithCN, cn } from '@tg-web/utils';

const eventVariants = cva(
  'relative flex flex-row gap-1.5 h-[54px] items-center rounded-xl rounded-tl-[0.25rem] px-3 py-2 w-full cursor-pointer',
  {
    defaultVariants: {
      variant: 'empty',
    },
    variants: {
      variant: {
        attend: 'bg-tg-secondary-bg',
        empty: 'border-tg-section-separator bg-tg-bg border-03',
        loading: 'bg-tg-secondary-bg',
        maybe: 'bg-tg-secondary-bg',
      },
    },
  },
);

const eventIndicatorVariants = cva('h-2 w-2 rounded border', {
  defaultVariants: {
    variant: 'secondary',
  },
  variants: {
    variant: {
      accent: 'bg-transparent border-tg-button',
      custom: '',
      secondary: 'bg-tg-secondary-bg border-tg-secondary-bg my-auto',
    },
  },
});

type EventItemProps = {
  indicatorColor?: string;
  indicatorVariant?: VariantProps<typeof eventIndicatorVariants>['variant'];
  isGoogle?: boolean;
  isLoading?: boolean;
} & PropsWithCN &
  PropsWithChildren &
  React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof eventVariants>;

export function EventItem({
  children,
  className,
  indicatorColor,
  indicatorVariant,
  isGoogle,
  isLoading,
  variant,
  ...restProps
}: EventItemProps) {
  if (isLoading) {
    return (
      <Skeleton className={eventVariants({ className, variant: 'loading' })} />
    );
  }

  const indicatorStyles: CSSProperties = {};
  if (indicatorColor) {
    indicatorStyles.backgroundColor = indicatorColor;
    indicatorStyles.borderColor = indicatorColor;
  }

  return (
    <div className={eventVariants({ className, variant })} {...restProps}>
      <div className={cn('flex h-full shrink-0 flex-col py-[3px]')}>
        <div
          className={eventIndicatorVariants({
            variant: indicatorVariant,
          })}
          style={indicatorStyles}
        />
      </div>
      <div className="flex min-w-0 grow">{children}</div>
      {isGoogle && (
        <div className="absolute right-3 top-2 flex h-full shrink-0 flex-col items-start">
          <GoogleIcon className="h-3 w-3" />
        </div>
      )}
    </div>
  );
}
