import { useEffect } from 'react';

import { getWebApp } from '@tg-web/utils';

export type UseMainButtonOptions = {
  isHidden?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  text: string;
};
export function useMainButton({
  isHidden = false,
  isLoading = false,
  onClick,
  text,
}: UseMainButtonOptions) {
  useEffect(() => {
    if (!isHidden) {
      getWebApp().MainButton.setText(text);
    }
  }, [text, isHidden]);

  useEffect(() => {
    if (isLoading && !getWebApp().MainButton.isProgressVisible) {
      getWebApp().MainButton.showProgress();
    }
    if (!isLoading && getWebApp().MainButton.isProgressVisible) {
      getWebApp().MainButton.hideProgress();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isHidden) {
      getWebApp().MainButton.onClick(onClick);
      return () => {
        getWebApp().MainButton.offClick(onClick);
      };
    }
  }, [onClick, isHidden]);

  useEffect(() => {
    if (!isHidden) {
      getWebApp().MainButton.show();
    }
    return () => {
      getWebApp().MainButton.hide();
    };
  }, [isHidden]);
}
