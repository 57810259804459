import { MouseEventHandler } from 'react';

import { Avatar, AvatarFallback, AvatarImage } from '@tg-web/components';
import { PropsWithCN, cn } from '@tg-web/utils';

import { User } from '../../../shared/api';

export type UserAvatarProps = {
  fallbackClassName?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  user: User;
} & PropsWithCN;
export function UserAvatar({
  className,
  fallbackClassName,
  onClick,
  user,
}: UserAvatarProps) {
  return (
    <Avatar className={cn('', className)} onClick={onClick}>
      {user.attributes.profile_photo_url && (
        <AvatarImage src={user.attributes.profile_photo_url} />
      )}
      <AvatarFallback className={cn('uppercase', fallbackClassName)}>
        {user.attributes.first_name[0]}
        {user.attributes.last_name?.[0]}
      </AvatarFallback>
    </Avatar>
  );
}
