import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { EditIcon } from '@tg-web/icons';

import { Event } from '../../../shared/api';
import { EventActionButton } from './EventActionButton';

export interface EditEventButtonProps {
  event: Event;
}

export function EditEventButton({ event }: EditEventButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <EventActionButton
      onClick={() => {
        navigate({
          params: { eventId: event.id },
          to: '/events/$eventId/edit',
        });
      }}
      icon={<EditIcon />}
      text={t('all:common.buttons.edit')}
    />
  );
}
