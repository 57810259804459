import { getWebApp } from '@tg-web/utils';

const AllowedLangs = ['ru', 'en'] as const;
type AllowedLang = (typeof AllowedLangs)[number];

function isAllowedLang(lang: string): lang is AllowedLang {
  return (AllowedLangs as readonly string[]).includes(lang);
}

export function useAppLang() {
  const userLang = getWebApp().initDataUnsafe.user?.language_code;
  const appLang: AllowedLang =
    userLang && isAllowedLang(userLang) ? userLang : 'en';

  return {
    appLang,
  };
}
