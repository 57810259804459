import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { getWebApp } from '@tg-web/utils';

import { useGoogleAuthHandler } from '../../../features/google';
import { useUserProfileConfigured } from '../../../shared/api';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function GoogleOnboardingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userProfile = useUserProfileConfigured({
    refetchOnWindowFocus: 'always',
  });
  const { createUserToken, googleAuthHandler } = useGoogleAuthHandler();

  const [feedSearch, setFeedSearch] = useState();
  useEffect(() => {
    getWebApp().CloudStorage.removeItem(CloudStorageKeys.SHOW_WELCOME);

    getCloudStorageItem(CloudStorageKeys.FEED_SEARCH).then((value) => {
      setFeedSearch(value ? JSON.parse(value) : {});
    });
  }, []);

  const navigateToWelcomeFeed = () => {
    navigate(
      feedSearch
        ? { search: feedSearch, to: '/feed' }
        : { to: '/feed-welcome' },
    );
  };

  useEffect(() => {
    if (
      userProfile.isSuccess &&
      userProfile.data.user.data.attributes.has_google_calendar
    ) {
      navigateToWelcomeFeed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile.isSuccess, userProfile.isRefetching]);

  return (
    <PageWrapper className="flex flex-col p-5">
      <div className="grow" />
      <div className="flex shrink-0 flex-col items-center gap-6 text-center">
        <img
          alt="Google calendar icon"
          className="h-20 w-20"
          src="/images/gcal_icon.webp"
        />
        <h1 className="typo-header-biggest">
          {t('all:google.onboarding.title')}
        </h1>
        <p className="typo-text px-10">
          {t('all:google.onboarding.description')}
        </p>
      </div>
      <div className="grow" />
      <div className="grow" />
      <div className="grow" />
      <div className="flex shrink-0 flex-col gap-3 text-center">
        <Button
          disabled={createUserToken.isPending}
          onClick={googleAuthHandler}
          type="button"
          variant="primary"
        >
          {t('all:common.buttons.continue')}
        </Button>
        <Button onClick={navigateToWelcomeFeed} type="button" variant="ghost">
          {t('all:google.onboarding.skip_button')}
        </Button>
        <p className="typo-text text-tg-subtitle-text px-10">
          {t('all:google.onboarding.hint')}
        </p>
      </div>
    </PageWrapper>
  );
}
