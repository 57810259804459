import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { ShevronRightIcon } from '@tg-web/icons';
import { cn } from '@tg-web/utils';

import { buttonVariants } from './button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      classNames={{
        button_next: cn(
          buttonVariants({ variant: 'secondary' }),
          'h-10 w-10 p-0 rounded-full',
        ),
        button_previous: cn(
          buttonVariants({ variant: 'secondary' }),
          'h-10 w-10 p-0 rounded-full',
        ),
        day: cn(
          'relative p-0 overflow-hidden text-center focus-within:relative focus-within:z-20 aria-selected:bg-tg-button aria-selected.day-outside:bg-accent/50 aria-selected.day-range-end:rounded-r-lg',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-lg [&:has(>.day-range-start)]:rounded-l-lg first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-lg'
            : 'aria-selected:rounded-lg',
        ),
        day_button: cn(
          buttonVariants({
            className: 'typo-text transition-none',
            size: 'icon',
            variant: 'ghost',
          }),
          'h-8 w-8 p-0 aria-selected:opacity-100',
        ),
        disabled: 'text-muted-foreground opacity-50',
        hidden: 'invisible',
        month: 'space-y-7',
        month_caption: 'flex flex-col justify-center typo-text-bold h-10',
        month_grid: 'w-full border-collapse space-y-1',
        months: 'flex flex-col relative',
        nav: 'space-x-4 flex items-center absolute right-0',
        outside:
          'day-outside text-tg-hint opacity-50 aria-selected:bg-tg-button aria-selected:text-tg-button-text aria-selected:opacity-100',
        range_end: 'day-range-end',
        range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        range_start: 'day-range-start',
        selected:
          'bg-tg-button text-tg-button-text hover:bg-tg-button hover:text-tg-button-text focus:bg-tg-button focus:text-tg-button-text',
        today: 'text-tg-accent-text',
        week: 'flex w-full mt-2 justify-between',
        weekday: 'text-tg-hint w-8 typo-small uppercase cursor-default',
        weekdays: 'flex justify-between',
        ...classNames,
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === 'left') {
            return <ShevronRightIcon className="rotate-180" />;
          }
          return <ShevronRightIcon />;
        },
      }}
      className={cn('select-none p-3', className)}
      showOutsideDays={showOutsideDays}
      weekStartsOn={1}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
