import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@tg-web/components';
import {
  EditIcon,
  MoreIcon,
  NotificationIcon,
  RemoveIcon,
} from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import { NotificationModal } from '../../notifications';
import { useDeleteEventLogic, useEventNotification } from '../lib';
import { AttendStatus } from '../model';
import { EventActionButton } from './EventActionButton';

export interface EventActionsMenuProps {
  event: Event;
  onNotificationChange: VoidFunction;
}

export function EventActionsMenu({
  event,
  onNotificationChange,
}: EventActionsMenuProps) {
  const { t } = useTranslation();
  const { handleDelete } = useDeleteEventLogic({ event });
  const navigate = useNavigate();
  const notificationModal = useDisclosure();
  const { handleNotificationSave, isRecurringEvent } = useEventNotification({
    event,
    onClose: notificationModal.onClose,
    onSuccess: onNotificationChange,
  });

  const handleEditClick = () =>
    navigate({
      params: { eventId: event.id },
      to: '/events/$eventId/edit',
    });

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="focus-visible:outline-none">
          <EventActionButton
            icon={<MoreIcon />}
            text={t('all:view_event.stack_event_owner.manage')}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={handleEditClick}>
            {t('all:common.buttons.edit')}
            <EditIcon height={24} width={24} />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDelete}>
            {t('all:common.buttons.remove')}
            <RemoveIcon height={24} width={24} />
          </DropdownMenuItem>
          {[AttendStatus.attend, AttendStatus.maybe].includes(
            event.attributes.attend_status,
          ) && (
            <DropdownMenuItem onClick={notificationModal.onOpen}>
              {t('all:common.buttons.notification')}
              <NotificationIcon height={24} width={24} />
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <NotificationModal
        title={
          isRecurringEvent
            ? t('all:event.notifications.recurrent.title')
            : t('all:event.notifications.once.title')
        }
        initial={event.attributes.notify_before}
        isRecurringEvent={isRecurringEvent}
        onClose={notificationModal.onClose}
        onSave={handleNotificationSave}
        open={notificationModal.open}
      />
    </>
  );
}
