import { getWebApp } from '@tg-web/utils';

export function openShareDialog(text: string, url?: string) {
  const params = {
    text: encodeURIComponent(text),
    url: url ? encodeURIComponent(url) : undefined,
  };

  getWebApp().openTelegramLink(
    `https://t.me/share/url?${Object.entries(params)
      .filter(([_, value]) => Boolean(value))
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`,
  );
}
