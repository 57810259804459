import { PropsWithChildren } from 'react';

import { PropsWithCN, cn } from '@tg-web/utils';

export function CircleIconWrapper({
  children,
  className,
}: PropsWithCN & PropsWithChildren) {
  return (
    <div
      className={cn(
        'bg-tg-bg flex h-[64px] w-[64px] items-center justify-center rounded-full',
        className,
      )}
    >
      {children}
    </div>
  );
}
