import React from 'react';

import { IconProps } from './types';

export const RemoveIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M11.1719 6.59375C11.0834 6.11979 11.0573 5.65625 11.1042 5.1875C11.1823 4.44792 11.7032 3.83333 12.4011 3.59375C14.7344 2.80208 17.2657 2.80208 19.599 3.59375C20.2969 3.83333 20.8125 4.44792 20.8907 5.1875C20.9479 5.69792 20.9115 6.20313 20.8021 6.72917"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M24.4636 7C25.9427 12.7656 26.2448 18.7552 25.3594 24.6094C24.9427 27.0521 22.5625 29 20.3594 29C17.7865 29 14.2136 29 11.6406 29C9.43751 29 7.0573 27.0521 6.64063 24.6094C5.75522 18.7552 6.0573 12.7656 7.53647 7"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M4.40106 6.01562C12.0781 7.34896 19.9271 7.34375 27.599 6"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M12 22C17 18 20 14 20 14"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M20 22C15 18 12 14 12 14"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
