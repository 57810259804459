import { useTranslation } from 'react-i18next';

import { Event, StackEvent } from '../../../shared/api';
import { getEventInterval } from './getEventInterval';
import { isFullDayInterval } from './isFullDayInterval';
import { useFormatEventDateAndTime } from './useFormatEventDateAndTime';

export function useFormatEventTime() {
  const { t } = useTranslation();
  const { formatEventDateAndTime } = useFormatEventDateAndTime();

  const formatEventTime = (event: Event | StackEvent) => {
    const interval = getEventInterval(event);
    const isAllDayEvent = isFullDayInterval(interval);

    return isAllDayEvent
      ? t('all:feed.event.full_day')
      : formatEventDateAndTime(event, true);
  };

  return {
    formatEventTime,
  };
}
