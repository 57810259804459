import { getWebApp } from '@tg-web/utils';

export function removeCloudStorageItem(key: string) {
  return new Promise<void>((resolve, reject) => {
    getWebApp().CloudStorage.removeItem(key, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
}
