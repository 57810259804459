import * as React from 'react';
import {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
} from 'react';

import { PropsWithCN, cn } from '@tg-web/utils';

export type ViewFieldProps<T extends ElementType> = {
  as?: T;
  innerClassName?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
} & ComponentPropsWithoutRef<T> &
  PropsWithCN &
  PropsWithChildren;

export function ViewField<T extends ElementType>({
  as,
  children,
  className,
  innerClassName,
  leftIcon,
  rightIcon,
  ...restProps
}: ViewFieldProps<T>) {
  const Component = as ?? 'div';
  return (
    <Component
      className={cn(
        'bg-tg-section-bg border-tg-section-separator typo-text border-b-03 relative flex min-h-14 w-full px-3 py-[18.5px]',
        'first:rounded-t-2xl',
        'last:rounded-b-2xl last:border-b-0',
        className,
      )}
      {...restProps}
    >
      {leftIcon && (
        <div className="absolute left-3 top-3 h-[32px] w-[32px]">
          {leftIcon}
        </div>
      )}
      <div
        className={cn(
          'whitespace-pre-wrap',
          Boolean(leftIcon) && 'pl-[42px]',
          Boolean(rightIcon) && 'pr-[42px]',
          innerClassName,
        )}
      >
        {children}
      </div>
      {rightIcon && (
        <div className="absolute right-3 top-3 h-[32px] w-[32px]">
          {rightIcon}
        </div>
      )}
    </Component>
  );
}
