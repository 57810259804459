import { getWebApp } from '@tg-web/utils';

export function getCloudStorageItem(key: string) {
  return new Promise<null | string>((resolve, reject) => {
    getWebApp().CloudStorage.getItem(key, (error, value) => {
      if (error) {
        reject(error);
      } else {
        resolve(value);
      }
    });
  });
}
