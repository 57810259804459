import React from 'react';

import { IconProps } from './types';

export const VideoIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M23.5 12.1771C24.8385 11.125 26.1094 10.1927 27.25 9.88022C28.1615 9.62501 29.1406 10.1094 29.5365 10.974C30.8229 13.8073 30.8229 18.1927 29.5365 21.0261C29.1406 21.8906 28.1615 22.375 27.25 22.1198C26.1146 21.8073 24.849 20.8802 23.5104 19.8333"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M5.70835 8.42709C10.8802 6.46876 16.0521 6.46876 21.2292 8.42709C21.6823 8.59897 22.0781 8.94793 22.3021 9.39584C24.5209 13.7969 24.5209 18.1979 22.3021 22.6042C22.0781 23.0521 21.6823 23.4011 21.2292 23.5729C16.0521 25.5313 10.8802 25.5313 5.70835 23.5729C5.25002 23.4011 4.8594 23.0521 4.63544 22.6042C2.41148 18.2031 2.41148 13.8021 4.63544 9.39584C4.8594 8.94793 5.25002 8.59897 5.70835 8.42709Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
