export enum AttendStatus {
  attend = 1,
  maybe = 2,
  missed = 0,
  notAttended = -1,
  removed = -2,
}

export const AttendStatusesRequiringOverlap = [
  AttendStatus.notAttended,
  AttendStatus.missed,
  AttendStatus.removed,
];
