import React from 'react';

import { IconProps } from './types';

export const CopyIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M20.6826 14.6198C19.4326 12.3594 17.5785 10.4896 15.6462 9.22396C12.9326 8.76042 10.2139 9.01042 7.50033 9.97396C6.69824 10.2552 6.06803 10.9062 5.87012 11.7292C4.70866 16.5729 4.70866 21.4219 5.87012 26.2708C6.06803 27.0885 6.68783 27.7396 7.4847 28.0208C11.1618 29.3229 14.8389 29.3229 18.516 28.0208C19.3128 27.7396 19.9326 27.0885 20.1305 26.2708C21.0576 22.3854 21.2451 18.5052 20.6826 14.6198Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M20.75 22.901C22.0052 22.7604 23.2604 22.4687 24.5156 22.0208C25.3125 21.7396 25.9323 21.0885 26.1302 20.2708C27.0573 16.3854 27.2448 12.5052 26.6823 8.61979C25.4323 6.35937 23.5781 4.48958 21.6458 3.22396C18.9323 2.76042 16.2135 3.01042 13.5 3.97396C12.6979 4.25521 12.0677 4.90625 11.8698 5.72917C11.599 6.84896 11.3958 7.97396 11.25 9.09896"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M26.2662 8.84894C24.6933 9.1354 23.1152 9.0156 21.5371 8.48956C21.0162 7.0729 20.8756 5.65623 21.1152 4.23956"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M20.2662 14.8489C18.6933 15.1354 17.1152 15.0156 15.5371 14.4896C15.0162 13.0729 14.8756 11.6562 15.1152 10.2396"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
