import { useTranslation } from 'react-i18next';

import { Skeleton } from '@tg-web/components';
import { DateIcon, PlusCircleIcon } from '@tg-web/icons';

import { StackEvents } from '../index';
import { StackEventsList } from './StackEventsList';
import { StackEventsWrapper } from './StackEventsWrapper';

export interface UpcomingStackEventsProps {
  canCreate: boolean;
  events: StackEvents;
  isLoading: boolean;
  stackId: string;
}

export function UpcomingStackEvents({
  canCreate,
  events,
  isLoading,
  stackId,
}: UpcomingStackEventsProps) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <StackEventsWrapper className="gap-3 px-5 py-2">
        <Skeleton className="bg-secondary h-[38px] w-full" />
        <Skeleton className="bg-secondary h-[125px] w-full" />
        <Skeleton className="bg-secondary h-[125px] w-full" />
        <Skeleton className="bg-secondary h-[125px] w-full" />
      </StackEventsWrapper>
    );
  }

  if (events.length === 0) {
    return (
      <StackEventsWrapper className="items-center gap-4 px-5 py-10">
        {canCreate ? (
          <PlusCircleIcon className="text-tg-hint" />
        ) : (
          <DateIcon className="text-tg-hint h-[40px] w-[40px]" />
        )}
        <span className="typo-header-small">
          {canCreate
            ? t('all:stacks.view.upcoming.empty.title')
            : t('all:stacks.view.upcoming.cant_create.title')}
        </span>
        <span className="typo-text text-center">
          {canCreate
            ? t('all:stacks.view.upcoming.empty.subtitle')
            : t('all:stacks.view.upcoming.cant_create.subtitle')}
        </span>
      </StackEventsWrapper>
    );
  }

  return (
    <StackEventsWrapper className="gap-6 px-5 py-2">
      <StackEventsList events={events} stackId={stackId} />
    </StackEventsWrapper>
  );
}
