import { PropsWithChildren } from 'react';

import { PropsWithCN, cn } from '@tg-web/utils';

export function StackEventsWrapper({
  children,
  className,
}: PropsWithCN & PropsWithChildren) {
  return (
    <div className={cn('bg-tg-bg flex h-full flex-col', className)}>
      {children}
    </div>
  );
}
