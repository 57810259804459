import { useEffect, useMemo, useState } from 'react';

import {
  addMonths,
  endOfMonth,
  isSameDay,
  isWithinInterval,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { EmblaCarouselType } from 'embla-carousel/components/EmblaCarousel';
import { EmblaEventType } from 'embla-carousel/components/EventHandler';

import {
  Calendar,
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from '@tg-web/components';
import { useCallbackRef } from '@tg-web/utils';

import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';

export type MonthsCarouselApi = {
  scrollToDate: (day: Date) => void;
};

export type MonthsCarouselProps = {
  daysWithEvents: Date[];
  onChangeMonth?: (day: Date) => void;
  onSelect?: (day: Date) => void;
  selectedDay: Date;
};

export function generateMonths(day: Date) {
  const middleDate = startOfMonth(day);
  return [subMonths(middleDate, 1), middleDate, addMonths(middleDate, 1)];
}

export function MonthsCarousel({
  daysWithEvents,
  onChangeMonth,
  onSelect,
  selectedDay,
}: MonthsCarouselProps) {
  const { userLocale } = useLocalisedDateFormat();

  const [api, setApi] = useState<CarouselApi>();
  const [months, setMonths] = useState(() => generateMonths(selectedDay));
  const onChangeMonthRef = useCallbackRef(onChangeMonth);

  useEffect(() => {
    if (
      isWithinInterval(selectedDay, {
        end: endOfMonth(months[1]),
        start: months[1],
      })
    ) {
      return;
    }

    setMonths(generateMonths(selectedDay));
  }, [selectedDay, months]);

  useEffect(() => {
    if (!api) {
      return;
    }

    api.scrollTo(1, true);
  }, [api]);

  useEffect(() => {
    if (!api) {
      return;
    }

    const handleScroll = (emblaApi: EmblaCarouselType, evt: EmblaEventType) => {
      const selectedScrollSnap = emblaApi.selectedScrollSnap();

      if (selectedScrollSnap === 1) {
        return;
      }

      setTimeout(() => {
        const newCurrentMonth = months[selectedScrollSnap];

        setMonths(generateMonths(newCurrentMonth));
        onChangeMonthRef(newCurrentMonth);

        setTimeout(() => {
          emblaApi.scrollTo(1, true);
        }, 0);
      }, 500);
    };

    api.on('select', handleScroll);

    return () => {
      api.off('select', handleScroll);
    };
  }, [api, months, onChangeMonthRef]);

  const daysWithEventsExceptToday = useMemo(() => {
    return daysWithEvents.filter((it) => !isSameDay(it, selectedDay));
  }, [daysWithEvents, selectedDay]);

  return (
    <Carousel setApi={setApi}>
      <CarouselContent>
        {months.map((month) => (
          <CarouselItem key={month.toString()}>
            <Calendar
              components={{
                MonthCaption: () => <></>,
                Nav: () => <></>,
              }}
              modifiersClassNames={{
                daysWithEvents:
                  "after:content-[''] after:block after:absolute after:bottom-0 after:left-3.5 after:w-1 after:h-1 after:rounded after:bg-tg-destructive-text",
              }}
              captionLayout="label"
              className="w-full px-5 pb-1"
              locale={userLocale}
              mode="single"
              modifiers={{ daysWithEvents: daysWithEventsExceptToday }}
              month={month}
              onSelect={(date) => onSelect?.(date)}
              selected={selectedDay}
              fixedWeeks
              required
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
}
