import * as reactQuery from '@tanstack/react-query';

import {
  UserLoginError,
  UserLoginRequestBody,
  UserLoginResponse,
  UserLoginVariables,
  calendarFetch,
  useCalendarContext,
} from '../../../shared/api';

export const fetchUserLoginWithStatus = (
  variables: UserLoginVariables,
  signal?: AbortSignal,
) =>
  calendarFetch<
    UserLoginResponse,
    UserLoginError,
    UserLoginRequestBody,
    {},
    {},
    {},
    true
  >({
    method: 'post',
    url: '/auth/login',
    ...variables,
    signal,
    withCode: true,
  });

export const useUserLoginWithStatus = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      { data: UserLoginResponse; status: number },
      UserLoginError,
      UserLoginVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useCalendarContext();
  return reactQuery.useMutation<
    { data: UserLoginResponse; status: number },
    UserLoginError,
    UserLoginVariables
  >({
    mutationFn: (variables: UserLoginVariables) =>
      fetchUserLoginWithStatus({ ...fetcherOptions, ...variables }),
    ...options,
  });
};
