import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';
import { format } from 'date-fns';

import { AvatarCircles, Button } from '@tg-web/components';
import { PlusIcon } from '@tg-web/icons';
import { PropsWithCN, cn } from '@tg-web/utils';

import { GoogleCalendar } from '../../../shared/api';
import { DATE_SEARCH_PARAM_FORMAT } from '../../../shared/model/search';
import { TruncateContainer } from '../../../shared/ui/TruncateContainer';
import {
  AttendStatus,
  EventType,
  formatEventTime,
  getEventInterval,
  isFullDayInterval,
} from '../../events';
import { ExtendedFeedEvent } from '../model';
import { EventItem } from './EventItem';

type EventsProps = {
  day: Date;
  events: ExtendedFeedEvent[];
  googleCalendars: GoogleCalendar[];
  isLoading?: boolean;
} & PropsWithCN;

export function Events({
  className,
  day,
  events,
  googleCalendars,
  isLoading,
}: EventsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToCreateEvent = () => {
    navigate({
      search: { date: format(day, DATE_SEARCH_PARAM_FORMAT) },
      to: '/events/new',
    });
  };

  if (isLoading) {
    return <EventItem isLoading />;
  }

  if (events.length === 0) {
    return (
      <EventItem indicatorVariant="secondary" onClick={navigateToCreateEvent}>
        <span className="typo-text text-tg-hint">
          {t('all:feed.no_events')}
        </span>
      </EventItem>
    );
  }

  return (
    <div className={cn('flex min-w-0 flex-col gap-1', className)}>
      {events.map((event) => {
        const { endTime, startTime } = formatEventTime(
          event.attributes.start_at,
          event.attributes.duration,
        );

        const interval = getEventInterval(event);
        const isAllDayEvent = isFullDayInterval(interval);

        const isMaybeAttend =
          event.attributes.attend_status === AttendStatus.maybe;

        const isGoogle = event.attributes.event_type === EventType.GOOGLE;
        return (
          <EventItem
            indicatorColor={
              isGoogle
                ? googleCalendars.find(
                    (it) => it.id === event.attributes.google_calendar_id,
                  )?.attributes.color
                : undefined
            }
            onClick={() => {
              navigate({
                params: { eventId: event.id },
                to: '/events/$eventId/view',
              });
            }}
            indicatorVariant={isGoogle ? 'custom' : 'accent'}
            isGoogle={isGoogle}
            key={event.id}
            variant={isMaybeAttend ? 'maybe' : 'attend'}
          >
            <div className="flex min-w-0 grow flex-col">
              <span className="typo-text-bold">
                {isAllDayEvent
                  ? t('all:feed.event.full_day')
                  : `${startTime} - ${endTime}`}
              </span>
              <TruncateContainer
                bgColor={isMaybeAttend ? 'secondary-bg' : 'event-bg'}
              >
                {event.titlePrefix && (
                  <span className="typo-text text-tg-hint">
                    {event.titlePrefix}{' '}
                  </span>
                )}
                <span className="typo-text">{event.attributes.title}</span>
              </TruncateContainer>
            </div>
            {event.attributes.attendees_count > 1 && (
              <AvatarCircles
                avatarUrls={event.attributes.attendees_photo_urls.filter(
                  (it) => it !== null,
                )}
                className="shrink-0"
                numOfAvatars={3}
                numPeople={event.attributes.attendees_count}
              />
            )}
          </EventItem>
        );
      })}
      <EventItem indicatorVariant="secondary" onClick={navigateToCreateEvent}>
        <div className="flex grow flex-row justify-between">
          <span className="typo-text text-tg-hint">
            {t('all:feed.add_more')}
          </span>
          <Button
            className="bg-tg-secondary-bg text-tg-section-separator h-[22px] w-[22px] rounded-full p-0"
            variant="ghost"
          >
            <PlusIcon />
          </Button>
        </div>
      </EventItem>
    </div>
  );
}
