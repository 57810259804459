import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, useToast } from '@tg-web/components';
import { CheckmarkIcon, HeartIcon } from '@tg-web/icons';

import { Stack, useDeleteStackSubscription } from '../../../shared/api';
import { isUserSubscribedToStack, useStackSubscription } from '../lib';

export interface StackSubscriptionButtonProps {
  onSuccess: VoidFunction;
  stack: Stack;
}

export function StackSubscriptionButton({
  onSuccess,
  stack,
}: StackSubscriptionButtonProps) {
  const { t } = useTranslation();
  const toast = useToast();

  const unsubscriptionReq = useDeleteStackSubscription();
  const { subscribe, subscriptionReq } = useStackSubscription({ onSuccess });

  const unsubscribe = () => {
    unsubscriptionReq.mutate({ pathParams: { stackId: stack.id } });
  };

  useEffect(() => {
    if (unsubscriptionReq.isSuccess) {
      onSuccess();
      toast.toast({
        description: t('all:stacks.view.unsubscribed_toast'),
      });
    }
  }, [unsubscriptionReq.isSuccess]);

  const isSubscribed = isUserSubscribedToStack(stack.role);

  return isSubscribed ? (
    <Button
      disabled={unsubscriptionReq.isPending}
      onClick={unsubscribe}
      size="tiny"
      variant="white"
    >
      <CheckmarkIcon height={24} width={24} />
      {t('all:stacks.view.buttons.unsubscribe')}
    </Button>
  ) : (
    <Button
      disabled={subscriptionReq.isPending}
      onClick={() => subscribe(stack.id)}
      size="tiny"
      variant="primary"
    >
      <HeartIcon height={24} width={24} />
      {t('all:stacks.view.buttons.subscribe')}
    </Button>
  );
}
