import { createFileRoute } from '@tanstack/react-router';

import { EventParticipantsPage } from '../../../../pages/events/ui/EventParticipantsPage';

export const Route = createFileRoute(
  '/_authorized/events/$eventId/participants',
)({
  component: Page,
});

function Page() {
  const { eventId } = Route.useParams();

  return <EventParticipantsPage eventId={eventId} />;
}
