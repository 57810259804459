import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Form,
  FormControl,
  FormField,
  Input,
  RadioGroup,
  RadioGroupItem,
  Sheet,
  SheetContent,
} from '@tg-web/components';
import { GlobeIcon } from '@tg-web/icons';

import { formatTimeZone } from '../../../shared/lib/formatTimeZone';
import { useMainButton } from '../../../shared/lib/useMainButton';
import { ViewField } from '../../../shared/ui/ViewField';

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezones: Array<{
  area: string;
  city: string;
  offset: string;
  value: string;
}> = [];
Intl.supportedValuesOf('timeZone').forEach((timeZone) => {
  const [area] = timeZone.split('/');
  const { city, offset } = formatTimeZone(timeZone);
  const result = {
    area,
    city,
    offset,
    value: timeZone,
  };
  if (!city) {
    return;
  }
  if (timeZone === currentTimeZone) {
    timezones.unshift(result);
  } else {
    timezones.push(result);
  }
});

const formSchema = z.object({
  query: z.string(),
  value: z.string(),
});
type FormType = z.infer<typeof formSchema>;

export type TimezonesModalProps = {
  initial: string;
  onClose: () => void;
  onSave: (value: string) => void;
  open: boolean;
};

export function TimezonesModal({
  initial,
  onClose,
  onSave,
  open = false,
}: TimezonesModalProps) {
  const { t } = useTranslation();
  const form = useForm<FormType>({
    defaultValues: {
      query: '',
      value: initial,
    },
    resolver: zodResolver(formSchema),
  });
  const query = form.watch('query');

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  const handleSubmit = (data: FormType) => {
    onSave(data.value);
    onClose();
  };

  const filteredTimezones = timezones.filter(
    ({ city, value }) =>
      city &&
      (city.toLowerCase().includes(query.toLowerCase()) ||
        value === currentTimeZone),
  );

  useMainButton({
    isHidden: !open,
    onClick: form.handleSubmit(handleSubmit),
    text: t('all:common.buttons.save'),
  });

  useEffect(() => {
    if (open) {
      form.reset({ query: '', value: initial });
    }
  }, [open]);

  return (
    <Sheet onOpenChange={handleOpenChange} open={open}>
      <SheetContent
        className="bg-tg-secondary-bg h-full w-full overflow-y-scroll border-none pb-0"
        onOpenAutoFocus={(event) => event.preventDefault()}
        side="right"
      >
        <Form {...form}>
          <form
            className="flex h-full flex-col"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <FormField
              render={({ field }) => (
                <FormControl>
                  <Input
                    {...field}
                    leftIcon={<GlobeIcon className="text-tg-hint" />}
                    placeholder={t('all:event.timezone.placeholder')}
                  />
                </FormControl>
              )}
              control={form.control}
              name="query"
            />
            {filteredTimezones.length > 0 && (
              <FormField
                render={({ field }) => (
                  <FormControl>
                    <RadioGroup
                      className="no-scrollbar -mb-6 mt-6 overflow-y-scroll rounded-2xl"
                      defaultValue={field.value}
                      onValueChange={field.onChange}
                    >
                      {filteredTimezones.map(
                        ({ area, city, offset, value }) => (
                          <ViewField
                            as="label"
                            className="min-h-[68px] cursor-pointer py-3 pl-3 pr-5"
                            htmlFor={value}
                            innerClassName="flex items-center justify-between w-full"
                            key={value}
                          >
                            <div>
                              <p>
                                {city}{' '}
                                {value ===
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                                  ? `(${t('all:event.timezone.your_timezone')})`
                                  : offset}
                              </p>
                              <p className="text-tg-hint mt-0.5">{area}</p>
                            </div>
                            <RadioGroupItem id={value} value={value} />
                          </ViewField>
                        ),
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
                control={form.control}
                name="value"
              />
            )}
            {filteredTimezones.length === 1 && (
              <div className="grow content-center text-center">
                {t('all:event.timezone.not_found')}
              </div>
            )}
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
