import { useTranslation } from 'react-i18next';

import { FormatDistanceFn } from 'date-fns';

export function useFormatDateDistanceShort() {
  const { t } = useTranslation();

  const formatDateDistanceShort: FormatDistanceFn = (token, count) =>
    t(`all:common.date_distance.${token}`, { count });

  return { formatDateDistanceShort };
}
