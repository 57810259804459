import type { QueryKey } from '@tanstack/query-core';

import * as reactQuery from '@tanstack/react-query';
import { InfiniteData } from '@tanstack/react-query';

import {
  ListEventError,
  ListEventResponse,
  ListEventVariables,
  fetchListEvent,
  useCalendarContext,
} from '../generated';

export const useInfiniteListEvent = <TData = ListEventResponse>(
  variables: ListEventVariables,
  options?: Omit<
    reactQuery.DefinedInitialDataInfiniteOptions<
      ListEventResponse,
      ListEventError,
      InfiniteData<TData>,
      QueryKey,
      ListEventVariables
    >,
    'initialData' | 'queryFn' | 'queryKey'
  >,
) => {
  const { fetcherOptions, queryKeyFn, queryOptions } = useCalendarContext();

  return reactQuery.useInfiniteQuery<
    ListEventResponse,
    ListEventError,
    InfiniteData<TData>,
    QueryKey,
    ListEventVariables
  >({
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      return lastPageParam;
    },
    initialPageParam: variables,
    queryFn: ({ pageParam, signal }) =>
      fetchListEvent({ ...fetcherOptions, ...pageParam }, signal),
    queryKey: queryKeyFn({
      operationId: 'listEvent',
      path: '/events',
      variables,
    }),
    ...options,
    ...queryOptions,
  });
};
