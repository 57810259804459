import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';

import { AvatarCircles, Button, useToast } from '@tg-web/components';
import {
  LocationIcon,
  PlusCircleIcon,
  ShareSecondaryIcon,
} from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { StackEvent } from '../../../shared/api';
import { LinkifyWrapper } from '../../../shared/ui/LinkifyWrapper';
import {
  AttendStatus,
  AttendStatusesRequiringOverlap,
  OverlapEvents,
  useAttendEventHandler,
  useFormatEventTime,
  useShareEvent,
} from '../../events';
import { useStackEventSubscriptionsCount } from '../lib';

export interface StackEventCardProps {
  event: StackEvent;
  stackId?: string;
}

export function StackEventCard({ event, stackId }: StackEventCardProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const { formatEventTime } = useFormatEventTime();
  const { getSubscriptionsCountAsText } = useStackEventSubscriptionsCount();
  const { shareEvent } = useShareEvent();
  const overlapModal = useDisclosure();
  const [handleClick, { isPending }] = useAttendEventHandler(
    event,
    AttendStatus.attend,
    () => {
      queryClient.invalidateQueries({
        queryKey: ['stacks', stackId, 'events'],
      });
      toast.toast({
        description: t('all:stacks.event.join'),
      });
    },
  );

  const handleJoinButtonClick = () => {
    if (AttendStatusesRequiringOverlap.includes(event.attend_status)) {
      overlapModal.onOpen();
    } else {
      handleClick();
    }
  };

  return (
    <>
      <Link params={{ eventId: event.id }} to="/events/$eventId/view">
        <div className="border-03 border-tg-section-separator flex cursor-pointer flex-col rounded-2xl p-4 pb-2">
          <span className="typo-text-bold">{formatEventTime(event)}</span>
          <span className="typo-text mt-1">{event.title}</span>
          {(event.location || event.video_link) && (
            <div className="mt-1 flex gap-1 py-1">
              <LocationIcon
                className="text-tg-hint shrink-0"
                height={20}
                width={20}
              />

              <span className="typo-text overflow-hidden text-ellipsis whitespace-nowrap">
                <LinkifyWrapper>
                  {event.location || event.video_link}
                </LinkifyWrapper>
              </span>
            </div>
          )}
          <div className="flex items-center justify-between py-2">
            {getSubscriptionsCountAsText(
              event.attend_status === AttendStatus.attend,
              event.attendees_count ?? 0,
            )}
            {!!event.attendees_count &&
              event.attendees_count > 1 &&
              event.attendees_photo_urls && (
                <AvatarCircles
                  avatarUrls={event.attendees_photo_urls.filter(
                    (it) => it !== null,
                  )}
                  numOfAvatars={3}
                  numPeople={event.attendees_count}
                />
              )}
            {stackId && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                {event.attend_status === AttendStatus.attend ? (
                  <Button
                    onClick={() => shareEvent(event)}
                    size="tiny"
                    variant="secondary"
                  >
                    <ShareSecondaryIcon height={24} width={24} />
                    {t('all:common.buttons.share')}
                  </Button>
                ) : (
                  <Button
                    disabled={isPending}
                    onClick={handleJoinButtonClick}
                    size="tiny"
                    variant="secondary"
                  >
                    <PlusCircleIcon height={24} width={24} />
                    {t('all:common.buttons.join')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Link>
      <OverlapEvents
        duration={event.duration.toString()}
        enabled={overlapModal.open}
        onCancel={overlapModal.onClose}
        onContinue={handleClick}
        start_at={event.start_at}
      />
    </>
  );
}
