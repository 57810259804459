import { z } from 'zod';

export const GoogleSuccessResponseSchema = z.object({
  authuser: z.number(),
  code: z.string(),
  prompt: z.string(),
  scope: z.string(),
  state: z.string(),
});

export const GoogleErrorResponseSchema = z.object({
  error: z.string(),
  state: z.string(),
});
