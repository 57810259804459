import { format } from 'date-fns';

import { Event } from '../../../shared/api';
import { DATE_SEARCH_PARAM_FORMAT } from '../../../shared/model/search';

export function getFeedSearch(event?: Event) {
  return event
    ? {
        date: format(event.attributes.start_at, DATE_SEARCH_PARAM_FORMAT),
      }
    : undefined;
}
