import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '@tg-web/components';

import { Stack, useCreateStackSubscription } from '../../../shared/api';

export interface useStackSubscriptionParams {
  onSuccess: VoidFunction;
}

export function useStackSubscription({
  onSuccess,
}: useStackSubscriptionParams) {
  const { t } = useTranslation();
  const toast = useToast();
  const subscriptionReq = useCreateStackSubscription();
  const subscribe = (stackId: Stack['id']) => {
    subscriptionReq.mutate({ pathParams: { stackId } });
  };

  useEffect(() => {
    if (subscriptionReq.isSuccess) {
      onSuccess();
      toast.toast({
        description: t('all:stacks.view.subscribed_toast'),
      });
    }
  }, [subscriptionReq.isSuccess]);

  return { subscribe, subscriptionReq };
}
