import * as React from 'react';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { CheckIcon } from '@tg-web/icons';
import { cn } from '@tg-web/utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    className={cn(
      'border-tg-button peer h-6 w-6 shrink-0 rounded-md border',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'focus-visible:outline-none focus-visible:ring-1',
      'data-[state=checked]:bg-tg-button data-[state=checked]:text-tg-button-text',
      className,
    )}
    ref={ref}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex items-center justify-center text-current')}
    >
      <CheckIcon className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
