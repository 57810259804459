import React from 'react';

import { IconProps } from './types';

export const CancelIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12 12L20 20"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="M20 12L12 20"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle cx="16" cy="16" r="9" stroke={color} strokeWidth="2" />
      </svg>
    );
  },
);
