import { Outlet, createFileRoute, useMatchRoute } from '@tanstack/react-router';

import { MainTabs } from '../../widgets/layout/ui/MainTabs';

export const Route = createFileRoute('/_authorized/_withTabs')({
  component: Component,
});

function Component() {
  const matchRoute = useMatchRoute();
  const initialRoute = matchRoute({ to: '/stacks' }) ? '/stacks' : '/feed';

  return (
    <>
      <Outlet />
      <MainTabs initialRoute={initialRoute} />
    </>
  );
}
