import React from 'react';

import { IconProps } from './types';

export const PointingArrowIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="105"
        viewBox="0 0 14 105"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.3623 2C7.52793 19.5384 5.57647 38.5441 5.76257 56.6763C5.89161 69.2494 6.83652 81.8242 7.15187 94.3979C7.17705 95.4017 8.90011 107.949 6.81581 101.122C5.6229 97.2149 0.416568 84.5699 2.81156 96.0866C2.99582 96.9727 4.38001 105.614 5.85933 101.817C7.48935 97.633 9.19166 92.9734 11.184 89.9989"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="3"
        />
      </svg>
    );
  },
);
