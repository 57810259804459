import { getWebApp } from '@tg-web/utils';

export function useSafeAreaInset() {
  const inset = getWebApp().safeAreaInset ?? {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  };
  const bottom = inset.bottom >= getWebApp().viewportHeight ? 0 : inset.bottom;

  return {
    ...inset,
    bottom,
  };
}
