import { getWebApp } from '@tg-web/utils';

import { getTgTheme } from '../model/defaultTheme';

export const setAppBackground = (
  bgColor: Parameters<WebApp['setBackgroundColor']>[0],
  headerColor?: Parameters<WebApp['setHeaderColor']>[0],
) => {
  // set style of body to fix bg bug in web and desktop telegram
  if (bgColor === 'bg_color') {
    document.body.style.backgroundColor = getTgTheme().bg_color;
  } else if (bgColor === 'secondary_bg_color') {
    document.body.style.backgroundColor = getTgTheme().secondary_bg_color;
  } else {
    document.body.style.backgroundColor = bgColor;
  }

  getWebApp().setBackgroundColor(bgColor);
  getWebApp().setHeaderColor(headerColor ?? bgColor);
};
