import { createFileRoute } from '@tanstack/react-router';

import { EditEventPage } from '../../../../pages/events/ui/EditEventPage';

export const Route = createFileRoute('/_authorized/events/$eventId/edit')({
  component: Page,
});

function Page() {
  const { eventId } = Route.useParams();

  return <EditEventPage eventId={eventId} />;
}
