import { TZDate } from '@date-fns/tz';
import { replace } from 'ramda';

export function formatTimeZone(timezone: string) {
  const city = timezone.split('/')[1];
  const timezoneDate = TZDate.tz(timezone).toISOString();
  const splitter = timezoneDate.includes('+') ? '+' : '-';
  return {
    city: city ? city.split('_').join(' ') : '',
    offset: `(GMT${splitter}${replace('0', '', timezoneDate.split(splitter)[1].slice(0, 2))})`,
  };
}
