import React from 'react';

import { IconProps } from './types';

export const TitleIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.3646 27C10.2396 26.8229 8.11979 26.4896 6 26C5.51042 23.8802 5.17708 21.7604 5 19.6354M19.6354 5C21.7604 5.17708 23.8802 5.51042 26 6C26.4896 8.11979 26.8229 10.2396 27 12.3646M5 12.3646C5.17708 10.2396 5.51042 8.11979 6 6C8.11979 5.51042 10.2396 5.17708 12.3646 5M27 19.6354C26.8229 21.7604 26.4896 23.8802 26 26C23.8802 26.4896 21.7604 26.8229 19.6354 27"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M11 22C11.5 14.1562 14 10 16 10C18 10 20.5 14.1562 21 22"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M20.5834 19.599C17.5261 18 14.474 18 11.4167 19.599"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
