export enum NotificationDuration {
  atTime = 1,
  day = 1440,
  halfHour = 30,
  hour = 60,
  hours3 = 180,
  noNotify = 0,
}

export const NotificationDurationArray = [
  NotificationDuration.noNotify,
  NotificationDuration.atTime,
  NotificationDuration.halfHour,
  NotificationDuration.hour,
  NotificationDuration.hours3,
  NotificationDuration.day,
] as const;

export const NotificationDurationLabels = {
  [NotificationDuration.atTime]:
    'all:event.notifications.duration_labels.atTime',
  [NotificationDuration.day]: 'all:event.notifications.duration_labels.day',
  [NotificationDuration.halfHour]:
    'all:event.notifications.duration_labels.halfHour',
  [NotificationDuration.hour]: 'all:event.notifications.duration_labels.hour',
  [NotificationDuration.hours3]:
    'all:event.notifications.duration_labels.hours3',
  [NotificationDuration.noNotify]:
    'all:event.notifications.duration_labels.noNotify',
} as const;

export const NotificationDurationDescriptions = {
  [NotificationDuration.atTime]:
    'all:event.notifications.duration_descriptions.atTime',
  [NotificationDuration.day]:
    'all:event.notifications.duration_descriptions.day',
  [NotificationDuration.halfHour]:
    'all:event.notifications.duration_descriptions.halfHour',
  [NotificationDuration.hour]:
    'all:event.notifications.duration_descriptions.hour',
  [NotificationDuration.hours3]:
    'all:event.notifications.duration_descriptions.hours3',
  [NotificationDuration.noNotify]:
    'all:event.notifications.duration_descriptions.noNotify',
} as const;
