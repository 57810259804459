import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { ShareSecondaryIcon } from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { Stack } from '../../../shared/api';
import { ShareStackModal } from './ShareStackModal';

export interface StackCardProps {
  stack: Stack;
}

export function StackCard({ stack }: StackCardProps) {
  const { t } = useTranslation();
  const { onClose, onOpen, open } = useDisclosure();
  const handleShareButtonClick: MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onOpen();
  };

  return (
    <>
      <Link params={{ stackId: stack.id }} to="/stacks/$stackId/view">
        <div className="bg-tg-section-bg flex flex-col gap-4 rounded-2xl px-4 py-3">
          <div className="flex gap-2">
            <span className="text-[20px] leading-none">{stack.emoji}</span>
            <span className="typo-text-bold">{stack.title}</span>
          </div>
          <div className="flex items-center justify-start gap-2">
            <span className="typo-feed text-tg-hint">
              {t('all:stacks.card.subscribers', {
                count: stack.subscriptions_count,
              })}
            </span>
            <Button
              className="ml-auto"
              onClick={handleShareButtonClick}
              size="tiny"
              type="button"
              variant="secondary"
            >
              <ShareSecondaryIcon height={24} width={24} />
              {t('all:common.buttons.share')}
            </Button>
          </div>
        </div>
      </Link>
      <ShareStackModal onOpenChange={onClose} open={open} stackId={stack.id} />
    </>
  );
}
