import { useTranslation } from 'react-i18next';

import {
  Button,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@tg-web/components';
import { getWebApp } from '@tg-web/utils';

export type InviteInstructionModalProps = {
  isOpen?: boolean;
  onClose: VoidFunction;
};
export function InviteInstructionModal({
  isOpen,
  onClose,
}: InviteInstructionModalProps) {
  const { t } = useTranslation();

  return (
    <Sheet onOpenChange={onClose} open={isOpen}>
      <SheetContent
        className="flex w-full flex-col gap-0 overflow-y-scroll"
        side="bottom"
      >
        <img
          src={
            getWebApp().colorScheme === 'light'
              ? '/images/event_with_invite_light.webp?v=1'
              : '/images/event_with_invite_dark.webp?v=1'
          }
          alt="Event with invite"
        />
        <SheetHeader>
          <SheetTitle>{t('all:event.invite_modal.title')}</SheetTitle>
        </SheetHeader>
        <p className="typo-text mt-3">{t('all:event.invite_modal.subtitle')}</p>
        <Button
          className="mt-8"
          onClick={onClose}
          type="button"
          variant="primary"
        >
          {t('all:common.buttons.got_it')}
        </Button>
      </SheetContent>
    </Sheet>
  );
}
