import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmojiPicker, {
  Categories,
  EmojiStyle,
  PickerProps,
  Theme,
} from 'emoji-picker-react';

import { getWebApp } from '@tg-web/utils';

export function ConfiguredEmojiPicker(props: PickerProps) {
  const { i18n, t } = useTranslation();

  const categories = useMemo<PickerProps['categories']>(() => {
    return [
      {
        category: Categories.SMILEYS_PEOPLE,
        name: t('all:common.emoji.category.smileys_people'),
      },
      {
        category: Categories.ANIMALS_NATURE,
        name: t('all:common.emoji.category.animals_nature'),
      },
      {
        category: Categories.FOOD_DRINK,
        name: t('all:common.emoji.category.food_drink'),
      },
      {
        category: Categories.TRAVEL_PLACES,
        name: t('all:common.emoji.category.travel_places'),
      },
      {
        category: Categories.ACTIVITIES,
        name: t('all:common.emoji.category.activities'),
      },
      {
        category: Categories.OBJECTS,
        name: t('all:common.emoji.category.objects'),
      },
      {
        category: Categories.SYMBOLS,
        name: t('all:common.emoji.category.symbols'),
      },
      {
        category: Categories.FLAGS,
        name: t('all:common.emoji.category.flags'),
      },
    ];
  }, [t]);

  const searchDisabled = i18n.language !== 'en'; // search works only for english words

  return (
    <EmojiPicker
      previewConfig={{
        showPreview: false,
      }}
      autoFocusSearch={false}
      categories={categories}
      emojiStyle={EmojiStyle.NATIVE}
      height={searchDisabled ? 300 : 400}
      searchDisabled={searchDisabled}
      searchPlaceHolder={t('all:common.emoji.search')}
      theme={getWebApp().colorScheme === 'dark' ? Theme.DARK : Theme.LIGHT}
      skinTonesDisabled
      {...props}
    />
  );
}
