import React from 'react';

import { IconProps } from './types';

export const PlusCircleIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="40"
        viewBox="0 0 40 40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M20 3.75C11.0402 3.75 3.75 11.0402 3.75 20C3.75 28.9598 11.0402 36.25 20 36.25C28.9598 36.25 36.25 28.9598 36.25 20C36.25 11.0402 28.9598 3.75 20 3.75ZM20 6.25C27.6087 6.25 33.75 12.3913 33.75 20C33.75 27.6087 27.6087 33.75 20 33.75C12.3913 33.75 6.25 27.6087 6.25 20C6.25 12.3913 12.3913 6.25 20 6.25ZM19.9805 12.4829C19.6494 12.4881 19.3339 12.6244 19.1032 12.862C18.8725 13.0995 18.7455 13.4189 18.75 13.75V18.75H13.75C13.5844 18.7477 13.4199 18.7783 13.2662 18.84C13.1125 18.9018 12.9726 18.9935 12.8546 19.1098C12.7367 19.2261 12.643 19.3647 12.5791 19.5175C12.5151 19.6703 12.4822 19.8343 12.4822 20C12.4822 20.1657 12.5151 20.3297 12.5791 20.4825C12.643 20.6353 12.7367 20.7739 12.8546 20.8902C12.9726 21.0065 13.1125 21.0982 13.2662 21.16C13.4199 21.2217 13.5844 21.2523 13.75 21.25H18.75V26.25C18.7477 26.4156 18.7783 26.5801 18.84 26.7338C18.9018 26.8875 18.9935 27.0274 19.1098 27.1454C19.2261 27.2633 19.3647 27.357 19.5175 27.4209C19.6703 27.4849 19.8343 27.5178 20 27.5178C20.1657 27.5178 20.3297 27.4849 20.4825 27.4209C20.6353 27.357 20.7739 27.2633 20.8902 27.1454C21.0065 27.0274 21.0982 26.8875 21.16 26.7338C21.2217 26.5801 21.2523 26.4156 21.25 26.25V21.25H26.25C26.4156 21.2523 26.5801 21.2217 26.7338 21.16C26.8875 21.0982 27.0274 21.0065 27.1454 20.8902C27.2633 20.7739 27.357 20.6353 27.4209 20.4825C27.4849 20.3297 27.5178 20.1657 27.5178 20C27.5178 19.8343 27.4849 19.6703 27.4209 19.5175C27.357 19.3647 27.2633 19.2261 27.1454 19.1098C27.0274 18.9935 26.8875 18.9018 26.7338 18.84C26.5801 18.7783 26.4156 18.7477 26.25 18.75H21.25V13.75C21.2523 13.5828 21.221 13.4168 21.158 13.2618C21.095 13.1069 21.0015 12.9662 20.8831 12.8481C20.7647 12.7299 20.6238 12.6367 20.4688 12.574C20.3138 12.5113 20.1477 12.4803 19.9805 12.4829Z"
          fill={color}
        />
      </svg>
    );
  },
);
