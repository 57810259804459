import { isToday } from 'date-fns';

import { PropsWithCN, cn } from '@tg-web/utils';

import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';

type DayBadgeProps = { day: Date } & PropsWithCN;

export function DayBadge({ className, day }: DayBadgeProps) {
  const { format } = useLocalisedDateFormat();
  return (
    <div
      className={cn(
        'flex h-[26px] w-[32px] cursor-default select-none flex-col text-center',
        isToday(day) && 'text-tg-accent-text',
        className,
      )}
    >
      <span className="typo-header-small">{format(day, 'dd')}</span>
      <span className="typo-small uppercase">{format(day, 'E')}</span>
    </div>
  );
}
