import React from 'react';

import { IconProps } from './types';

export const UsersIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M15 26C11.4219 27.6615 6.46354 27.2292 4 26C3.1875 25.5938 2 24.4896 2 23.5C2 21.4271 6.52604 20 9.42188 20C12.3229 20 16.8698 21.4271 16.8698 23.5C16.8698 24.4844 15.8177 25.6198 15 26Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M14 12C14 14.2083 12.2083 16 10 16C7.79167 16 6 14.2083 6 12C6 9.79167 7.79167 8 10 8C12.2083 8 14 9.79167 14 12Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M26 10C26 12.2083 24.2083 14 22 14C19.7917 14 18 12.2083 18 10C18 7.79167 19.7917 6 22 6C24.2083 6 26 7.79167 26 10Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M21.5573 18C18.9115 18 14.9167 19.1875 14.2396 20.974C15.7396 21.5885 16.8698 22.4635 16.8698 23.5C16.8698 23.7865 16.8125 24.0833 16.7136 24.3646C19.573 25.2187 23.9063 25.4375 27 24C27.8177 23.6198 29 22.4844 29 21.5C29 19.4271 24.4532 18 21.5573 18Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
