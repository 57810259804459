import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { NewsIcon, PointingArrowIcon } from '@tg-web/icons';

import { FeedHeader } from '../../../features/feed';
import { useUserProfileConfigured } from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function EmptyFeed() {
  const { t } = useTranslation();

  const userProfile = useUserProfileConfigured();

  if (userProfile.isPending) {
    return <GlobalLoading />;
  }

  if (userProfile.isError) {
    throw new Error('Unexpected API error');
  }

  return (
    <PageWrapper className="flex flex-col">
      <FeedHeader currentDay={new Date()} user={userProfile.data.user.data} />
      <div className="flex grow flex-col items-center justify-around">
        <div className="flex max-w-[270px] flex-col items-center text-center">
          <NewsIcon className="mb-4" />
          <h1 className="typo-header-biggest mb-3">
            {t('all:feed.empty.title')}
          </h1>
          <h1 className="typo-text mb-14">{t('all:feed.empty.subtitle')}</h1>
          <PointingArrowIcon className="text-tg-accent-text" />
        </div>
      </div>
      <div className="fixed bottom-8 flex w-full justify-center">
        <Button size="small" variant="hover" asChild>
          <Link to="/events/new">{t('all:feed.new_event')}</Link>
        </Button>
      </div>
    </PageWrapper>
  );
}
