import { getWebApp } from '@tg-web/utils';

export function setCloudStorageItem(key: string, value: string) {
  return new Promise<null | string>((resolve, reject) => {
    getWebApp().CloudStorage.setItem(key, value, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve(value);
      }
    });
  });
}
