import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

type AdditionalClassGroupIds = 'typo';
type AdditionalThemeGroupIds = never;

const customTwMerge = extendTailwindMerge<
  AdditionalClassGroupIds,
  AdditionalThemeGroupIds
>({
  extend: {
    classGroups: {
      typo: [
        {
          typo: [
            'feed',
            'feed-bold',
            'header-big',
            'header-biggest',
            'header-small',
            'small',
            'text',
            'text-bold',
          ],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
