import React from 'react';

import { PropsWithCN, cn } from '@tg-web/utils';

export interface DotsProps extends PropsWithCN {
  activeIndex: number;
  count: number;
}

export function Dots({ activeIndex, className, count }: DotsProps) {
  return (
    <div className={cn('flex justify-center gap-1.5', className)}>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <div
            className={cn('border-tg-button h-3 w-3 rounded-full border', {
              'bg-tg-button': index === activeIndex,
            })}
            key={index}
          />
        ))}
    </div>
  );
}
