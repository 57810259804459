import React from 'react';

import { IconProps } from './types';

export const QuestionMarkIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M16.286 1C11.7599 1 8.4891 4.94792 8.07764 8.73438C7.8641 10.6927 9.81722 11.8229 11.2756 10.1771C13.1141 8.09896 13.9474 7.5 15.2391 7.5C16.6662 7.5 18.4943 9.35417 16.9995 11.8854C15.2912 14.776 11.9995 16.7083 11.9995 19.3333C11.9995 20.9375 13.687 21.7292 16.2703 20.1667C20.3172 17.7292 23.9995 13.9635 23.9995 9.3125C23.9995 4.98958 21.9266 1 16.286 1Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M18 28C18 29.6562 16.6562 31 15 31C13.3438 31 12 29.6562 12 28C12 26.3438 13.3438 25 15 25C16.6562 25 18 26.3438 18 28Z"
          stroke={color}
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
