import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';

export type EventDateHeaderProps = {
  date: Date;
  secondDate?: Date;
  short?: boolean;
};

export function EventDateHeader({
  date,
  secondDate,
  short,
}: EventDateHeaderProps) {
  const { format } = useLocalisedDateFormat();

  return (
    <div className="flex flex-col items-center">
      <h1 className="typo-header-big text-center">
        {format(date, `dd ${short ? 'MMM' : 'MMMM'}`)}
      </h1>
      <p className="typo-text">
        {format(date, 'HH:mm')}
        {secondDate && ` - ${format(secondDate, 'HH:mm')}`}
      </p>
    </div>
  );
}
