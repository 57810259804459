import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useStackEventSubscriptionsCount() {
  const { t } = useTranslation();
  const messages = useMemo(
    () =>
      new Map<string, (count: number) => string>([
        ['0', () => t('all:stacks.event.no_guests')],
        ['1-false', (count) => t('all:stacks.event.participants', { count })],
        ['1-true', () => t('all:stacks.event.only_you')],
        [
          'many-false',
          (count) => t('all:stacks.event.participants', { count }),
        ],
        [
          'many-true',
          (count) => t('all:stacks.event.you_and_other', { count: count - 1 }),
        ],
      ]),
    [],
  );

  const getSubscriptionsCountAsText = (
    currentUserJoined: boolean,
    count: number,
  ) => {
    const key =
      count === 0 ? '0' : `${count > 1 ? 'many' : count}-${currentUserJoined}`;
    return messages.get(key)?.(count);
  };
  return {
    getSubscriptionsCountAsText,
  };
}
