import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { has } from 'ramda';

import { ListEventResponse, ListEventVariables } from '../../../shared/api';

type FeedQueryData = InfiniteData<ListEventResponse, ListEventVariables>;
type FeedUpdaterFunction = (
  oldValue: FeedQueryData,
) => FeedQueryData | undefined;

export function useFeedOptimisticUpdater() {
  const queryClient = useQueryClient();

  return (updateFn: FeedUpdaterFunction) => {
    const queryData = queryClient.getQueriesData<FeedQueryData>({
      predicate: (v) =>
        has('from', v.queryKey?.[1]) && has('to', v.queryKey?.[1]),
      queryKey: ['events'],
    });

    if (queryData.length === 0 || !queryData[0][1]) {
      return;
    }

    const updatedData = updateFn(queryData[0][1]);
    if (updatedData) {
      queryClient.setQueryData<InfiniteData<ListEventResponse>>(
        queryData[0][0],
        updatedData,
      );
    }
  };
}
