import { createFileRoute } from '@tanstack/react-router';

import { NewStackPage } from '../../../pages/stacks/ui/NewStackPage';

export const Route = createFileRoute('/_authorized/stacks/new')({
  component: Page,
});

function Page() {
  return <NewStackPage />;
}
