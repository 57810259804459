import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { Button } from '@tg-web/components';

import { CalendarsSynchronizationForm } from '../../../features/google';
import { useGoogleCalendarAccountConfigured } from '../../../shared/api';
import { setCloudStorageItem } from '../../../shared/lib/setCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

const FORM_ID = 'googleCalendarOnboardingForm';

export function GoogleCalendarOnboardingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isFormPending, setIsFormRending] = useState(false);

  const { googleAccount, googleAccountsList } =
    useGoogleCalendarAccountConfigured();

  if (googleAccountsList.isPending || googleAccount.isPending) {
    return <GlobalLoading />;
  }

  if (googleAccountsList.isError || googleAccount.isError) {
    throw new Error('Unexpected API error');
  }

  return (
    <PageWrapper className="flex flex-col items-center p-4">
      <div className="grow" />
      <img
        alt="Google calendar icon"
        className="h-20 w-20"
        src="/images/gcal_icon.webp"
      />
      <h1 className="typo-header-biggest mt-6 text-center">
        {t('all:google.calendar_onboarding.title')}
      </h1>
      <div className="mt-9 w-full">
        <CalendarsSynchronizationForm
          googleCalendars={googleAccount.data.google_calendars.data.map(
            (it) => ({
              ...it,
              attributes: { ...it.attributes, active: true },
            }),
          )}
          onSuccess={async () => {
            await setCloudStorageItem(
              CloudStorageKeys.GOOGLE_CALENDAR_ONBOARDING_PASSED,
              'true',
            );

            navigate({ to: '/feed' });
          }}
          formId={FORM_ID}
          googleAccountId={googleAccountsList.data.google_accounts.data[0].id}
          onIsPendingChange={setIsFormRending}
          hideCaptions
        />
      </div>
      <Button
        className="mt-4"
        form={FORM_ID}
        isLoading={isFormPending}
        type="submit"
        variant="primary"
      >
        {t('all:common.buttons.save')}
      </Button>
      <div className="grow" />
      <div className="grow" />
    </PageWrapper>
  );
}
