import { useTranslation } from 'react-i18next';

import { NotificationIcon } from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import { NotificationModal } from '../../notifications';
import { useEventNotification } from '../lib';
import { EventActionButton } from './EventActionButton';

export interface NotificationEventButtonProps {
  event: Event;
  onSuccess?: () => void;
}

export function NotificationEventButton({
  event,
  onSuccess,
}: NotificationEventButtonProps) {
  const { t } = useTranslation();
  const notificationModal = useDisclosure();

  const { handleNotificationSave, isRecurringEvent } = useEventNotification({
    event,
    onClose: notificationModal.onClose,
    onSuccess,
  });

  return (
    <>
      <EventActionButton
        icon={<NotificationIcon />}
        onClick={notificationModal.onOpen}
        text={t('all:common.buttons.notification')}
      />
      <NotificationModal
        title={
          isRecurringEvent
            ? t('all:event.notifications.recurrent.title')
            : t('all:event.notifications.once.title')
        }
        initial={event.attributes.notify_before}
        isRecurringEvent={isRecurringEvent}
        onClose={notificationModal.onClose}
        onSave={handleNotificationSave}
        open={notificationModal.open}
      />
    </>
  );
}
