import React from 'react';

import { IconProps } from './types';

export const WatchesIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M23.9793 20.1406C23.4481 23.3698 22.4325 26.2083 21.7918 27.7708C21.4325 28.6406 20.6981 29.3021 19.7918 29.5312C18.8804 29.7708 17.5679 30 16.0002 30C14.4325 30 13.12 29.7708 12.2085 29.5312C11.3022 29.3021 10.5679 28.6406 10.2085 27.7708C9.56787 26.2083 8.55225 23.3698 8.021 20.1406"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M8.021 11.8594C8.55225 8.63021 9.56787 5.79167 10.2085 4.22917C10.5679 3.35938 11.3022 2.69792 12.2085 2.46875C13.12 2.22917 14.4325 2 16.0002 2C17.5679 2 18.8804 2.22917 19.7918 2.46875C20.6981 2.69792 21.4325 3.35938 21.7918 4.22917C22.4325 5.79167 23.4481 8.63021 23.9793 11.8594"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M16 7C11.0312 7 7 11.0312 7 16C7 20.9688 11.0312 25 16 25C20.9688 25 25 20.9688 25 16C25 11.0312 20.9688 7 16 7Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M16 11.3594V16.0156L18.7865 18.7865"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M25.7915 17.9894C25.9321 17.3384 26.005 16.6717 26.005 15.9998C26.005 15.3228 25.9321 14.6613 25.7915 14.0103"
          fill="white"
        />
        <path
          d="M25.7915 17.9894C25.9321 17.3384 26.005 16.6717 26.005 15.9998C26.005 15.3228 25.9321 14.6613 25.7915 14.0103"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
