import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate, useRouter } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { useBackNavigation } from '@tg-web/utils';

import {
  DeleteStackModal,
  StackForm,
  StackFormProps,
} from '../../../features/stacks';
import { useGetStack, useUpdateStack } from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export interface EditStackPageProps {
  stackId: string;
}

export function EditStackPage({ stackId }: EditStackPageProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();
  const stackReq = useGetStack({
    pathParams: { id: stackId },
  });
  const updateStackReq = useUpdateStack();
  const handleSubmit: StackFormProps['onSubmit'] = (values) => {
    updateStackReq.mutate({
      body: { stack: { default_creator: values.allow, title: values.title } },
      pathParams: { id: stackId },
    });
  };

  useBackNavigation(router.history.back);
  useEffect(() => {
    if (updateStackReq.isSuccess) {
      navigate({
        params: { stackId },
        to: '/stacks/$stackId/view',
      });
    }
  }, [updateStackReq.isSuccess]);

  if (stackReq.isPending || !stackReq.data) {
    return <GlobalLoading />;
  }

  return (
    <PageWrapper className="flex flex-col px-5 py-6">
      <span className="typo-header-big">{t('all:stacks.edit.title')}</span>
      <StackForm
        defaultValues={{
          allow: stackReq.data.default_creator,
          title: stackReq.data.title,
        }}
        buttonTitle={t('all:stacks.edit.button')}
        isPending={updateStackReq.isPending}
        onSubmit={handleSubmit}
      >
        <DeleteStackModal stackId={stackId}>
          <Button className="mt-8" type="button" variant="tertiary">
            {t('all:stacks.edit.delete_button')}
          </Button>
        </DeleteStackModal>
      </StackForm>
    </PageWrapper>
  );
}
