import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectProps } from '@radix-ui/react-select';
import { useNavigate } from '@tanstack/react-router';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Skeleton,
} from '@tg-web/components';
import { PlusCircleIcon } from '@tg-web/icons';
import { cn } from '@tg-web/utils';

import { UserAvatar } from '../../../features/user';
import { useListStack, useUserProfile } from '../../../shared/api';

export const MY_CALENDAR_FAKE_STACK_ID = 'my_calendar';

export function StackSelect({ value, ...props }: SelectProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userProfile = useUserProfile({});
  const stacksReq = useListStack({
    queryParams: {
      page_number: 1,
      page_size: 100,
      roles: ['owner', 'creator'],
    },
  });

  useEffect(() => {
    if (value === 'new') {
      navigate({ to: '/stacks/new' });
    }
  }, [value]);

  if (!stacksReq.isSuccess) {
    return <Skeleton className="bg-tg-section-bg h-10 w-full" />;
  }

  const currentStack = stacksReq.data.stacks.find(
    (stack) => stack.id === value,
  );
  const profile = userProfile.data?.user.data;

  return (
    <div className="flex flex-row items-center gap-3 py-1">
      {currentStack && (
        <span className="text-[32px] leading-none">{currentStack.emoji}</span>
      )}
      {!currentStack && profile && (
        <UserAvatar className="h-[32px] w-[32px]" user={profile} />
      )}
      <Select value={value} {...props}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder={t('all:stacks.select.my')} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem
            rightSlot={
              profile ? (
                <UserAvatar className="h-[24px] w-[24px]" user={profile} />
              ) : null
            }
            className={cn({ hidden: value === MY_CALENDAR_FAKE_STACK_ID })}
            key={MY_CALENDAR_FAKE_STACK_ID}
            value={MY_CALENDAR_FAKE_STACK_ID}
          >
            {t('all:stacks.select.my')}
          </SelectItem>
          {stacksReq.data.stacks.map((stack) => (
            <SelectItem
              rightSlot={
                <span className="shrink-0 text-[24px] leading-none">
                  {stack.emoji}
                </span>
              }
              className={cn({ hidden: stack.id === value })}
              key={stack.id}
              value={stack.id}
            >
              {stack.title}
            </SelectItem>
          ))}
          <SelectItem
            key="new"
            rightSlot={<PlusCircleIcon height={24} width={24} />}
            value="new"
          >
            {t('all:stacks.select.new')}
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}
