import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import calendarAnimation from '../config/lottie-calendar.json';
import { hexToRgb } from '../lib/hexToRgb';
import { getTgTheme } from '../model/defaultTheme';

export function GlobalLoading() {
  const { t } = useTranslation();

  const accentColor = hexToRgb(getTgTheme().accent_text_color);

  // Replace color with accent form TG theme
  const animationData = JSON.parse(
    JSON.stringify(calendarAnimation).replace(
      /"CUSTOM_COLOR"/gi,
      `[${accentColor.r / 255},${accentColor.g / 255},${accentColor.b / 255}]`,
    ),
  );

  return (
    <div className="flex h-full flex-col items-center justify-center text-center">
      <div className="flex max-w-[270px] flex-col gap-3">
        <Lottie
          options={{
            animationData,
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={150}
          width={150}
        />
        <h1 className="typo-header-biggest">
          {t('all:common.global_loading.title')}
        </h1>
        <p className="typo-text">{t('all:common.global_loading.subtitle')}</p>
      </div>
    </div>
  );
}
