import { UseQueryOptions } from '@tanstack/react-query';

import {
  ListGoogleAccountsError,
  ListGoogleAccountsResponse,
  useListGoogleAccounts,
  useShowGoogleAccount,
} from '../generated';

const FIVE_MINUTES = 5 * 60 * 1000;

export function useGoogleCalendarAccountConfigured(
  options: Omit<
    UseQueryOptions<
      ListGoogleAccountsResponse,
      ListGoogleAccountsError,
      ListGoogleAccountsResponse
    >,
    'initialData' | 'queryFn' | 'queryKey'
  > | void,
) {
  const googleAccountsList = useListGoogleAccounts(
    {},
    { staleTime: FIVE_MINUTES, ...(options ?? {}) },
  );
  const googleAccount = useShowGoogleAccount(
    {
      pathParams: {
        id: googleAccountsList.data?.google_accounts.data[0].id ?? '-1',
      },
    },
    {
      enabled:
        googleAccountsList.isSuccess &&
        googleAccountsList.data.google_accounts.data.length > 0,
      staleTime: FIVE_MINUTES,
    },
  );

  return { googleAccount, googleAccountsList };
}
