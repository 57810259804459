import { sort } from 'ramda';

import { AttendStatus } from '../../../features/events';
import { Participant } from '../model/types';

export function sortParticipants(
  participants: Participant[],
  creatorId: string,
) {
  return sort((a, b) => {
    if (a.user.id === creatorId) {
      return -100;
    }
    if (b.user.id === creatorId) {
      return 100;
    }

    return getStatusOrder(a.status) - getStatusOrder(b.status);
  }, participants);
}

function getStatusOrder(status: AttendStatus) {
  switch (status) {
    case AttendStatus.attend:
      return 0;
    case AttendStatus.maybe:
      return 1;
    case AttendStatus.notAttended:
      return 2;
    default:
      return 10;
  }
}
