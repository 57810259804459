import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { Button, useToast } from '@tg-web/components';
import { useBackNavigation } from '@tg-web/utils';

import { CalendarsSynchronizationForm } from '../../features/google';
import { useGoogleCalendarAccountConfigured } from '../../shared/api';
import { GlobalLoading } from '../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../shared/ui/PageWrapper';

const FORM_ID = 'googleCalendarSettingsForm';

export function GoogleCalendarSettingsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dismiss, toast } = useToast();

  const [isFormPending, setIsFormRending] = useState(false);
  const [activeToastId, setActiveToastId] = useState('');

  const { googleAccount, googleAccountsList } =
    useGoogleCalendarAccountConfigured();

  useBackNavigation(() => {
    dismiss(activeToastId);
    navigate({ to: '/settings' });
  });

  if (googleAccountsList.isPending || googleAccount.isPending) {
    return <GlobalLoading />;
  }

  if (googleAccountsList.isError || googleAccount.isError) {
    throw new Error('Unexpected API error');
  }

  return (
    <PageWrapper className="flex flex-col space-y-6 p-4">
      <h1 className="typo-header-big">
        {t('all:settings.google_calendar.title')}
      </h1>
      <CalendarsSynchronizationForm
        onSuccess={() => {
          const toastResult = toast({
            description: t('all:settings.google_calendar.saved_toast'),
          });
          setActiveToastId(toastResult.id);
        }}
        formId={FORM_ID}
        googleAccountId={googleAccountsList.data.google_accounts.data[0].id}
        googleCalendars={googleAccount.data.google_calendars.data}
        onIsPendingChange={setIsFormRending}
      />
      <Button
        form={FORM_ID}
        isLoading={isFormPending}
        type="submit"
        variant="primary"
      >
        {t('all:common.buttons.save')}
      </Button>
    </PageWrapper>
  );
}
