import { captureException } from '@sentry/react';
import { z } from 'zod';

import {
  GoogleErrorResponseSchema,
  GoogleSuccessResponseSchema,
} from '../model';

export type GoogleErrorResponseType = z.infer<typeof GoogleErrorResponseSchema>;
export type GoogleSuccessResponseType = z.infer<
  typeof GoogleSuccessResponseSchema
>;
export type TypedGoogleResponse =
  | ({ type: 'error' } & GoogleErrorResponseType)
  | ({ type: 'success' } & GoogleSuccessResponseType);

export function getTypedGoogleResponse(
  response: GoogleErrorResponseType | GoogleSuccessResponseType,
): TypedGoogleResponse {
  try {
    const result = GoogleSuccessResponseSchema.parse(response);
    return { ...result, type: 'success' };
  } catch (error) {
    try {
      const result = GoogleErrorResponseSchema.parse(response);
      return { ...result, type: 'error' };
    } catch (error) {
      const parsingError = new Error('Invalid google response format');

      captureException(parsingError, {
        extra: response,
      });

      throw parsingError;
    }
  }
}
