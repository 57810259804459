import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from '@tg-web/components';
import { StackIcon } from '@tg-web/icons';

const formSchema = z.object({
  allow: z.boolean().optional(),
  title: z.string().trim().min(1, 'all:stacks.form.error.required'),
});

type StackForm = z.infer<typeof formSchema>;

export interface StackFormProps extends PropsWithChildren {
  buttonTitle: string;
  defaultValues?: StackForm;
  isPending: boolean;
  onSubmit: (values: StackForm) => void;
}

export function StackForm({
  buttonTitle,
  children,
  defaultValues,
  isPending,
  onSubmit,
}: StackFormProps) {
  const { t } = useTranslation();

  const form = useForm<StackForm>({
    defaultValues: defaultValues ?? {
      allow: true,
      title: '',
    },
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <Form {...form}>
      <form className="mt-6 flex grow flex-col" onSubmit={handleSubmit}>
        <FormField
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  leftIcon={<StackIcon className="text-tg-hint" />}
                  placeholder={t('all:stacks.form.title.placeholder')}
                />
              </FormControl>
              <FormMessage translate />
              <FormDescription className="typo-feed text-tg-hint">
                {t('all:stacks.form.title.description')}
              </FormDescription>
            </FormItem>
          )}
          control={form.control}
          name="title"
        />
        <FormField
          render={({ field: { onChange, value, ...field } }) => (
            <FormItem>
              <FormControl>
                <div className="flex py-4">
                  <Checkbox
                    checked={value}
                    id="allow"
                    onCheckedChange={onChange}
                    {...field}
                  />
                  <label className="typo-text ml-4 mt-0.5" htmlFor="allow">
                    {t('all:stacks.form.allow_for_subscribers')}
                  </label>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
          control={form.control}
          name="allow"
        />
        {children}
        <Button className="mt-auto" disabled={isPending} variant="primary">
          {buttonTitle}
        </Button>
      </form>
    </Form>
  );
}
