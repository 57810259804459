import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import {
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@tg-web/components';
import { useCallbackRef } from '@tg-web/utils';

import {
  GoogleCalendar,
  useActivateGoogleAccountCalendars,
} from '../../../shared/api';
import { ViewField } from '../../../shared/ui/ViewField';

const FormSchema = z.object({
  items: z.array(z.string()),
});

export type CalendarsSynchronizationFormProps = {
  formId?: string;
  googleAccountId: string;
  googleCalendars: GoogleCalendar[];
  hideCaptions?: boolean;
  onIsPendingChange?: (value: boolean) => void;
  onSuccess?: VoidFunction;
};

export function CalendarsSynchronizationForm({
  formId,
  googleAccountId,
  googleCalendars,
  hideCaptions = false,
  onIsPendingChange,
  onSuccess,
}: CalendarsSynchronizationFormProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const onIsPendingChangeCallback = useCallbackRef(onIsPendingChange);

  const form = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {
      items: googleCalendars
        .filter((it) => it.attributes.active)
        .map((it) => it.id),
    },
    resolver: zodResolver(FormSchema),
  });

  const googleAccountCalendars = useActivateGoogleAccountCalendars({
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['events'],
      });
      queryClient.invalidateQueries({
        queryKey: ['google_accounts', googleAccountId],
      });

      onSuccess?.();
    },
  });

  useEffect(() => {
    onIsPendingChangeCallback?.(googleAccountCalendars.isPending);
  }, [googleAccountCalendars.isPending, onIsPendingChangeCallback]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    googleAccountCalendars.mutate({
      body: { google_account: { calendar_ids: data.items } },
      pathParams: { id: googleAccountId },
    });
  }

  return (
    <Form {...form}>
      <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-3">
          {!hideCaptions && (
            <p className="typo-text-bold">
              {t('all:google.calendar_sync.import.title')}
            </p>
          )}
          <div>
            <FormField
              render={() => (
                <FormItem withoutWrapper>
                  {googleCalendars.map((it) => (
                    <FormField
                      render={({ field }) => {
                        return (
                          <FormItem key={it.id} withoutWrapper>
                            <ViewField
                              leftIcon={
                                <div className="flex h-[32px] w-[32px] flex-col items-center justify-center">
                                  <FormControl>
                                    <Checkbox
                                      disabled={
                                        googleAccountCalendars.isPending
                                      }
                                      onCheckedChange={(checked) => {
                                        return checked
                                          ? field.onChange([
                                              ...field.value,
                                              it.id,
                                            ])
                                          : field.onChange(
                                              field.value?.filter(
                                                (value) => value !== it.id,
                                              ),
                                            );
                                      }}
                                      checked={field.value?.includes(it.id)}
                                    />
                                  </FormControl>
                                </div>
                              }
                            >
                              <FormLabel className="typo-text">
                                {it.attributes.name}
                              </FormLabel>
                            </ViewField>
                          </FormItem>
                        );
                      }}
                      control={form.control}
                      key={it.id}
                      name="items"
                    />
                  ))}
                  <FormMessage />
                </FormItem>
              )}
              control={form.control}
              name="items"
            />
          </div>
        </div>
      </form>
    </Form>
  );
}
