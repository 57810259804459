import { Stack } from '../../../shared/api';
import { StackCard } from './StackCard';

export interface StacksListProps {
  stacks: Array<Stack>;
}

export function StacksList({ stacks }: StacksListProps) {
  return stacks.map((stack) => <StackCard key={stack.id} stack={stack} />);
}
