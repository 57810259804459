import React from 'react';

import { IconProps } from './types';

export const NotificationIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M20 23.875C19.9427 26.1614 18.1719 28 16 28C13.7917 28 12 26.1042 12 23.7604"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M26.1615 19.1615C25.5208 18.6667 25.0469 17.9271 24.9531 17.0729C24 8 20.4115 4 16 4C11.5885 4 8 8 7.04688 17.0729C6.95312 17.9271 6.47917 18.6667 5.83854 19.1615C5.29688 19.5729 5 20.026 5 20.5C5 22.4323 9.92708 24 16 24C22.0729 24 27 22.4323 27 20.5C27 20.026 26.7031 19.5729 26.1615 19.1615Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M5 9C5.60417 6.42188 7.14583 4.27604 9 3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M27 9C26.3958 6.42188 24.8542 4.27604 23 3"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
