import { PropsWithChildren, useEffect, useLayoutEffect } from 'react';

import { useJitsu } from '@jitsu/jitsu-react';
import { captureException } from '@sentry/react';

import { getWebApp } from '@tg-web/utils';

import { parseStartappParam } from '../../../shared/lib/parseStartappParam';
import { AuthContext } from '../../../shared/model/authContext';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { useUserLoginWithStatus } from '../lib';

export function AuthProvider({ children }: PropsWithChildren) {
  const { analytics } = useJitsu();

  const authLogin = useUserLoginWithStatus();

  useEffect(() => {
    authLogin.mutate({
      body: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token_id: Number(import.meta.env.VITE_BOT_TOKEN_ID ?? '0'),
        user_data: getWebApp().initData,
      },
    });
  }, []);

  useLayoutEffect(() => {
    if (authLogin.isSuccess) {
      analytics.identify(authLogin.data.data.user.data.id, {
        colorScheme: getWebApp().colorScheme,
        environment: import.meta.env.VITE_APP_ENV,
        platform: getWebApp().platform,
        version: getWebApp().version,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLogin.isSuccess, analytics]);

  useEffect(() => {
    if (authLogin.isSuccess && authLogin.data.status === 201) {
      getWebApp().CloudStorage.setItem(CloudStorageKeys.SHOW_WELCOME, 'true');
    }
  }, [authLogin.data?.status, authLogin.isSuccess]);

  if (authLogin.isPending || authLogin.isIdle) {
    return null;
  }

  if (authLogin.isError) {
    return 'Access denied';
  }

  return (
    <AuthContext.Provider
      value={{
        token: authLogin.data.data.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
