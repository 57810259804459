export enum RecurrentType {
  daily = 1,
  monthly = 3,
  noRecurrency = 0,
  weekly = 2,
}

export const RecurrentTypesArray = [
  RecurrentType.noRecurrency,
  RecurrentType.daily,
  RecurrentType.weekly,
  RecurrentType.monthly,
] as const;

export const RecurrentTypesLabels = {
  [RecurrentType.daily]: 'all:event.recurrent.labels.daily',
  [RecurrentType.monthly]: 'all:event.recurrent.labels.monthly',
  [RecurrentType.noRecurrency]: 'all:event.recurrent.labels.noRecurrency',
  [RecurrentType.weekly]: 'all:event.recurrent.labels.weekly',
} as const;

export const RecurrentTypesDescriptions = {
  [RecurrentType.daily]: 'all:event.recurrent.descriptions.daily',
  [RecurrentType.monthly]: 'all:event.recurrent.descriptions.monthly',
  [RecurrentType.noRecurrency]: 'all:event.recurrent.descriptions.noRecurrency',
  [RecurrentType.weekly]: 'all:event.recurrent.descriptions.weekly',
} as const;

export const RecurrentTypeInfo = {
  [RecurrentType.daily]: 'all:event.recurrent.info.daily',
  [RecurrentType.monthly]: 'all:event.recurrent.info.monthly',
  [RecurrentType.noRecurrency]: 'all:event.recurrent.info.noRecurrency',
  [RecurrentType.weekly]: 'all:event.recurrent.info.weekly',
} as const;
