import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@tg-web/components';
import { PropsWithCN, cn, useBoolean } from '@tg-web/utils';

import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { setCloudStorageItem } from '../../../shared/lib/setCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';

export function MonthViewTooltip({
  children,
  className,
}: PropsWithCN & PropsWithChildren) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useBoolean();
  const hideTooltip = () => {
    setShowTooltip.off();
    setCloudStorageItem(CloudStorageKeys.HIDE_MONTH_VIEW_TOOLTIP, 'true');
  };

  useEffect(() => {
    getCloudStorageItem(CloudStorageKeys.HIDE_MONTH_VIEW_TOOLTIP).then(
      (value) => {
        if (!value) {
          setShowTooltip.on();
        }
      },
    );
  }, [setShowTooltip]);

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        className={cn('max-w-[270px]', className)}
        onPointerDownOutside={hideTooltip}
        sideOffset={-20}
      >
        <TooltipArrow />
        {t('all:feed.month_view.tooltip.text')}
      </TooltipContent>
    </Tooltip>
  );
}
