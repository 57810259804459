import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '../../../shared/api';

export interface StackDescriptionProps {
  stack: Stack;
}

export function StackDescription({ stack }: StackDescriptionProps) {
  const { t } = useTranslation();

  const text = useMemo(
    () =>
      new Map<Stack['role'] | null, string>([
        ['creator', t('all:stacks.view.subscribed')],
        ['owner', t('all:stacks.view.one_link')],
        ['reader', t('all:stacks.view.subscribed')],
        [null, t('all:stacks.view.subscribe')],
      ]),
    [],
  );

  return <span className="typo-feed text-tg-hint">{text.get(stack.role)}</span>;
}
