import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Label,
  RadioGroup,
  RadioGroupItem,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@tg-web/components';
import { cn } from '@tg-web/utils';

import {
  RecurrentType,
  RecurrentTypesArray,
  RecurrentTypesDescriptions,
  RecurrentTypesLabels,
} from '../lib/recurrent';

const formSchema = z.object({
  value: z.string(),
});

export type DescriptionModalProps = {
  initial?: string;
  onClose?: () => void;
  onSave?: (value: RecurrentType) => void;
  open?: boolean;
};

export function RecurrentModal({
  initial = RecurrentType.noRecurrency.toString(),
  onClose,
  onSave,
  open = false,
}: DescriptionModalProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      value: initial,
    },
    resolver: zodResolver(formSchema),
  });

  const recurrentOptions = useMemo(() => {
    return RecurrentTypesArray.map((value) => ({
      label: t(RecurrentTypesLabels[value]),
      value: value.toString(),
    }));
  }, []);

  useEffect(() => {
    if (open) {
      form.reset();
      form.setValue('value', initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    onSave?.(parseInt(values.value));
    onClose?.();
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose?.();
    }
  };

  return (
    <Sheet onOpenChange={handleOpenChange} open={open}>
      <SheetContent className="w-full border-none" side="bottom">
        <SheetHeader>
          <SheetTitle>{t('all:event.recurrent.modal.title')}</SheetTitle>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              render={({ field }) => (
                <>
                  <FormItem>
                    <FormControl>
                      <RadioGroup
                        className="-mx-5 mt-6"
                        defaultValue={field.value}
                        onValueChange={field.onChange}
                      >
                        {recurrentOptions.map((option) => (
                          <div
                            className={cn(
                              'flex h-12 items-center px-5',
                              field.value === option.value &&
                                'bg-tg-secondary-bg',
                            )}
                            key={option.value}
                          >
                            <Label
                              className="grow"
                              htmlFor={`r${option.value}`}
                            >
                              {option.label}
                            </Label>
                            <RadioGroupItem
                              id={`r${option.value}`}
                              value={option.value}
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                  <p className="typo-text mt-4 text-center">
                    {t(
                      RecurrentTypesDescriptions[
                        parseInt(field.value) as RecurrentType
                      ],
                    )}
                  </p>
                </>
              )}
              control={form.control}
              name="value"
            />
            <Button className="mt-8" variant="primary">
              {t('all:common.buttons.save')}
            </Button>
            <Button className="mt-3" variant="secondary">
              {t('all:common.buttons.cancel')}
            </Button>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
