import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Calendar, SheetContent, SheetTitle } from '@tg-web/components';
import { DateIcon } from '@tg-web/icons';

import { useLocalisedDateFormat } from '../lib/useLocalisedDateFormat';
import { getTgTheme } from '../model/defaultTheme';

export type DatePickerSheetContentProps = {
  onClose: () => void;
  onSelect: (selected: Date | undefined) => void;
  open: boolean;
  title: string;
  value?: Date;
};
export function DatePickerSheetContent({
  onClose,
  onSelect,
  open,
  title,
  value,
}: DatePickerSheetContentProps) {
  const { userLocale } = useLocalisedDateFormat();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (!open) {
      setSelectedDate(undefined);
    }
  }, [open]);

  const handleSave = () => {
    if (selectedDate) {
      onSelect?.(selectedDate);
    }
    onClose();
  };

  return (
    <SheetContent className="flex flex-col border-none" side="bottom">
      <SheetTitle>{title}</SheetTitle>
      <div className="relative">
        <Calendar
          className="w-full p-0"
          locale={userLocale}
          mode="single"
          month={selectedDate ?? value}
          onSelect={setSelectedDate}
          selected={selectedDate ?? value}
          fixedWeeks
          required
        />
        <Button
          className="absolute right-28 top-0 h-10 w-10 rounded-full p-0"
          onClick={() => setSelectedDate(new Date())}
          variant="secondary"
        >
          <DateIcon
            className="h-8 w-8"
            secondaryColor={getTgTheme().accent_text_color}
          />
        </Button>
      </div>
      <Button onClick={handleSave} type="button" variant="primary">
        {t('all:common.buttons.save')}
      </Button>
      <Button onClick={onClose} type="button" variant="secondary">
        {t('all:common.buttons.cancel')}
      </Button>
    </SheetContent>
  );
}
