import { useRef } from 'react';

import { Time } from '@internationalized/date';
import { useTimeField } from '@react-aria/datepicker';
import {
  TimeFieldStateOptions,
  useTimeFieldState,
} from '@react-stately/datepicker';

import { PropsWithCN, cn, getMobileOperatingSystem } from '@tg-web/utils';

import { DateSegment } from './dateSegment';
import { baseInputClassNames } from './input';

export type TimeInputProps = Omit<TimeFieldStateOptions, 'locale'> &
  PropsWithCN;

export function TimeInput({ className, ...props }: TimeInputProps) {
  const state = useTimeFieldState({
    hourCycle: 24,
    // use result from @react-aria/i18n useLocale()
    locale: 'en',
    ...props,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { fieldProps, labelProps } = useTimeField(props, state, ref);

  return (
    <div className="relative flex flex-col items-start">
      <span {...labelProps} className="text-sm text-gray-800">
        {props.label}
      </span>
      {/* Enable native time select for mobiles */}
      {(getMobileOperatingSystem() === 'Android' ||
        getMobileOperatingSystem() === 'iOS') && (
        <input
          onChange={(e) => {
            const [hours, minutes] = e.currentTarget.value
              .split(':')
              .map((it) => parseInt(it, 10));
            props.onChange?.(new Time(hours ?? 0, minutes ?? 0));
          }}
          className="absolute bottom-0 left-0 right-0 top-0 w-full bg-transparent text-transparent outline-0"
          type="time"
          value={`${state.timeValue.hour.toString().padStart(2, '0')}:${state.timeValue.minute.toString().padStart(2, '0')}`}
        />
      )}
      <div
        {...fieldProps}
        className={cn(baseInputClassNames, className)}
        ref={ref}
      >
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
      </div>
    </div>
  );
}
