import React from 'react';

import { IconProps } from './types';

export const DateIcon = React.forwardRef<
  SVGSVGElement,
  { secondaryColor?: string } & IconProps
>(({ color = 'currentColor', secondaryColor, ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M14.3333 14C14.3333 14.1839 14.1839 14.3333 14 14.3333C13.8161 14.3333 13.6667 14.1839 13.6667 14C13.6667 13.8161 13.8161 13.6667 14 13.6667C14.1839 13.6667 14.3333 13.8161 14.3333 14Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M18.3333 14C18.3333 14.1839 18.1839 14.3333 18 14.3333C17.8161 14.3333 17.6667 14.1839 17.6667 14C17.6667 13.8161 17.8161 13.6667 18 13.6667C18.1839 13.6667 18.3333 13.8161 18.3333 14Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M22.3333 14C22.3333 14.1839 22.1839 14.3333 22 14.3333C21.8161 14.3333 21.6667 14.1839 21.6667 14C21.6667 13.8161 21.8161 13.6667 22 13.6667C22.1839 13.6667 22.3333 13.8161 22.3333 14Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M14.3333 18C14.3333 18.1839 14.1839 18.3333 14 18.3333C13.8161 18.3333 13.6667 18.1839 13.6667 18C13.6667 17.8161 13.8161 17.6667 14 17.6667C14.1839 17.6667 14.3333 17.8161 14.3333 18Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M18.3333 18C18.3333 18.1839 18.1839 18.3333 18 18.3333C17.8161 18.3333 17.6667 18.1839 17.6667 18C17.6667 17.8161 17.8161 17.6667 18 17.6667C18.1839 17.6667 18.3333 17.8161 18.3333 18Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M22.3333 18C22.3333 18.1839 22.1839 18.3333 22 18.3333C21.8161 18.3333 21.6667 18.1839 21.6667 18C21.6667 17.8161 21.8161 17.6667 22 17.6667C22.1839 17.6667 22.3333 17.8161 22.3333 18Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M10.3333 18C10.3333 18.1839 10.1839 18.3333 10 18.3333C9.81611 18.3333 9.66667 18.1839 9.66667 18C9.66667 17.8161 9.81611 17.6667 10 17.6667C10.1839 17.6667 10.3333 17.8161 10.3333 18Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M14.3333 22C14.3333 22.1839 14.1839 22.3333 14 22.3333C13.8161 22.3333 13.6667 22.1839 13.6667 22C13.6667 21.8161 13.8161 21.6667 14 21.6667C14.1839 21.6667 14.3333 21.8161 14.3333 22Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M18.8333 22C18.8333 22.4599 18.4599 22.8333 18 22.8333C17.5401 22.8333 17.1667 22.4599 17.1667 22C17.1667 21.5401 17.5401 21.1667 18 21.1667C18.4599 21.1667 18.8333 21.5401 18.8333 22Z"
        fill={secondaryColor ?? color}
        stroke={secondaryColor ?? color}
        strokeWidth="1.33333"
      />
      <path
        d="M10.3333 22C10.3333 22.1839 10.1839 22.3333 10 22.3333C9.81611 22.3333 9.66667 22.1839 9.66667 22C9.66667 21.8161 9.81611 21.6667 10 21.6667C10.1839 21.6667 10.3333 21.8161 10.3333 22Z"
        fill={color}
        stroke={color}
        strokeWidth="1.33333"
      />
      <path
        d="M24.8281 26.3281C18.9427 27.8698 13.0573 27.8698 7.1719 26.3281C6.43752 26.1354 5.83856 25.5729 5.60419 24.849C3.93231 19.6146 3.93231 14.3854 5.60419 9.15104C5.83856 8.42708 6.43752 7.86458 7.1719 7.67188C13.0573 6.13021 18.9427 6.13021 24.8281 7.67188C25.5625 7.86458 26.1615 8.42708 26.3959 9.15104C28.0677 14.3854 28.0677 19.6146 26.3959 24.849C26.1615 25.5729 25.5625 26.1354 24.8281 26.3281Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path d="M11 5V9V5Z" fill="#AAAAAA" />
      <path
        d="M11 5V9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path d="M21 5V9V5Z" fill="#AAAAAA" />
      <path
        d="M21 5V9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
});
