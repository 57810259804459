import { useCallback, useEffect } from 'react';

import { useCallbackRef } from '@tg-web/utils';

import { useCreateUserToken } from '../../../shared/api';

export function useGoogleAuthHandler(onConnectStart?: VoidFunction) {
  const createUserToken = useCreateUserToken();
  const onConnectStartCallback = useCallbackRef(onConnectStart);

  useEffect(() => {
    if (createUserToken.isSuccess) {
      window.open(
        `${window.location.origin}/google-auth?token=${createUserToken.data.token}`,
      );
    }
  }, [createUserToken.data?.token, createUserToken.isSuccess]);

  const googleAuthHandler = useCallback(() => {
    onConnectStartCallback?.();
    createUserToken.mutate({});
  }, [createUserToken, onConnectStartCallback]);

  return {
    createUserToken,
    googleAuthHandler,
  };
}
