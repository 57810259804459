import { addMinutes, parseISO } from 'date-fns';

import { Event } from '../../../shared/api';

export function fixFullDayEventStart(event: Event) {
  if (event.attributes.all_day) {
    const date = parseISO(event.attributes.start_at);
    const newDateString = addMinutes(
      date,
      date.getTimezoneOffset(),
    ).toISOString();

    return {
      ...event,
      attributes: {
        ...event.attributes,
        start_at: newDateString,
      },
    };
  }

  return event;
}
