import { useTranslation } from 'react-i18next';

import { type VariantProps, cva } from 'class-variance-authority';
import { format } from 'date-fns';

import { Button } from '@tg-web/components';
import { PropsWithCN } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import { createViewEventDeepLink, getEventInterval } from '../../events';
import { RecurrentType } from '../../recurrency';

const googleButtonVariants = cva('typo-text text-tg-link', {
  defaultVariants: {
    variant: 'simple',
  },
  variants: {
    variant: {
      ghost: 'bg-transparent h-auto p-0',
      simple: 'bg-tg-section-bg',
    },
  },
});

export type AddToGoogleButtonProps = {
  event: Event;
} & PropsWithCN &
  VariantProps<typeof googleButtonVariants>;
export function AddToGoogleButton({
  className,
  event,
  variant,
}: AddToGoogleButtonProps) {
  const { t } = useTranslation();
  const { end, start } = getEventInterval(event);

  const queryParams = new URLSearchParams({
    action: 'TEMPLATE',
    ctz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dates: `${formatDateForGoogle(start)}/${formatDateForGoogle(end)}`,
    details: createViewEventDeepLink(event.attributes.token),
    location: event.attributes.location ?? '',
    recur: formatRecurForGoogle(event.attributes.recurrent_type),
    text: event.attributes.title,
  });

  return (
    <Button
      className={googleButtonVariants({ className, variant })}
      variant="ghost"
      asChild
    >
      <a
        href={`https://calendar.google.com/calendar/render?${queryParams.toString()}`}
        rel="noreferrer"
        target="_blank"
      >
        {t('all:common.buttons.add_to_google')}
      </a>
    </Button>
  );
}

function formatDateForGoogle(date: Date) {
  return `${format(date, 'yyyyMMdd')}T${format(date, 'HHmmss')}`;
}

function formatRecurForGoogle(rec: RecurrentType) {
  switch (rec) {
    case RecurrentType.daily:
      return 'RRULE:FREQ=DAILY';
    case RecurrentType.weekly:
      return 'RRULE:FREQ=WEEKLY';
    case RecurrentType.monthly:
      return 'RRULE:FREQ=MONTHLY';
    default:
      return '';
  }
}
