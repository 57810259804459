import React from 'react';

import { IconProps } from './types';

export const GlobeIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M28 16C28 22.625 22.625 28 16 28C9.375 28 4 22.625 4 16C4 9.375 9.375 4 16 4C22.625 4 28 9.375 28 16Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M21.3337 16C21.3337 22.625 18.9448 28 16.0003 28C13.0559 28 10.667 22.625 10.667 16C10.667 9.375 13.0559 4 16.0003 4C18.9448 4 21.3337 9.375 21.3337 16Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M4 12H28"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M4 20H28"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
