import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { BoringIcon } from '@tg-web/icons';

import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function StackNotFound() {
  const { t } = useTranslation();

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="grow" />
      <div className="flex shrink-0 flex-col items-center">
        <div className="bg-tg-bg mb-5 flex h-[64px] w-[64px] items-center justify-center rounded-full">
          <BoringIcon />
        </div>
        <h1 className="typo-header-biggest mb-3 text-center">
          {t('all:stacks.not_found.title')}
        </h1>
        <p className="typo-text text-tg-subtitle-text mb-8">
          {t('all:stacks.not_found.subtitle')}
        </p>
        <Button size="small" variant="primary" asChild>
          <Link to="/stacks/new" replace>
            {t('all:stacks.empty.button')}
          </Link>
        </Button>
      </div>
      <div className="grow" />
      <div className="grow" />
    </PageWrapper>
  );
}
