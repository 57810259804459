import { createFileRoute } from '@tanstack/react-router';

import { EditStackPage } from '../../../../pages/stacks/ui/EditStackPage';

export const Route = createFileRoute('/_authorized/stacks/$stackId/edit')({
  component: Page,
});

function Page() {
  const { stackId } = Route.useParams();

  return <EditStackPage stackId={stackId} />;
}
