import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tzOffset } from '@date-fns/tz';
import { Time } from '@internationalized/date';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { addMinutes, getHours, getMinutes, startOfDay } from 'date-fns';

import { useToast } from '@tg-web/components';

import { getEventInterval } from '../../../features/events';
import { usePrompt } from '../../../features/prompt';
import { RecurrentType } from '../../../features/recurrency';
import {
  CreateEventRequestBody,
  useShowEvent,
  useUpdateEvent,
} from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import {
  EditEventForm,
  EditEventFormProps,
} from '../../../widgets/events/ui/EditEventForm';
import { MY_CALENDAR_FAKE_STACK_ID } from '../../../widgets/events/ui/StackSelect';

export interface EditEventPageProps {
  eventId: string;
}

export function EditEventPage({ eventId }: EditEventPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const router = useRouter();
  const prompt = usePrompt();
  const toast = useToast();

  const event = useShowEvent(
    {
      pathParams: { id: eventId },
    },
    { refetchOnWindowFocus: false },
  );

  const updateEvent = useUpdateEvent();

  useEffect(() => {
    if (updateEvent.isSuccess) {
      navigate({
        params: { eventId },
        to: '/events/$eventId/view',
      });
      toast.toast({
        description: t('all:event.edit_from.snack'),
      });
    }
  }, [eventId, navigate, updateEvent.isSuccess]);

  const initialForm = useMemo<EditEventFormProps['initialForm']>(() => {
    if (!event.data) {
      return undefined;
    }

    const attributes = event.data.event.data.attributes;

    const eventInterval = getEventInterval(event.data.event.data);
    if (attributes.timezone) {
      eventInterval.start = addMinutes(
        eventInterval.start,
        tzOffset(attributes.timezone, eventInterval.start) +
          eventInterval.start.getTimezoneOffset(),
      );
      eventInterval.end = addMinutes(
        eventInterval.end,
        tzOffset(attributes.timezone, eventInterval.end) +
          eventInterval.end.getTimezoneOffset(),
      );
    }

    return {
      description: attributes.description ?? '',
      endsAtDate: startOfDay(eventInterval.end),
      endsAtTime: new Time(
        getHours(eventInterval.end),
        getMinutes(eventInterval.end),
      ),
      location: attributes.location ?? '',
      notifyBefore: attributes.notify_before,
      recurrent: attributes.recurrent_type,
      stackId: attributes.stack_id ?? MY_CALENDAR_FAKE_STACK_ID,
      startsAtDate: startOfDay(eventInterval.start),
      startsAtTime: new Time(
        getHours(eventInterval.start),
        getMinutes(eventInterval.start),
      ),
      timezone:
        attributes.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      title: attributes.title,
      videoLink: attributes.video_link ?? '',
    };
  }, [event.data]);

  // Show loader only on first fetch
  if (event.isPending) {
    return <GlobalLoading />;
  }

  if (event.isError) {
    throw new Error('Unexpected API error');
  }

  const handleSubmit = async (form: CreateEventRequestBody) => {
    let dependentUpdate = 0;
    if (
      event.data.event.data.attributes.recurrent_type !==
        RecurrentType.noRecurrency &&
      form.recurrent_type !== RecurrentType.noRecurrency
    ) {
      const promptStatus = await prompt({
        discardButton: { label: t('all:common.buttons.cancel') },
        secondaryButton: { label: t('all:common.buttons.all_events') },
        submitButton: { label: t('all:common.buttons.only_this') },
        subtitle: t('all:event.recurrent.prompt.subtitle'),
        title: t('all:event.recurrent.prompt.title'),
      });
      if (promptStatus === 'secondary') {
        dependentUpdate = 1;
      }
      if (promptStatus === 'close' || promptStatus === 'discard') {
        return;
      }
    }

    updateEvent.mutate({
      body: { ...form, dependent_update: dependentUpdate },
      pathParams: { id: eventId },
    });
  };

  return (
    <EditEventForm
      onBack={() => {
        router.history.back();
      }}
      initialForm={initialForm}
      isPending={updateEvent.isPending}
      onSubmit={handleSubmit}
      submitButtonText={t('all:event.buttons.save')}
    />
  );
}
