import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@tg-web/components';

import { useOverlapEvents } from '../../../shared/api';
import { EventInfoCard } from './EventInfoCard';

export type OverlapEventsProps = {
  duration: string;
  enabled?: boolean;
  onCancel?: () => void;
  onContinue?: () => void;
  start_at: string;
};

export function OverlapEvents({
  duration,
  enabled,
  onCancel,
  onContinue,
  start_at,
}: OverlapEventsProps) {
  const { t } = useTranslation();
  const overlapEvents = useOverlapEvents(
    {
      queryParams: {
        duration,
        start_at,
      },
    },
    { enabled },
  );

  useEffect(() => {
    if (
      enabled &&
      overlapEvents.isSuccess &&
      !overlapEvents.isFetching &&
      overlapEvents.data.events.data.length === 0
    ) {
      onContinue?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overlapEvents.isSuccess,
    overlapEvents.isFetching,
    overlapEvents.data?.events.data.length,
    enabled,
  ]);

  const isMulti =
    overlapEvents.isSuccess && overlapEvents.data.events.data.length > 1;

  return (
    <Sheet
      open={
        enabled &&
        overlapEvents.isSuccess &&
        overlapEvents.data.events.data.length > 0
      }
      onOpenChange={onCancel}
    >
      <SheetContent
        className="flex w-full flex-col gap-0 overflow-y-scroll"
        side="bottom"
      >
        <SheetHeader>
          <SheetTitle>{t('all:event.overlap_dialog.title')}</SheetTitle>
        </SheetHeader>
        <p className="typo-text text-tg-subtitle-text mt-4">
          {t(
            isMulti
              ? 'all:event.overlap_dialog.multi.subtitle'
              : 'all:event.overlap_dialog.subtitle',
          )}
        </p>
        {overlapEvents.isSuccess && (
          <>
            <EventInfoCard
              className="mt-6"
              event={overlapEvents.data.events.data[0]}
              isMulti={isMulti}
            />
            {isMulti && (
              <p className="typo-text text-tg-subtitle-text mt-6 text-center">
                {t('all:event.overlap_dialog.multi.count', {
                  count: overlapEvents.data.events.data.length,
                })}
              </p>
            )}
          </>
        )}
        <Button
          className="mt-8"
          onClick={onContinue}
          type="button"
          variant="primary"
        >
          {t('all:common.buttons.continue')}
        </Button>
        <Button
          className="mb-6 mt-3"
          onClick={onCancel}
          type="button"
          variant="secondary"
        >
          {t('all:common.buttons.cancel')}
        </Button>
      </SheetContent>
    </Sheet>
  );
}
