import { useTranslation } from 'react-i18next';

import { PromptOptions, usePrompt } from '../../prompt';

export function useDeletePrompt(isRecurring: boolean) {
  const { t } = useTranslation();
  const prompt = usePrompt();

  return () => {
    const promptOptions: PromptOptions = {
      discardButton: { label: t('all:event.delete_dialog.buttons.cancel') },
      submitButton: {
        label: t('all:event.delete_dialog.buttons.delete_single'),
      },
      subtitle: t('all:event.delete_dialog.subtitle.base'),
      title: t('all:event.delete_dialog.title.base'),
    };

    if (isRecurring) {
      promptOptions.title = t('all:event.delete_dialog.title.recurring');
      promptOptions.subtitle = t('all:event.delete_dialog.subtitle.recurring');
      promptOptions.submitButton = {
        label: t('all:event.delete_dialog.buttons.delete_only_this'),
      };
      promptOptions.secondaryButton = {
        label: t('all:event.delete_dialog.buttons.delete_all'),
      };
    }

    return prompt(promptOptions);
  };
}
