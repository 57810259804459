import { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { omit } from 'ramda';

import { Button, Sheet, SheetContent, SheetTitle } from '@tg-web/components';

import { PromptContext, PromptOptions, PromptResultStatus } from '../model';

export function PromptProvider({ children }: PropsWithChildren) {
  const [prompt, setPrompt] = useState<PromptOptions | undefined>(undefined);
  const resolverRef = useRef<
    ((status: PromptResultStatus) => void) | undefined
  >(undefined);

  const promptPromise = useCallback(
    (promptOptions: PromptOptions) =>
      new Promise<PromptResultStatus>((resolve) => {
        setPrompt(promptOptions);
        resolverRef.current = resolve;
      }),
    [],
  );

  const handleAction = (status: PromptResultStatus) => () => {
    setPrompt(undefined);
    resolverRef.current?.(status);
    resolverRef.current = undefined;
  };

  const takeButtonProps = omit(['label']);

  return (
    <PromptContext.Provider value={promptPromise}>
      {children}
      <Sheet onOpenChange={handleAction('close')} open={Boolean(prompt)}>
        <SheetContent
          className="flex w-full flex-col gap-8 border-none"
          side="bottom"
        >
          <div className="flex flex-col gap-4">
            <SheetTitle className="typo-header-big">{prompt?.title}</SheetTitle>
            <p className="typo-text text-tg-subtitle-text">
              {prompt?.subtitle}
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <Button
              variant="primary"
              {...(prompt?.submitButton
                ? takeButtonProps(prompt.submitButton)
                : {})}
              onClick={handleAction('submit')}
            >
              {prompt?.submitButton.label}
            </Button>
            {prompt?.secondaryButton && (
              <Button
                variant="tertiary"
                {...(prompt?.secondaryButton
                  ? takeButtonProps(prompt.secondaryButton)
                  : {})}
                onClick={handleAction('secondary')}
              >
                {prompt?.secondaryButton.label}
              </Button>
            )}
            <Button
              variant="secondary"
              {...(prompt?.discardButton
                ? takeButtonProps(prompt.discardButton)
                : {})}
              onClick={handleAction('discard')}
            >
              {prompt?.discardButton.label}
            </Button>
          </div>
        </SheetContent>
      </Sheet>
    </PromptContext.Provider>
  );
}
