import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { isValid, parse } from 'date-fns';
import { z } from 'zod';

import { NewEventPage } from '../../../pages/events/ui/NewEventPage';
import { DATE_SEARCH_PARAM_FORMAT } from '../../../shared/model/search';

const feedSearchSchema = z.object({
  date: z.string().default(''),
  stackId: z.string().optional(),
});

export const Route = createFileRoute('/_authorized/events/new')({
  component: Page,
  validateSearch: zodSearchValidator(feedSearchSchema),
});

function Page() {
  const { date, stackId } = Route.useSearch();
  const parsedDate = parse(date, DATE_SEARCH_PARAM_FORMAT, new Date());

  return (
    <NewEventPage
      initialDate={isValid(parsedDate) ? parsedDate : undefined}
      stackId={stackId}
    />
  );
}
