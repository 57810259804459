import { createFileRoute } from '@tanstack/react-router';

import { ViewStackPage } from '../../../../pages/stacks/ui/ViewStackPage';

export const Route = createFileRoute('/_authorized/stacks/$stackId/view')({
  component: Page,
});

function Page() {
  const { stackId } = Route.useParams();

  return <ViewStackPage stackId={stackId} />;
}
