import React from 'react';

import { IconProps } from './types';

export const DescriptionIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M22.5104 26.7708C18.1719 28.3177 13.8281 28.3177 9.48958 26.7708C8.53646 26.4323 7.79167 25.651 7.5625 24.6667C6.1875 18.8906 6.1875 13.1094 7.5625 7.33333C7.79167 6.35416 8.53646 5.57291 9.48438 5.23437C12.6979 4.08854 15.9167 3.78645 19.1302 4.33854C21.4219 5.84374 23.6146 8.07812 25.0938 10.776C25.7552 15.4062 25.5365 20.0365 24.4427 24.6667C24.2083 25.6458 23.4635 26.4323 22.5104 26.7708Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M24.6042 11.3854C22.698 11.75 20.7917 11.599 18.8855 10.9323C18.2605 9.13542 18.0886 7.34375 18.375 5.54688"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M10.9688 15.901C12.974 16.7031 15.1719 16.8854 17.2812 16.4219"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M10.9375 21C14.2396 22.0156 17.7604 22.0156 21.0625 21"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
