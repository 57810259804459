import { useTranslation } from 'react-i18next';

import { Button } from '@tg-web/components';
import { CancelIcon } from '@tg-web/icons';
import { PropsWithCN, cn } from '@tg-web/utils';

import { User } from '../../../shared/api';
import { ViewField } from '../../../shared/ui/ViewField';
import { AttendStatus } from '../../events';
import { UserAvatar, formatFullName } from '../../user';

export type ParticipantCardProps = {
  eventCreatorId?: string;
  onRemove?: () => void;
  status: AttendStatus;
  user: User;
} & PropsWithCN;

export function ParticipantCard({
  className,
  eventCreatorId,
  onRemove,
  status,
  user,
}: ParticipantCardProps) {
  const { t } = useTranslation();

  return (
    <ViewField
      rightIcon={
        onRemove && (
          <Button className="h-8 w-8 p-0" onClick={onRemove} variant="ghost">
            <CancelIcon className="text-tg-hint" />
          </Button>
        )
      }
      className={cn('py-[9.5px]', className)}
      innerClassName="pl-[62px]"
      leftIcon={<UserAvatar user={user} />}
    >
      <p className="typo-text">{formatFullName(user)}</p>
      {user.id === eventCreatorId ? (
        <p className="typo-feed text-tg-hint">
          {t('all:event.participants.card.organiser')}
        </p>
      ) : (
        <div className="flex flex-row items-center gap-1.5">
          {status === AttendStatus.attend && (
            <>
              <div className="bg-c-green h-2 w-2 rounded" />
              <p className="typo-feed text-tg-hint">
                {t('all:event.participants.status.joined')}
              </p>
            </>
          )}
          {status === AttendStatus.maybe && (
            <>
              <div className="bg-c-yellow h-2 w-2 rounded" />
              <p className="typo-feed text-tg-hint">
                {t('all:event.participants.status.maybe')}
              </p>
            </>
          )}
          {status === AttendStatus.missed && (
            <>
              <div className="bg-tg-section-separator h-2 w-2 rounded" />
              <p className="typo-feed text-tg-hint">
                {t('all:event.participants.status.not_interested')}
              </p>
            </>
          )}
        </div>
      )}
    </ViewField>
  );
}
