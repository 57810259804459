import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from '@tanstack/react-router';
import { formatDuration, intervalToDuration, isSameDay } from 'date-fns';

import { Button, ConfettiFireworks, Skeleton } from '@tg-web/components';
import {
  CheckmarkIcon,
  CircleIconWrapper,
  DateDurationArrowIcon,
  PlusCircleIcon,
} from '@tg-web/icons';
import { getWebApp, useBackNavigation } from '@tg-web/utils';

import {
  AttendStatus,
  CopyLinkButton,
  EventDateHeader,
  JoinEventButton,
  ShareEventButton,
  getEventInterval,
  useFormatDateDistanceShort,
} from '../../../features/events';
import { getFeedSearch } from '../../../features/feed';
import { AddToGoogleButton } from '../../../features/google';
import {
  NotificationDuration,
  NotificationDurationLabels,
} from '../../../features/notifications';
import { RecurrentType, RecurrentTypeInfo } from '../../../features/recurrency';
import { useGetStack, useShowEvent } from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { TruncateContainer } from '../../../shared/ui/TruncateContainer';

export interface CreatedEventPage {
  eventId: string;
  stackId: string | undefined;
}

export function CreatedEventPage({ eventId, stackId }: CreatedEventPage) {
  const { t } = useTranslation();
  const { formatDateDistanceShort } = useFormatDateDistanceShort();
  const navigate = useNavigate();

  const event = useShowEvent({
    pathParams: { id: eventId },
  });
  const stackReq = useGetStack(
    {
      pathParams: { id: stackId ?? '' },
    },
    { enabled: !!stackId },
  );

  useBackNavigation(() => {
    navigate({
      search: getFeedSearch(event.data?.event.data),
      to: '/feed',
    });
  });

  useEffect(() => {
    if (event.isSuccess) {
      getWebApp().HapticFeedback.notificationOccurred('success');
    }
  }, [event.isSuccess]);

  if (event.isFetching || event.isPending) {
    return <GlobalLoading />;
  }

  if (event.isError) {
    throw new Error('Unexpected API error');
  }

  const eventData = event.data.event.data;

  const { end, start } = getEventInterval(eventData);

  return (
    <PageWrapper className="flex flex-col gap-3 px-5 py-6">
      {stackId && stackReq.isPending && (
        <Skeleton className="bg-tg-section-bg mb-5 h-8 w-full" />
      )}
      {stackId && stackReq.isSuccess && (
        <div className="flex flex-row items-center gap-3 self-center py-2.5">
          <span className="text-[28px] leading-none">
            {stackReq.data.emoji}
          </span>
          <span className="typo-text overflow-hidden text-ellipsis whitespace-nowrap">
            {stackReq.data.title}
          </span>
        </div>
      )}
      <ConfettiFireworks className="flex grow flex-col justify-center">
        <div className="flex w-full min-w-0 flex-col items-center">
          <CircleIconWrapper className="bg-tg-button text-tg-button-text mt-4">
            <CheckmarkIcon />
          </CircleIconWrapper>
          {isSameDay(start, end) ? (
            <div className="mt-4">
              <EventDateHeader date={start} secondDate={end} />
            </div>
          ) : (
            <div className="mt-4 flex flex-row gap-4">
              <EventDateHeader date={start} short />
              <div className="flex flex-col items-center">
                <div className="flex h-7 flex-col justify-center">
                  <DateDurationArrowIcon className="text-tg-hint" />
                </div>
                <p className="typo-text text-tg-hint">
                  {formatDuration(intervalToDuration({ end, start }), {
                    locale: { formatDistance: formatDateDistanceShort },
                  })}
                </p>
              </div>
              <EventDateHeader date={end} short />
            </div>
          )}
          <TruncateContainer
            bgColor="secondary-bg"
            className="mt-5 w-full text-center"
          >
            <p className="typo-text-bold">{eventData.attributes.title}</p>
          </TruncateContainer>
          {eventData.attributes.recurrent_type !==
            RecurrentType.noRecurrency && (
            <p className="typo-text">
              {t(
                RecurrentTypeInfo[
                  eventData.attributes.recurrent_type as RecurrentType
                ],
              )}
            </p>
          )}
          {eventData.attributes.recurrent_type !==
            RecurrentType.noRecurrency && (
            <p className="typo-text text-tg-subtitle-text">
              Notification{' '}
              {t(
                NotificationDurationLabels[
                  eventData.attributes.notify_before as NotificationDuration
                ],
              )}
            </p>
          )}
          <AddToGoogleButton
            className="mt-3"
            event={eventData}
            variant="ghost"
          />
        </div>
      </ConfettiFireworks>
      <div className="flex shrink-0 flex-col items-center">
        {stackId ? (
          <div className="mb-10 flex flex-row gap-14 py-4">
            <ShareEventButton
              event={eventData}
              text={t('all:created_event.invite.button')}
            />
            {eventData.attributes.attend_status === AttendStatus.attend ? (
              <div className="flex h-auto w-auto flex-col gap-2 p-0">
                <div className="bg-tg-section-bg h-[64px] w-[64px] rounded-full p-4">
                  <CheckmarkIcon />
                </div>
                <span className="typo-text">
                  {t('all:created_event.you_are_in')}
                </span>
              </div>
            ) : (
              <JoinEventButton
                event={eventData}
                icon={<PlusCircleIcon height={32} width={32} />}
                onSuccess={() => event.refetch()}
                text={t('all:created_event.add_to_my_cal')}
              />
            )}
          </div>
        ) : (
          <div className="bg-tg-section-bg flex flex-col items-center rounded-2xl p-5">
            <h3 className="typo-header-small text-center">
              {t('all:created_event.invite.title')}
            </h3>
            <p className="typo-text text-tg-subtitle-text mt-2.5 text-center">
              {t('all:created_event.invite.text')}
            </p>
            <div className="mt-6 flex flex-row gap-14">
              <ShareEventButton
                event={eventData}
                text={t('all:created_event.invite.button')}
                isPrimary
              />
              <CopyLinkButton event={eventData} />
            </div>
          </div>
        )}

        <Button className="mt-2.5" variant="tertiary" asChild>
          {stackId ? (
            <Link params={{ stackId }} to="/stacks/$stackId/view">
              {t('all:created_event.buttons.back_to_stack')}
            </Link>
          ) : (
            <Link search={getFeedSearch(eventData)} to="/feed">
              {t('all:created_event.buttons.continue')}
            </Link>
          )}
        </Button>
      </div>
    </PageWrapper>
  );
}
