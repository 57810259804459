import React from 'react';

import { IconProps } from './types';

export const EditIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M5.91668 26.2604C5.82293 26.2552 5.7448 26.1771 5.73959 26.0833C5.61459 20.5417 7.73959 15.1667 11.6927 11.2813C13.4896 9.51564 15.4792 7.94793 17.6354 6.6146C22.6927 3.4896 28.5104 9.3073 25.3854 14.3594C24.0521 16.5156 22.4844 18.5104 20.7188 20.3073C16.8333 24.2604 11.4583 26.3854 5.91668 26.2604Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M6.6875 19.6562C9.8125 19.6562 12.3438 22.1875 12.3438 25.3125"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M16.2448 7.95834C19.7292 9.46876 22.5052 12.2448 24.0156 15.7292"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
